import {
  AutocompleteInput,
  Create,
  DateTimeInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useGetIdentity,
  useTranslate,
  useRedirect,
  SaveButton,
  Toolbar,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { Customer } from '../types';
import { OrderTotalField } from './components/OrderTotalField';
import { ItemsGridInput } from './components/ItemsGridInput';

const OrderCreateToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const generateReference = () => {
  const now = new Date();
  const day = now.getDate().toString().padStart(2, '0');
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const year = now.getFullYear().toString().slice(-2);
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');
  return `ORD${year}${month}${day}${hours}${minutes}${seconds}`;
};

export const OrderCreate = () => {
  const { identity } = useGetIdentity();
  const isStaff = identity?.roles?.includes('staff');
  const translate = useTranslate();
  const redirect = useRedirect();
  const reference = generateReference();

  const transform = (data: any) => {
    const items = data.items.map((item: any) => ({
      product_id: item.productId,
      quantity: item.quantity,
      price: item.price,
      note: item.note,
    }));

    return {
      ...data,
      items,
      status: 'ordered',
    };
  };

  return (
    <Create
      transform={transform}
      mutationOptions={{
        onSuccess: () => {
          redirect('list', 'orders');
        },
      }}
    >
      <SimpleForm
        toolbar={<OrderCreateToolbar />}
        defaultValues={{ reference, promotionType: 'none' }}
      >
        <TextInput
          source="reference"
          label="resources.orders.fields.reference"
          disabled
        />
        <NumberInput
          source="tableNumber"
          label="resources.orders.fields.tableNumber"
          validate={required()}
        />
        {!isStaff && (
          <ReferenceInput source="customerId" reference="customers">
            <AutocompleteInput
              optionText={(choice?: Customer) => {
                if (!choice?.id)
                  return translate('resources.orders.messages.unknownCustomer');
                const fullName = `${choice.first_name || ''} ${
                  choice.last_name || ''
                }`.trim();
                return (
                  fullName ||
                  translate('resources.orders.messages.unknownCustomer')
                );
              }}
              label="resources.orders.fields.customer"
            />
          </ReferenceInput>
        )}
        <DateTimeInput
          source="date"
          label="resources.orders.fields.date"
          defaultValue={new Date()}
          validate={required()}
        />
        <ItemsGridInput />
        <OrderTotalField />
        <Box sx={{ border: '1px solid #e0e0e0', p: 2, borderRadius: 1, mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            {translate('resources.orders.fields.promotion')}
          </Typography>
          <Box display="flex" gap={2}>
            <SelectInput
              source="promotionType"
              label="resources.orders.fields.promotionType"
              choices={[
                { id: 'none', name: 'resources.orders.promotion.none' },
                {
                  id: 'percentage',
                  name: 'resources.orders.promotion.percentage',
                },
                { id: 'fixed', name: 'resources.orders.promotion.fixed' },
              ]}
            />
            <NumberInput
              source="promotionValue"
              label="resources.orders.fields.promotionValue"
              min={0}
            />
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};

export default OrderCreate;
