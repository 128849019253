import PrintIcon from '@mui/icons-material/Print';
import { Button } from '@mui/material';
import {
  useRecordContext,
  useTranslate,
  useGetMany,
  useGetOne,
} from 'react-admin';
import { Order, Product } from '../../types';
import { calculateTotals } from '../utils/calculateTotals';
import { useFileUpload } from '../../hooks/useFileUpload';

export const PrintBillButton = () => {
  const translate = useTranslate();
  const record = useRecordContext<Order>();
  const { getFileUrl } = useFileUpload();

  const { data: products } = useGetMany<Product>('products', {
    ids: record?.items?.map((item) => item.productId) || [],
  });

  const { data: configuration } = useGetOne('configuration', { id: 1 });

  const handlePrint = async () => {
    if (!record || !products) return;

    const totals = calculateTotals(record);
    const printWindow = window.open('', '_blank');
    if (!printWindow) return;

    let qrCodeUrl = '';
    if (configuration?.qrCode && typeof configuration.qrCode === 'string') {
      try {
        const url = await getFileUrl(configuration.qrCode);
        if (url) qrCodeUrl = url;
      } catch (error) {
        console.error('Failed to get QR code URL:', error);
      }
    }

    const content = `
      <html>
        <head>
          <title>Neko Coffee - ${record.reference}</title>
          <style>
            @page {
              size: 80mm auto;
              margin: 0;
            }
            body {
              font-family: 'Courier New', monospace;
              padding: 8px;
              width: 72mm;
              margin: 0 auto;
              font-size: 12px;
            }
            .header {
              text-align: center;
              margin-bottom: 10px;
            }
            .header h2 {
              font-size: 16px;
              margin: 0;
              padding: 0;
            }
            .header h3 {
              font-size: 14px;
              margin: 5px 0;
            }
            .info {
              margin-bottom: 10px;
              font-size: 12px;
            }
            .items {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 10px;
              font-size: 12px;
            }
            .items th, .items td {
              text-align: left;
              padding: 3px 0;
              border: none;
              border-bottom: 1px dashed #ddd;
            }
            .total {
              text-align: right;
              font-size: 12px;
            }
            .total h3 {
              font-size: 14px;
              margin: 5px 0;
            }
            .footer {
              text-align: center;
              margin-top: 20px;
              font-size: 12px;
            }
            .qr-code {
              text-align: center;
              margin-top: 20px;
            }
            .qr-code img {
              width: 100px;
              height: 100px;
            }
            @media print {
              html, body {
                width: 80mm;
                height: auto;
              }
            }
          </style>
        </head>
        <body>
          <div class="header">
            <h2>Neko Coffee</h2>
            <h3>${translate('resources.orders.name')} #${record.reference}</h3>
          </div>
          <div class="info">
            <p><strong>${translate(
              'resources.orders.fields.date'
            )}:</strong> ${new Date(record.date).toLocaleString()}</p>
          </div>
          <table class="items">
            <thead>
              <tr>
                <th>${translate('resources.products.fields.name')}</th>
                <th>${translate('resources.orders.fields.quantity')}</th>
                <th>${translate('resources.products.fields.price')}</th>
                <th>${translate('resources.orders.fields.total')}</th>
              </tr>
            </thead>
            <tbody>
              ${record.items
                ?.map((item) => {
                  const product = products.find((p) => p.id === item.productId);
                  return `
                    <tr>
                      <td>${product?.name}</td>
                      <td>${item.quantity}</td>
                      <td>${product?.price?.toLocaleString('vi-VN')} ₫</td>
                      <td>${(
                        (product?.price || 0) * item.quantity
                      )?.toLocaleString('vi-VN')} ₫</td>
                    </tr>
                  `;
                })
                .join('')}
            </tbody>
          </table>
          <div class="total">
            <p><strong>${translate(
              'resources.orders.fields.sum'
            )}:</strong> ${totals.totalExTaxes?.toLocaleString('vi-VN')} ₫</p>
            ${
              totals.discount > 0
                ? `<p><strong>${translate(
                    'resources.orders.fields.discount'
                  )}:</strong> ${totals.discount?.toLocaleString(
                    'vi-VN'
                  )} ₫</p>`
                : ''
            }
            <h3>${translate(
              'resources.orders.fields.total'
            )}: ${totals.total?.toLocaleString('vi-VN')} ₫</h3>
          </div>
          ${
            qrCodeUrl
              ? `<div class="qr-code">
                  <p style="margin-bottom: 10px;">${translate(
                    'pos.configuration.qrCode.thankYouMessage'
                  )}</p>
                  <img src="${qrCodeUrl}" alt="QR Code" />
                </div>`
              : ''
          }
          ${
            configuration?.receiptThankYouMessage
              ? `<div class="footer">
                  <p>${configuration.receiptThankYouMessage}</p>
                </div>`
              : ''
          }
          <script>
            window.onload = () => {
              window.print();
              window.onafterprint = () => window.close();
            }
          </script>
        </body>
      </html>
    `;

    printWindow.document.write(content);
    printWindow.document.close();
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<PrintIcon />}
      onClick={handlePrint}
      disabled={!record}
    >
      {translate('resources.orders.actions.print_bill')}
    </Button>
  );
};
