import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Typography,
  Box,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

interface Notification {
  id: string;
  message: string;
  timestamp: Date;
  read: boolean;
}

export const NotificationButton = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [connection, setConnection] = useState<any>(null);

  // useEffect(() => {
  // Create SignalR connection
  // const newConnection = new HubConnectionBuilder()
  //   .withUrl('YOUR_AZURE_SIGNALR_URL') // Replace with your Azure SignalR URL
  //   .withAutomaticReconnect()
  //   .build();

  // setConnection(newConnection);

  // // Start the connection
  // newConnection
  //   .start()
  //   .then(() => {
  //     console.log('Connected to SignalR Hub');

  //     // Subscribe to notifications
  //     newConnection.on(
  //       'ReceiveNotification',
  //       (notification: Notification) => {
  //         setNotifications((prev) => [notification, ...prev]);
  //       }
  //     );
  //   })
  //   .catch(console.error);

  // // Cleanup on unmount
  // return () => {
  //   if (newConnection) {
  //     newConnection.stop();
  //   }
  // };
  // }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (notification: Notification) => {
    // Mark notification as read
    setNotifications((prev) =>
      prev.map((n) => (n.id === notification.id ? { ...n, read: true } : n))
    );
    handleClose();
  };

  const unreadCount = notifications.filter((n) => !n.read).length;

  return (
    <>
      <IconButton color="inherit" onClick={handleClick} sx={{ marginLeft: 2 }}>
        <Badge badgeContent={unreadCount} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 400,
            width: '300px',
          },
        }}
      >
        {notifications.length === 0 ? (
          <MenuItem>
            <Typography>No notifications</Typography>
          </MenuItem>
        ) : (
          notifications.map((notification) => (
            <MenuItem
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
              sx={{
                backgroundColor: notification.read ? 'inherit' : 'action.hover',
                whiteSpace: 'normal',
              }}
            >
              <Box>
                <Typography variant="body1">{notification.message}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {new Date(notification.timestamp).toLocaleString()}
                </Typography>
              </Box>
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
};
