import {
  Datagrid,
  List,
  ReferenceField,
  TextField,
  NumberField,
  SelectField,
  FunctionField,
} from 'react-admin';
import { User } from 'public-contracts';

const genderChoices = [
  { id: 'male', name: 'Male' },
  { id: 'female', name: 'Female' },
  { id: 'other', name: 'Other' },
];

const FullNameField = ({ label }: { label?: string }) => (
  <ReferenceField source="userId" reference="users" label={label}>
    <FunctionField
      render={(record: User) => {
        if (!record) return null;
        return `${record.firstName} ${record.lastName}`;
      }}
    />
  </ReferenceField>
);

interface Props {
  filter?: any;
  disableCreate?: boolean;
  disableBulkActions?: boolean;
}

const StaffProfileList = (props: Props) => (
  <List {...props} hasCreate={true}>
    <Datagrid rowClick="edit">
      <FullNameField label="resources.staff_profiles.fields.name" />
      <ReferenceField
        source="departmentId"
        reference="organization_departments"
        label="resources.staff_profiles.fields.departmentId"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="postionId"
        reference="department_positions"
        label="resources.staff_profiles.fields.postionId"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField
        source="address"
        label="resources.staff_profiles.fields.address"
      />
      <SelectField
        source="sex"
        choices={genderChoices}
        label="resources.staff_profiles.fields.sex"
      />
      <NumberField
        source="ratePerHour"
        options={{ style: 'currency', currency: 'USD' }}
        label="resources.staff_profiles.fields.ratePerHour"
      />
    </Datagrid>
  </List>
);

export default StaffProfileList;
