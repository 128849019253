import { AuthProvider, UserIdentity } from 'react-admin';
import { User } from 'public-contracts';
import { IAuthClient, generateAvatar } from 'neko-common';
import { history } from './history';
import { configs } from './configs';
import sdk from './sdks/master-data-api';

const authProvider: AuthProvider & { auth: IAuthClient<User> } = {
  sdk,
  auth: sdk.createAuthClient(configs, () => {
    history.replace('/');
  }),
  login: async (authData: {
    username?: string;
    email?: string;
    password?: string;
    passkey?: boolean;
  }) => {
    const auth: IAuthClient<User> = authProvider.auth;
    if (await auth.isAuthenticated()) {
      return;
    }
    if (!auth.isCallback()) {
      if (authData.passkey) {
        await auth.loginWithPasskey();
        return await auth.redirectToAuthServer();
      }

      // Validate credentials before proceeding
      if ((!authData.username && !authData.email) || !authData.password) {
        throw new Error('Username/email and password are required');
      }

      // Ensure username/email is trimmed
      if (authData.username) {
        authData.username = authData.username.trim();
      }
      if (authData.email) {
        authData.email = authData.email.trim();
      }

      await auth.login({
        username: authData.username,
        email: authData.email,
        password: authData.password,
      });
      return await auth.redirectToAuthServer();
    }
  },
  logout: async () => {
    const auth: IAuthClient<User> = authProvider.auth;
    try {
      // Always attempt to logout regardless of current state
      await auth.logout();

      // Clear any client-side state or caches
      localStorage.clear();
      sessionStorage.clear();

      // Redirect to login page
      window.location.href = '/login';
    } catch (error) {
      console.error('Error during logout:', error);
      // Still redirect to login even if logout fails
      window.location.href = '/login';
    }
  },
  checkError: async (error) => {
    if (error.status === 401) {
      const auth: IAuthClient<User> = authProvider.auth;
      await auth.logout();
      window.location.href = '/login';
      return;
    }
    if (error.status === 403) {
      window.location.href = '/forbidden';
      return;
    }
  },
  checkAuth: async () => {
    const auth: IAuthClient<User> = authProvider.auth;

    // Handle callback first
    if (auth.isCallback()) {
      try {
        await auth.onCallback();
        return; // Just return, let the app handle the redirect
      } catch (error) {
        console.error('Error during callback:', error);
        window.location.href = '/login';
        throw error;
      }
    }

    // Handle login page
    if (auth.isLogin()) {
      return;
    }

    // Check current auth state
    const [authenticated, redirectedToAuthServer, tokenExpired] =
      await Promise.all([
        auth.isAuthenticated(),
        auth.isRedirectedToAuthServer(),
        auth.isTokenExpire(),
      ]);

    // Allow the auth flow to proceed during transitions
    if (redirectedToAuthServer || auth.isCallback()) {
      return;
    }

    // Check authentication state
    if (!authenticated) {
      // Only redirect if we're not in a transition state
      if (!auth.isLogin() && !redirectedToAuthServer && !auth.isCallback()) {
        window.location.href = '/login';
      }
      throw new Error('Not authenticated');
    }

    // Handle token refresh if needed
    if (tokenExpired) {
      try {
        await auth.fetchRefreshToken();
      } catch (error) {
        console.error('Error refreshing token:', error);
        // Only redirect if we're not in a transition state
        if (!auth.isLogin() && !redirectedToAuthServer && !auth.isCallback()) {
          window.location.href = '/login';
        }
        throw error;
      }
    }
  },
  getPermissions: () => Promise.resolve(),
  getIdentity: async () => {
    const auth: IAuthClient<User> = authProvider.auth;
    try {
      // Allow identity check during transitions
      const [authenticated, redirectedToAuthServer] = await Promise.all([
        auth.isAuthenticated(),
        auth.isRedirectedToAuthServer(),
      ]);

      if (!authenticated && !redirectedToAuthServer && !auth.isCallback()) {
        throw new Error('Not authenticated');
      }

      // Try to get profile multiple times with delay
      let profile;
      let attempts = 0;
      const maxAttempts = 5;
      const delayMs = 1000;

      while (!profile && attempts < maxAttempts) {
        try {
          profile = await auth.getUserProfile();
          if (profile) break;
        } catch (error) {
          if (attempts === maxAttempts - 1) {
            throw error; // Only throw on last attempt
          }
          console.log(
            `Profile fetch attempt ${attempts + 1} failed, retrying...`
          );
          await new Promise((resolve) => setTimeout(resolve, delayMs));
        }
        attempts++;
      }

      if (!profile) {
        throw new Error('Failed to load user profile after multiple attempts');
      }

      const fullName = profile.firstName + ' ' + profile.lastName;
      const userIdentity: UserIdentity & { roles?: string[] } = {
        id: profile.id,
        fullName,
        avatar: profile.avatar ?? generateAvatar(fullName),
        roles: profile.roles,
      };

      return userIdentity;
    } catch (error) {
      console.error('Error getting identity:', error);
      // Only redirect if we're not in a transition state
      if (
        !auth.isLogin() &&
        !auth.isRedirectedToAuthServer() &&
        !auth.isCallback()
      ) {
        window.location.href = '/login';
      }
      throw error;
    }
  },
  forgotPassword: async (email: string) => {
    const auth: IAuthClient<User> = authProvider.auth;
    return await auth.forgotPassword(email);
  },
  setNewPassword: async ({ password }: { password: string }) => {
    const auth: IAuthClient<User> = authProvider.auth;
    const token = new URLSearchParams(window.location.search).get('token');
    if (!token) {
      throw new Error('Invalid token');
    }
    return await auth.setNewPassword(password, token);
  },
};

export default authProvider;
