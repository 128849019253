import {
  AutocompleteInput,
  DateTimeInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useGetIdentity,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import { Customer } from '../types';
import { OrderTotalField } from './components/OrderTotalField';
import { ItemsGridInput } from './components/ItemsGridInput';
import { PrintBillButton } from './components/PrintBillButton';

const OrderEditToolbar = () => {
  const record = useRecordContext();
  return record?.status === 'delivered' ? null : <PrintBillButton />;
};

export const OrderEdit = () => {
  const { identity } = useGetIdentity();
  const isStaff = identity?.roles?.includes('staff');
  const translate = useTranslate();

  const transform = (data: any) => {
    const items = data.items.map((item: any) => ({
      product_id: item.productId,
      quantity: item.quantity,
      price: item.price,
      note: item.note,
    }));

    return {
      ...data,
      items,
    };
  };

  return (
    <Edit transform={transform}>
      <SimpleForm toolbar={<OrderEditToolbar />}>
        <TextInput
          source="reference"
          label="resources.orders.fields.reference"
          disabled
        />
        <NumberInput
          source="tableNumber"
          label="resources.orders.fields.tableNumber"
          validate={required()}
          disabled
        />
        {!isStaff && (
          <ReferenceInput source="customerId" reference="customers">
            <AutocompleteInput
              optionText={(choice?: Customer) => {
                if (!choice?.id)
                  return translate('resources.orders.messages.unknownCustomer');
                const fullName = `${choice.first_name || ''} ${
                  choice.last_name || ''
                }`.trim();
                return (
                  fullName ||
                  translate('resources.orders.messages.unknownCustomer')
                );
              }}
              label="resources.orders.fields.customer"
              disabled
            />
          </ReferenceInput>
        )}
        <DateTimeInput
          source="date"
          label="resources.orders.fields.date"
          validate={required()}
          disabled
        />
        <SelectInput
          source="status"
          label="resources.orders.fields.status"
          choices={[
            { id: 'ordered', name: 'resources.orders.tabs.ordered' },
            { id: 'ready', name: 'resources.orders.tabs.ready' },
            { id: 'delivered', name: 'resources.orders.tabs.delivered' },
            { id: 'cancelled', name: 'resources.orders.tabs.cancelled' },
          ]}
        />
        <ItemsGridInput disabled={true} />
        <OrderTotalField />
        <Box sx={{ border: '1px solid #e0e0e0', p: 2, borderRadius: 1, mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            {translate('resources.orders.fields.promotion')}
          </Typography>
          <Box display="flex" gap={2}>
            <SelectInput
              source="promotionType"
              label="resources.orders.fields.promotionType"
              disabled
              choices={[
                { id: 'none', name: 'resources.orders.promotion.none' },
                {
                  id: 'percentage',
                  name: 'resources.orders.promotion.percentage',
                },
                { id: 'fixed', name: 'resources.orders.promotion.fixed' },
              ]}
            />
            <NumberInput
              source="promotionValue"
              label="resources.orders.fields.promotionValue"
              min={0}
              disabled
            />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default OrderEdit;
