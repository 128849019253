import {
  Create,
  DateTimeInput,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  useGetIdentity,
  useRedirect,
  SaveButton,
  Toolbar,
  SelectInput,
  useTranslate,
  useNotify,
  Button as RaButton,
  FormDataConsumer,
  useInput,
} from 'react-admin';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import { Customer } from '../types';
import { Order } from 'public-contracts';
import { OrderTotalField } from './components/OrderTotalField';
import { ItemsGridInput } from './components/ItemsGridInput';
import { CustomerInput } from './components/CustomerInput';
import { PointsRedemption } from './components/PointsRedemption';
import { TableNumberGrid } from './components/TableNumberGrid';
import { useTableStatus } from './hooks/useTableStatus';
import { useEffect, useState } from 'react';

interface OrderFormData {
  reference: string;
  promotionType: 'none' | 'percentage' | 'fixed';
  promotionValue: number;
  customerPoints: number | null;
  pointsRedemption: number;
  orderType: 'dine_in' | 'takeaway' | 'delivery';
  tableNumber?: number;
  items: Array<{
    productId: string;
    quantity: number;
    price: number;
    note?: string;
  }>;
  date: Date;
  customerId?: string;
}

const OrderCreateToolbar = () => (
  <Toolbar>
    <SaveButton />
  </Toolbar>
);

const generateReference = () => {
  const now = new Date();
  const day = now.getDate().toString().padStart(2, '0');
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const year = now.getFullYear().toString().slice(-2);
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');
  return `ORD${year}${month}${day}${hours}${minutes}${seconds}`;
};

const TableSelectionDialog = ({
  open,
  onClose,
  value,
  onChange,
}: {
  open: boolean;
  onClose: () => void;
  value?: number;
  onChange: (value?: number) => void;
}) => {
  const translate = useTranslate();
  const [selectedTable, setSelectedTable] = useState<number | undefined>(value);
  const { field } = useInput({ source: 'tableNumber' });

  const handleConfirm = () => {
    onChange(selectedTable);
    field.onChange(selectedTable);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {translate('resources.orders.fields.tableNumber')}
      </DialogTitle>
      <DialogContent>
        <TableNumberGrid value={selectedTable} onChange={setSelectedTable} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate('ra.action.cancel')}</Button>
        <Button onClick={handleConfirm} variant="contained" color="primary">
          {translate('ra.action.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TableSection = () => {
  const translate = useTranslate();
  const [tableDialogOpen, setTableDialogOpen] = useState(false);
  const { field: orderTypeField } = useInput({ source: 'orderType' });
  const { field: tableNumberField } = useInput({ source: 'tableNumber' });

  if (orderTypeField.value !== 'dine_in') return null;

  return (
    <>
      <Box display="flex" alignItems="center" gap={2}>
        <Typography>
          {translate('resources.orders.fields.tableNumber')}:{' '}
          {tableNumberField.value ||
            translate('resources.orders.messages.noTableSelected')}
        </Typography>
        <Button variant="contained" onClick={() => setTableDialogOpen(true)}>
          {tableNumberField.value
            ? translate('resources.orders.actions.changeTable')
            : translate('resources.orders.actions.selectTable')}
        </Button>
      </Box>
      <TableSelectionDialog
        open={tableDialogOpen}
        onClose={() => setTableDialogOpen(false)}
        value={tableNumberField.value}
        onChange={(value) => {
          tableNumberField.onChange(value);
        }}
      />
      <TextInput source="tableNumber" style={{ display: 'none' }} />
    </>
  );
};

const PromotionSection = () => {
  const translate = useTranslate();
  const { field: promotionTypeField } = useInput({ source: 'promotionType' });
  const { field: customerPointsField } = useInput({ source: 'customerPoints' });
  const { field: itemsField } = useInput({ source: 'items' });

  const totalAmount = (itemsField.value || []).reduce(
    (sum: number, item: { price: number; quantity: number }) =>
      sum + item.price * item.quantity,
    0
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="subtitle1">
        {translate('resources.orders.fields.promotion')}
      </Typography>
      <SelectInput
        source="promotionType"
        label="resources.orders.fields.promotionType"
        defaultValue="none"
        fullWidth
        choices={[
          {
            id: 'none',
            name: translate('resources.orders.promotion.none'),
          },
          {
            id: 'percentage',
            name: translate('resources.orders.promotion.percentage'),
          },
          {
            id: 'fixed',
            name: translate('resources.orders.promotion.fixed'),
          },
        ]}
      />
      {promotionTypeField.value !== 'none' && (
        <NumberInput
          source="promotionValue"
          label="resources.orders.fields.promotionValue"
          fullWidth
          min={0}
          validate={(value) => {
            if (promotionTypeField.value === 'percentage' && value > 100) {
              return translate('resources.orders.messages.percentageExceeded');
            }
            if (promotionTypeField.value === 'fixed' && value > totalAmount) {
              return translate('resources.orders.messages.fixedAmountExceeded');
            }
          }}
        />
      )}

      {promotionTypeField.value === 'none' && (
        <PointsRedemption
          customerPoints={Number(customerPointsField.value || 0)}
          totalAmount={totalAmount}
        />
      )}
    </Box>
  );
};

const OrderForm = () => {
  const { identity } = useGetIdentity();
  const isStaff = identity?.roles?.includes('staff');
  const translate = useTranslate();

  return (
    <SimpleForm
      toolbar={<OrderCreateToolbar />}
      defaultValues={{
        reference: generateReference(),
        promotionType: 'none',
        promotionValue: 0,
        customerPoints: null,
        pointsRedemption: 0,
        orderType: 'dine_in',
        tableNumber: undefined,
        items: [],
        date: new Date(),
      }}
    >
      <TextInput
        source="reference"
        label="resources.orders.fields.reference"
        disabled
      />
      <SelectInput
        source="orderType"
        label="resources.orders.fields.orderType"
        choices={[
          {
            id: 'dine_in',
            name: translate('resources.orders.orderTypes.dineIn'),
          },
          {
            id: 'takeaway',
            name: translate('resources.orders.orderTypes.takeaway'),
          },
          {
            id: 'delivery',
            name: translate('resources.orders.orderTypes.delivery'),
          },
        ]}
        validate={required()}
      />
      <TableSection />
      <CustomerInput />
      <DateTimeInput
        source="date"
        label="resources.orders.fields.date"
        defaultValue={new Date()}
        validate={required()}
      />
      <ItemsGridInput />
      <OrderTotalField />
      <PromotionSection />
    </SimpleForm>
  );
};

export const OrderCreate = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const { occupyTable } = useTableStatus();

  const transform = (data: OrderFormData) => {
    const items = data.items.map((item) => ({
      product_id: item.productId,
      quantity: item.quantity,
      price: item.price,
      note: item.note,
    }));

    // Validate table number for dine-in orders
    if (data.orderType === 'dine_in' && !data.tableNumber) {
      throw new Error('Table number is required for dine-in orders');
    }

    // Create base order data
    const orderData = {
      ...data,
      items,
      status: 'ordered',
      pointsRedemption: data.pointsRedemption || 0,
    };

    // Remove tableNumber if not dine-in
    if (data.orderType !== 'dine_in') {
      delete orderData.tableNumber;
    }

    return orderData;
  };

  return (
    <Create
      transform={transform}
      mutationOptions={{
        onSuccess: (data: Order) => {
          // Update table status if it's a dine-in order
          if (data.orderType === 'dine_in' && data.tableNumber) {
            occupyTable(data.tableNumber, data.id);
          }
          redirect('list', 'orders');
        },
        onError: (error: any) => {
          notify(error.message, { type: 'error' });
        },
      }}
    >
      <OrderForm />
    </Create>
  );
};

export default OrderCreate;
