import * as React from 'react';
import { useRecordContext } from 'react-admin';

const AddressField = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>{record.address}</span>;
};

AddressField.defaultProps = {
  label: 'resources.orders.fields.address',
};

export default AddressField;
