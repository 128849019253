import {
  AutocompleteInput,
  DateTimeInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useGetIdentity,
  useRecordContext,
  useTranslate,
  SaveButton,
  Toolbar,
  useNotify,
  useUpdate,
  useRedirect,
  useRefresh,
} from 'react-admin';
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Customer } from '../types';
import { OrderTotalField } from './components/OrderTotalField';
import { ItemsGridInput } from './components/ItemsGridInput';
import { PrintBillButton } from './components/PrintBillButton';
import { useTableStatus } from './hooks/useTableStatus';
import { useEffect, useState } from 'react';

const OrderEditToolbar = () => {
  const record = useRecordContext();
  const { releaseTable } = useTableStatus();
  const notify = useNotify();
  const [update] = useUpdate();
  const translate = useTranslate();
  const redirect = useRedirect();
  const refresh = useRefresh();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleCancel = async () => {
    try {
      await update(
        'orders',
        {
          id: record.id,
          data: { ...record, status: 'cancelled' },
          previousData: record,
        },
        {
          onSuccess: async () => {
            // Release table if it's a dine-in order
            if (record.orderType === 'dine_in' && record.tableNumber) {
              await releaseTable(record.tableNumber);
            }
            notify('Order cancelled successfully', { type: 'success' });
            redirect('list', 'orders');
            refresh();
          },
          onError: (error) => {
            console.error('Error cancelling order:', error);
            notify('Error cancelling order', { type: 'error' });
          },
        }
      );
    } catch (error) {
      console.error('Error handling order cancellation:', error);
      notify('Error handling order cancellation', { type: 'error' });
    }
  };

  return (
    <>
      <Toolbar
        sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}
      >
        <Box>
          <SaveButton />
        </Box>
        {record?.status !== 'delivered' && <PrintBillButton />}
        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => setConfirmOpen(true)}
            disabled={
              record?.status === 'cancelled' || record?.status === 'delivered'
            }
          >
            {translate('resources.orders.actions.cancel')}
          </Button>
        </Box>
      </Toolbar>

      <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
        <DialogTitle>
          {translate('resources.orders.actions.cancel_confirm_title')}
        </DialogTitle>
        <DialogContent>
          {translate('resources.orders.actions.cancel_confirm_message')}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmOpen(false)}>
            {translate('common.actions.cancel')}
          </Button>
          <Button
            onClick={() => {
              setConfirmOpen(false);
              handleCancel();
            }}
            color="error"
            autoFocus
          >
            {translate('resources.orders.actions.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const OrderEdit = () => {
  const { identity } = useGetIdentity();
  const isStaff = identity?.roles?.includes('staff');
  const translate = useTranslate();
  const record = useRecordContext();
  const { releaseTable } = useTableStatus();
  const notify = useNotify();
  const [update] = useUpdate();

  // Release table when order is delivered or cancelled
  useEffect(() => {
    if (record && record.orderType === 'dine_in' && record.tableNumber) {
      if (record.status === 'delivered' || record.status === 'cancelled') {
        releaseTable(record.tableNumber);
      }
    }
  }, [record?.status, record?.orderType, record?.tableNumber, releaseTable]);

  const handleCancel = async () => {
    try {
      await update(
        'orders',
        {
          id: record.id,
          data: { ...record, status: 'cancelled' },
          previousData: record,
        },
        {
          onSuccess: async () => {
            // Release table if it's a dine-in order
            if (record.orderType === 'dine_in' && record.tableNumber) {
              await releaseTable(record.tableNumber);
            }
            notify('Order cancelled successfully', { type: 'success' });
          },
          onError: (error) => {
            console.error('Error cancelling order:', error);
            notify('Error cancelling order', { type: 'error' });
          },
        }
      );
    } catch (error) {
      console.error('Error handling order cancellation:', error);
      notify('Error handling order cancellation', { type: 'error' });
    }
  };

  const transform = (data: any) => {
    const items = data.items.map((item: any) => ({
      product_id: item.productId,
      quantity: item.quantity,
      price: item.price,
      note: item.note,
    }));

    return {
      ...data,
      items,
    };
  };

  return (
    <Edit transform={transform}>
      <SimpleForm toolbar={<OrderEditToolbar />}>
        <TextInput
          source="reference"
          label="resources.orders.fields.reference"
          disabled
        />
        <SelectInput
          source="orderType"
          label="resources.orders.fields.orderType"
          choices={[
            {
              id: 'dine_in',
              name: translate('resources.orders.orderTypes.dineIn'),
            },
            {
              id: 'takeaway',
              name: translate('resources.orders.orderTypes.takeaway'),
            },
            {
              id: 'delivery',
              name: translate('resources.orders.orderTypes.delivery'),
            },
          ]}
          disabled
        />
        {record?.orderType === 'dine_in' && (
          <TextInput
            source="tableNumber"
            label="resources.orders.fields.tableNumber"
            disabled
          />
        )}
        {!isStaff && (
          <ReferenceInput source="customerId" reference="customers">
            <AutocompleteInput
              optionText={(choice?: Customer) => {
                if (!choice?.id)
                  return translate('resources.orders.messages.unknownCustomer');
                const fullName = `${choice.first_name || ''} ${
                  choice.last_name || ''
                }`.trim();
                return (
                  fullName ||
                  translate('resources.orders.messages.unknownCustomer')
                );
              }}
              label="resources.orders.fields.customer"
              disabled
            />
          </ReferenceInput>
        )}
        <DateTimeInput
          source="date"
          label="resources.orders.fields.date"
          validate={required()}
          disabled
        />
        <SelectInput
          source="status"
          label="resources.orders.fields.status"
          choices={[
            { id: 'ordered', name: 'resources.orders.tabs.ordered' },
            { id: 'ready', name: 'resources.orders.tabs.ready' },
            { id: 'delivered', name: 'resources.orders.tabs.delivered' },
            { id: 'cancelled', name: 'resources.orders.tabs.cancelled' },
          ]}
        />
        <ItemsGridInput disabled={true} />
        <OrderTotalField />
        <Box sx={{ border: '1px solid #e0e0e0', p: 2, borderRadius: 1, mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            {translate('resources.orders.fields.promotion')}
          </Typography>
          <Box display="flex" gap={2}>
            <SelectInput
              source="promotionType"
              label="resources.orders.fields.promotionType"
              disabled
              choices={[
                { id: 'none', name: 'resources.orders.promotion.none' },
                {
                  id: 'percentage',
                  name: 'resources.orders.promotion.percentage',
                },
                { id: 'fixed', name: 'resources.orders.promotion.fixed' },
              ]}
            />
            <NumberInput
              source="promotionValue"
              label="resources.orders.fields.promotionValue"
              min={0}
              disabled
            />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  );
};

export default OrderEdit;
