import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { useTranslate } from 'react-admin';

interface PasskeyRegistrationDialogProps {
  open: boolean;
  onClose: () => void;
  onRegister: () => Promise<void>;
}

const PasskeyRegistrationDialog = ({
  open,
  onClose,
  onRegister,
}: PasskeyRegistrationDialogProps) => {
  const translate = useTranslate();

  const handleRegister = async () => {
    try {
      await onRegister();
      onClose();
    } catch (error) {
      console.error('Passkey registration error:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center" gap={1}>
          <FingerprintIcon color="primary" />
          {translate('ra.auth.register_passkey')}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography>
          {translate('ra.auth.register_passkey_description')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate('ra.action.skip')}</Button>
        <Button onClick={handleRegister} variant="contained" color="primary">
          {translate('ra.auth.register_passkey_button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasskeyRegistrationDialog;
