import { styled } from '@mui/styles';
import * as React from 'react';
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  TextInput,
  DateInput,
  SelectInput,
  AutocompleteInput,
  useNotify,
  useRedirect,
  required,
  useGetOne,
  useRecordContext,
  useTranslate,
} from 'react-admin';

const ValidationError = styled('span')({
  color: 'red',
});

const BookTitle = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  const {
    data: book,
    isLoading,
    error,
  } = useGetOne('books', { id: record?.bookId }, { enabled: !!record?.bookId });

  if (isLoading) return <span>{translate('ra.message.loading')}</span>;
  if (error) return <span>{translate('ra.message.error')}</span>;
  if (!book)
    return (
      <span>{translate('resources.book_rents.messages.book_not_found')}</span>
    );

  return (
    <ReferenceInput source="bookId" reference="books">
      <AutocompleteInput
        optionText="title"
        label="resources.book_rents.fields.title"
        fullWidth
        disabled
        defaultValue={record?.bookId}
      />
    </ReferenceInput>
  );
};

const RentEdit = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext();
  const translate = useTranslate();

  const rentLocations = [
    {
      id: 'IN_STORE',
      name: translate('resources.book_rents.location.IN_STORE'),
    },
    {
      id: 'OUTSIDE_STORE',
      name: translate('resources.book_rents.location.OUTSIDE_STORE'),
    },
  ];

  const bookStatuses = [
    { id: 'RENTED', name: translate('resources.book_rents.status.RENTED') },
    { id: 'RETURNED', name: translate('resources.book_rents.status.RETURNED') },
  ];

  const mutate = React.useCallback(
    (data: any) => {
      notify(translate('resources.book_rents.messages.update_success'));
      redirect('list', 'book_rents');
    },
    [notify, redirect, translate]
  );

  const mutationOptions = React.useMemo(
    () => ({
      onSuccess: mutate,
    }),
    [mutate]
  );

  // Check if the rent is currently overdue
  const isOverdue = React.useMemo(() => {
    if (!record) return false;
    const dueDate = new Date(record.dueDate);
    const now = new Date();
    return record.status === 'RENTED' && dueDate < now;
  }, [record]);

  const transform = (data: any) => {
    // Check if the rent is overdue
    const dueDate = new Date(data.dueDate);
    const now = new Date();
    const isOverdue = data.status === 'RENTED' && dueDate < now;

    // Add status field
    const dataWithStatus = {
      ...data,
      status: isOverdue ? 'OVERDUE' : data.status,
    };

    // If no customer is selected for in-store rent
    if (!data.customerId) {
      const { customerId, ...rest } = dataWithStatus;
      return rest;
    }
    return dataWithStatus;
  };

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.trackingCode) {
      errors.trackingCode = translate(
        'resources.book_rents.messages.book_not_found'
      );
    }

    // Only require customer if isOutsideStore is true
    if (values.isOutsideStore && !values.customerId) {
      errors.customerId = translate(
        'resources.book_rents.messages.customer_required'
      );
    }

    return errors;
  };

  return (
    <Edit transform={transform} mutationOptions={mutationOptions}>
      <SimpleForm validate={validate}>
        <TextInput
          source="trackingCode"
          label="resources.book_rents.fields.trackingCode"
          disabled
        />
        <BookTitle />
        <SelectInput
          source="rentLocation"
          label="resources.book_rents.fields.rentLocation"
          choices={rentLocations}
        />
        <ReferenceInput
          source="customerId"
          reference="customers"
          queryOptions={{ meta: { brief: true } }}
        >
          <AutocompleteInput
            optionText={(record) => record.name}
            label="resources.book_rents.fields.customerId"
            fullWidth
          />
        </ReferenceInput>
        <DateInput
          source="rentDate"
          label="resources.book_rents.fields.rentDate"
        />
        <DateInput
          source="dueDate"
          label="resources.book_rents.fields.dueDate"
        />
        {isOverdue ? (
          <TextInput
            source="status"
            disabled
            defaultValue="OVERDUE"
            helperText={translate('resources.book_rents.messages.overdue_help')}
          />
        ) : (
          <SelectInput
            source="status"
            label="resources.book_rents.fields.status"
            disabled
            choices={bookStatuses}
          />
        )}
      </SimpleForm>
    </Edit>
  );
};

export default RentEdit;
