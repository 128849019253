import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  CreateButton,
  useRecordContext,
} from 'react-admin';
import { PaymentGateway } from 'public-contracts';

interface QRCodeFieldProps {
  source: keyof PaymentGateway;
  label?: string;
}

const QRCodeField: React.FC<QRCodeFieldProps> = ({ source }) => {
  const record = useRecordContext<PaymentGateway>();
  if (!record?.[source]) return null;
  return (
    <img
      src={record[source] as string}
      alt="QR Code"
      style={{ width: 50, height: 50, objectFit: 'contain' }}
    />
  );
};

export const PaymentGatewayList: React.FC = () => {
  return (
    <List actions={<CreateButton />}>
      <Datagrid>
        <TextField source="name" />
        <TextField source="description" />
        <QRCodeField source="staticQrCode" label="QR Code" />
        <BooleanField source="isDefault" />
        <BooleanField source="isSandbox" label="Sandbox Mode" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
