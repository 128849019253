import { styled } from '@mui/styles';
import * as React from 'react';
import {
  Create,
  SimpleForm,
  ReferenceInput,
  TextInput,
  DateInput,
  SelectInput,
  AutocompleteInput,
  useNotify,
  useRedirect,
  useGetList,
  Loading,
  required,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

const ValidationError = styled('span')({
  color: 'red',
});

const BookStatus = ({ trackingCode }: { trackingCode: string }) => {
  const { setValue } = useFormContext();
  const translate = useTranslate();

  const { data: books, isLoading: isLoadingBook } = useGetList(
    'books',
    {
      filter: { trackingCode },
      pagination: { page: 1, perPage: 1 },
    },
    { enabled: !!trackingCode }
  );

  // Get the book ID if available
  const bookId = React.useMemo(() => {
    return books && books.length > 0 ? books[0].id : null;
  }, [books]);

  // Query rent records for the book
  const { data: rents, isLoading: isLoadingRents } = useGetList(
    'book_rents',
    {
      filter: { bookId },
      pagination: { page: 1, perPage: 1 },
      sort: { field: 'rentDate', order: 'DESC' },
    },
    { enabled: !!bookId }
  );

  const isAvailable = React.useMemo(() => {
    if (!bookId || isLoadingRents || !rents) return false;
    return !rents.length || rents[0].status === 'RETURNED';
  }, [bookId, isLoadingRents, rents]);

  const isChecking = React.useMemo(() => {
    return isLoadingBook || (bookId && isLoadingRents);
  }, [isLoadingBook, bookId, isLoadingRents]);

  // Update form when book is found or cleared
  React.useEffect(() => {
    if (!isChecking) {
      setValue('bookId', bookId || '');
      setValue('isAvailable', isAvailable);
      if (books?.[0]) {
        setValue('title', books[0].title);
      }
    }
  }, [setValue, bookId, isAvailable, isChecking, books]);

  return (
    <>
      {isChecking && <Loading />}
      {!isChecking && trackingCode && !bookId && (
        <ValidationError>
          {translate('resources.book_rents.messages.book_not_found')}
        </ValidationError>
      )}
      {!isChecking && bookId && !isAvailable && (
        <ValidationError>
          {translate('resources.book_rents.messages.book_not_available')}
        </ValidationError>
      )}
    </>
  );
};

const BookInput = () => {
  const [trackingCode, setTrackingCode] = React.useState('');
  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTrackingCode(event.target.value);
    },
    []
  );

  return (
    <>
      <TextInput
        source="trackingCode"
        label="resources.book_rents.fields.trackingCode"
        autoFocus
        onChange={handleChange}
        validate={required()}
      />
      {trackingCode && <BookStatus trackingCode={trackingCode} />}
    </>
  );
};

const RentCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const now = React.useRef(new Date());
  const dueDate = React.useMemo(() => {
    return new Date(now.current.getTime() + 7 * 24 * 60 * 60 * 1000);
  }, [now]);

  const rentLocations = [
    {
      id: 'IN_STORE',
      name: translate('resources.book_rents.location.IN_STORE'),
    },
    {
      id: 'OUTSIDE_STORE',
      name: translate('resources.book_rents.location.OUTSIDE_STORE'),
    },
  ];

  const mutate = React.useCallback(
    (data: any) => {
      notify(translate('resources.book_rents.messages.create_success'));
      redirect('list', 'book_rents');
    },
    [notify, redirect, translate]
  );

  const mutationOptions = React.useMemo(
    () => ({
      onSuccess: mutate,
    }),
    [mutate]
  );

  const transform = (data: any) => {
    // Add status field
    const dataWithStatus = {
      ...data,
      status: 'RENTED',
    };

    // If no customer is selected for in-store rent
    if (!data.customerId) {
      const { customerId, ...rest } = dataWithStatus;
      return rest;
    }
    return dataWithStatus;
  };

  const validate = (values: any) => {
    const errors: any = {};

    if (!values.trackingCode) {
      errors.trackingCode = translate(
        'resources.book_rents.messages.book_not_found'
      );
    }

    if (!values.bookId) {
      errors.trackingCode = translate(
        'resources.book_rents.messages.book_not_found'
      );
    }

    if (!values.isAvailable) {
      errors.trackingCode = translate(
        'resources.book_rents.messages.book_not_available'
      );
    }

    // Only require customer if isOutsideStore is true
    if (values.isOutsideStore && !values.customerId) {
      errors.customerId = translate(
        'resources.book_rents.messages.customer_required'
      );
    }

    return errors;
  };

  return (
    <Create transform={transform} mutationOptions={mutationOptions}>
      <SimpleForm validate={validate}>
        <BookInput />
        <TextInput
          source="title"
          label="resources.book_rents.fields.title"
          fullWidth
          disabled
        />
        <SelectInput
          source="rentLocation"
          label="resources.book_rents.fields.rentLocation"
          choices={rentLocations}
          defaultValue="IN_STORE"
        />
        <ReferenceInput
          source="customerId"
          reference="customers"
          queryOptions={{ meta: { brief: true } }}
        >
          <AutocompleteInput
            optionText={(record) => `${record.firstName} ${record.lastName}`}
            label="resources.book_rents.fields.customerId"
            fullWidth
          />
        </ReferenceInput>
        <DateInput
          source="dueDate"
          label="resources.book_rents.fields.dueDate"
          defaultValue={dueDate}
        />
        <DateInput
          source="rentDate"
          label="resources.book_rents.fields.rentDate"
          defaultValue={now.current}
        />
      </SimpleForm>
    </Create>
  );
};

export default RentCreate;
