import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGetList, useTranslate } from 'react-admin';
import { normalizeVietnamese } from '../../utils/normalizeVietnamese';
import Image from '../../components/Image';

interface ProductGridSelectorProps {
  disabled?: boolean;
  onAddProduct: (product: Product) => void;
  onClose?: () => void;
  currentItems?: OrderItem[];
}

interface Product {
  id: string | number;
  name: string;
  price: number;
  reference?: string;
  imageId?: string;
  quantity?: number;
}

interface OrderItem {
  productId: string | number;
  quantity: number;
  price: number;
  note?: string;
}

const PAGE_SIZE = 12; // 3x4 grid

export const ProductGridSelector = ({
  disabled,
  onAddProduct,
  onClose,
  currentItems = [],
}: ProductGridSelectorProps) => {
  const theme = useTheme();
  const translate = useTranslate();
  const [searchText, setSearchText] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [page, setPage] = useState(1);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [hasMore, setHasMore] = useState(true);

  const {
    data: products = [],
    total,
    isLoading,
  } = useGetList<Product>('products', {
    pagination: { page, perPage: PAGE_SIZE },
    sort: { field: 'name', order: 'ASC' },
    filter: debouncedSearch
      ? {
          q: normalizeVietnamese(debouncedSearch),
        }
      : undefined,
  });

  const [allProducts, setAllProducts] = useState<Product[]>(products);

  // Get current quantity for a product
  const getProductQuantity = useCallback(
    (productId: string | number) => {
      const item = currentItems.find((item) => item.productId === productId);
      return item?.quantity || 0;
    },
    [currentItems]
  );

  // Debounce the search text update
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchText);
      setPage(1); // Reset page when search changes
      setAllProducts([]); // Clear products when search changes
      setHasMore(true); // Reset hasMore when search changes
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  // Update allProducts when new products are loaded
  useEffect(() => {
    if (products.length > 0) {
      if (page === 1) {
        setAllProducts(products);
      } else {
        setAllProducts((prev) => {
          const newProducts = [...prev];
          products.forEach((product) => {
            if (!newProducts.find((p) => p.id === product.id)) {
              newProducts.push(product);
            }
          });
          return newProducts;
        });
      }
    }
  }, [products, page]);

  // Update hasMore when total changes
  useEffect(() => {
    if (total !== undefined) {
      setHasMore(allProducts.length < total);
    }
  }, [total, allProducts.length]);

  // Handle infinite scroll
  const handleScroll = useCallback(() => {
    if (!scrollContainerRef.current || isLoading || !hasMore) return;

    const container = scrollContainerRef.current;
    const { scrollTop, scrollHeight, clientHeight } = container;

    // Load more when near bottom
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      setPage((prev) => prev + 1);
    }
  }, [isLoading, hasMore]);

  // Add scroll event listener
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    []
  );

  const handleAddProduct = useCallback(
    (product: Product) => {
      if (!disabled) {
        onAddProduct({
          id: product.id,
          name: product.name,
          price: product.price,
          reference: product.reference,
          imageId: product.imageId,
        } as Product);
      }
    },
    [disabled, onAddProduct]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        position: 'relative',
      }}
    >
      {/* Fixed Header */}
      <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={translate('resources.orders.fields.searchProducts')}
          value={searchText}
          onChange={handleSearch}
          disabled={disabled}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <Box
        ref={scrollContainerRef}
        sx={{
          flex: 1,
          overflow: 'auto',
          p: 2,
        }}
      >
        {isLoading && allProducts.length === 0 ? (
          <Box display="flex" justifyContent="center" mt={2}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {(allProducts.length > 0 ? allProducts : products).map(
              (product) => {
                const quantity = getProductQuantity(product.id);
                return (
                  <Grid item xs={12} sm={6} md={4} key={product.id}>
                    <Card
                      sx={{
                        height: '100%',
                        cursor: disabled ? 'not-allowed' : 'pointer',
                        '&:hover': {
                          bgcolor: disabled
                            ? undefined
                            : theme.palette.action.hover,
                        },
                      }}
                      onClick={() => handleAddProduct(product)}
                    >
                      {product.imageId && (
                        <CardMedia sx={{ height: 140, position: 'relative' }}>
                          <Image id={product.imageId} alt={product.name} />
                        </CardMedia>
                      )}
                      <CardContent>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="flex-start"
                        >
                          <Box flex={1}>
                            <Typography
                              variant="subtitle1"
                              component="div"
                              gutterBottom
                            >
                              {product.name}
                            </Typography>
                            {product.reference && (
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {product.reference}
                              </Typography>
                            )}
                            <Typography variant="body1" color="primary">
                              {product.price.toLocaleString()} đ
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="column" gap={1}>
                            <Badge
                              badgeContent={quantity}
                              color="primary"
                              invisible={quantity === 0}
                              sx={{
                                '& .MuiBadge-badge': {
                                  right: -3,
                                  top: -3,
                                },
                              }}
                            >
                              <IconButton
                                size="small"
                                color="primary"
                                disabled={disabled}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAddProduct(product);
                                }}
                                sx={{
                                  bgcolor: 'action.selected',
                                }}
                              >
                                <AddIcon />
                              </IconButton>
                            </Badge>
                            {quantity > 0 && (
                              <IconButton
                                size="small"
                                color="error"
                                disabled={disabled}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const existingItemIndex =
                                    currentItems.findIndex(
                                      (item) => item.productId === product.id
                                    );
                                  if (existingItemIndex >= 0) {
                                    const newItems = [...currentItems];
                                    newItems[existingItemIndex] = {
                                      ...newItems[existingItemIndex],
                                      quantity: Math.max(
                                        0,
                                        (newItems[existingItemIndex].quantity ||
                                          0) - 1
                                      ),
                                    };
                                    onAddProduct({
                                      ...product,
                                      quantity:
                                        newItems[existingItemIndex].quantity,
                                    });
                                  }
                                }}
                                sx={{
                                  bgcolor: 'action.selected',
                                }}
                              >
                                <RemoveIcon />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              }
            )}
          </Grid>
        )}
        {isLoading && allProducts.length > 0 && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 80,
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>

      {/* Fixed Footer */}
      <Box
        sx={{
          p: 2,
          borderTop: 1,
          borderColor: 'divider',
          bgcolor: 'background.paper',
        }}
      >
        <Button
          fullWidth
          variant="outlined"
          color="inherit"
          onClick={onClose}
          startIcon={<CloseIcon />}
        >
          {translate('ra.action.close')}
        </Button>
      </Box>
    </Box>
  );
};
