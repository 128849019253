import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFileUpload } from '../hooks/useFileUpload';

const fileUrlCache = new Map<string, string>();

const Image = (props: { id: string; alt?: string }) => {
  const [fileUrl, setFileUrl] = useState<string>('');
  const { getFileUrl } = useFileUpload();

  useEffect(() => {
    (async () => {
      if (fileUrlCache.has(props.id)) {
        setFileUrl(fileUrlCache.get(props.id)!);
        return;
      }
      if (typeof props.id === 'object' && 'src' in props.id) {
        const url = (props.id as object & { src: string }).src;
        if (url) {
          setFileUrl(url);
          fileUrlCache.set(props.id, url);
        }
      } else if (typeof props.id === 'string') {
        const url = await getFileUrl(props.id);
        if (url) {
          setFileUrl(url);
          fileUrlCache.set(props.id, url);
        }
      }
    })();
  }, [getFileUrl, props.id]);

  return <Box component="img" src={fileUrl} alt={props.alt || ''} />;
};

export default Image;
