import {
  Datagrid,
  DateField,
  List,
  TextField,
  SearchInput,
  Button,
  useNotify,
  useRecordContext,
  useListContext,
  TopToolbar,
  useTranslate,
  SimpleList,
  DateInput,
  useDataProvider,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import CheckinDialog from './CheckinDialog';
import QRScannerDialog from './QRScannerDialog';
import { Box, Chip, Tabs, Tab, IconButton } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ProfileDialog from './ProfileDialog';

// Get default date range (30 days ago to today)
const getDefaultDateRange = () => {
  const today = new Date();
  today.setHours(23, 59, 59, 999);
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  thirtyDaysAgo.setHours(0, 0, 0, 0);
  return {
    $gte: thirtyDaysAgo.toISOString(),
    $lte: today.toISOString(),
  };
};

const todayFilters = [<SearchInput key="search" source="q" alwaysOn />];
const yesterdayFilters = [<SearchInput key="search" source="q" alwaysOn />];

const allTimeFilters = [
  <SearchInput key="search" source="q" alwaysOn />,
  <DateInput
    key="createdAtFrom"
    source="createdAt.$gte"
    label="resources.integrations.filters.createdAtFrom"
    alwaysOn
  />,
  <DateInput
    key="createdAtTo"
    source="createdAt.$lte"
    label="resources.integrations.filters.createdAtTo"
    alwaysOn
  />,
];

const CheckinStatusField = ({
  setOpen,
  setCustomerId,
  setCode,
}: {
  setOpen: (open: boolean) => void;
  setCustomerId: (customerId: string) => void;
  setCode: (code: string) => void;
}) => {
  const record = useRecordContext();
  const { filterValues } = useListContext();
  const [qrOpen, setQrOpen] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();
  const navigate = useNavigate();

  if (!record) return null;
  if (record.data.customerId) setCustomerId(record.data.customerId);
  if (record.data.otp) setCode(record.data.otp);

  const isCheckedIn = () => {
    const createdAt = record.createdAt;
    if (!createdAt) return false;

    // If we have date range filters
    if (filterValues?.createdAt?.$gte && filterValues?.createdAt?.$lte) {
      const fromDate = new Date(filterValues.createdAt.$gte);
      fromDate.setHours(0, 0, 0, 0);
      const toDate = new Date(filterValues.createdAt.$lte);
      toDate.setHours(23, 59, 59, 999);

      const createdAtDate = new Date(createdAt);
      return createdAtDate >= fromDate && createdAtDate <= toDate;
    }

    // For today/yesterday tabs
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      .toISOString()
      .split('T')[0];
    const yesterday = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 1
    )
      .toISOString()
      .split('T')[0];

    const createdAtDateStr = new Date(createdAt).toISOString().split('T')[0];
    return createdAtDateStr === today || createdAtDateStr === yesterday;
  };

  if (isCheckedIn()) {
    return (
      <Chip
        icon={<CheckCircleIcon />}
        label={translate('resources.integrations.status.checkedIn')}
        color="success"
      />
    );
  }

  return (
    <Box display="flex" gap={1}>
      <Chip
        icon={<PendingIcon />}
        label={translate('resources.integrations.status.notCheckedIn')}
        color="warning"
      />
      <Button
        label={translate('resources.integrations.actions.checkIn')}
        onClick={() => setOpen(true)}
      />
      <Button
        label={translate('resources.integrations.actions.scanQR')}
        onClick={() => setQrOpen(true)}
        startIcon={<QrCodeScannerIcon />}
      />

      <QRScannerDialog
        open={qrOpen}
        onClose={() => setQrOpen(false)}
        onSuccess={(customerId, code) => {
          navigate(`/zalo/checkin?code=${customerId}:${code}`);
          notify(translate('resources.integrations.messages.qrScanSuccess'), {
            type: 'success',
          });
          setQrOpen(false);
        }}
        onError={(error) => {
          notify(
            error || translate('resources.integrations.messages.qrScanFailed'),
            { type: 'error' }
          );
        }}
      />
    </Box>
  );
};

interface CheckinRecord {
  id: string;
  data: {
    customerId: string;
    profile: {
      firstName: string;
      lastName: string;
    };
  };
  createdAt: string;
}

const GroupedCheckinList = () => {
  const { data, refetch } = useListContext();
  const notify = useNotify();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const [open, setOpen] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [checkingCode, setCheckingCode] = useState('');
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  // get from search params
  const { search } = useLocation();
  const codeParam = new URLSearchParams(search).get('code');
  // if the current route has code&customerId, open checkin dialog
  useEffect(() => {
    if (!codeParam) {
      return;
    }
    const [customerId, code] = codeParam.split(':');
    if (code && customerId) {
      setOpen(true);
      setCustomerId(customerId);
      setCheckingCode(code);
    }
  }, [codeParam]);

  const handleSuccess = async () => {
    try {
      // First get the customer entity ID from social profile
      const { data: socialProfiles } = await dataProvider.getList(
        'social_profiles',
        {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            socialProfileId: customerId,
            socialProfileType: 'zalo',
          },
        }
      );

      if (!socialProfiles || socialProfiles.length === 0) {
        notify(translate('resources.orders.messages.checkinSuccess'), {
          type: 'success',
        });
        setOpen(false);
        await refetch();
        return;
      }

      // Now get membership data using customer entity ID
      const { data: integrations } = await dataProvider.getList(
        'integrations',
        {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'createdAt', order: 'DESC' },
          filter: {
            type: 'customer_membership',
            'data.customerId': socialProfiles[0].entityId,
          },
        }
      );

      if (integrations && integrations.length > 0) {
        notify(
          translate('resources.orders.messages.checkinSuccessWithPoints', {
            points: integrations[0].data.points.toLocaleString(),
          }),
          { type: 'success' }
        );
      } else {
        notify(translate('resources.orders.messages.checkinSuccess'), {
          type: 'success',
        });
      }
      setOpen(false);
      await refetch();
    } catch (error) {
      notify(translate('resources.orders.messages.checkinSuccess'), {
        type: 'success',
      });
      setOpen(false);
      await refetch();
    }
  };

  // Group by customerId and take latest record for each
  const groupedData = data?.reduce((acc, record) => {
    const customerId = record.data.customerId;
    if (
      !acc[customerId] ||
      new Date(acc[customerId].createdAt) < new Date(record.createdAt)
    ) {
      acc[customerId] = record;
    }
    return acc;
  }, {});

  // Convert back to array and sort by customerId
  const sortedData = Object.values(groupedData || {}).sort((a: any, b: any) =>
    a.data.customerId?.localeCompare(b.data.customerId)
  );

  return (
    <>
      {isSmall ? (
        <SimpleList<CheckinRecord>
          data={sortedData as CheckinRecord[]}
          primaryText={(record) => record.data.customerId}
          secondaryText={(record) =>
            `${record.data.profile?.firstName ?? ''} ${
              record.data.profile?.lastName ?? ''
            }`
          }
          tertiaryText={(record) => (
            <Box display="flex" gap={1} alignItems="center">
              <CheckinStatusField
                setOpen={setOpen}
                setCustomerId={setCustomerId}
                setCode={setCheckingCode}
              />
              <IconButton
                onClick={() => setQrOpen(true)}
                size="small"
                color="primary"
                title={translate('resources.integrations.actions.scanQR')}
              >
                <QrCodeScannerIcon />
              </IconButton>
            </Box>
          )}
        />
      ) : (
        <Datagrid data={sortedData}>
          <TextField source="data.customerId" label="Customer ID" />
          <TextField source="data.profile.firstName" label="First Name" />
          <TextField source="data.profile.lastName" label="Last Name" />
          <CheckinStatusField
            setOpen={setOpen}
            setCustomerId={setCustomerId}
            setCode={setCheckingCode}
          />
          <DateField source="createdAt" showTime />
        </Datagrid>
      )}
      <QRScannerDialog
        open={qrOpen}
        onClose={() => setQrOpen(false)}
        onSuccess={(customerId, code) => {
          navigate(`/zalo/checkin?code=${customerId}:${code}`);
          notify(translate('resources.integrations.messages.qrScanSuccess'), {
            type: 'success',
          });
          setQrOpen(false);
        }}
        onError={(error) => {
          notify(
            error || translate('resources.integrations.messages.qrScanFailed'),
            { type: 'error' }
          );
        }}
      />
      <CheckinDialog
        open={open}
        onClose={() => setOpen(false)}
        customerId={customerId}
        code={checkingCode}
        onSuccess={handleSuccess}
        onError={(error) => {
          notify(
            error || translate('resources.orders.messages.checkinFailed'),
            { type: 'error' }
          );
        }}
      />
    </>
  );
};

const ListActions = () => {
  const [qrOpen, setQrOpen] = useState(false);
  const notify = useNotify();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const translate = useTranslate();

  return (
    <TopToolbar
      sx={{
        position: 'absolute',
        top: -70,
        right: isSmall ? 'auto' : 0,
      }}
    >
      {isSmall && (
        <IconButton
          onClick={() => setQrOpen(true)}
          size="large"
          color="primary"
        >
          <QrCodeScannerIcon sx={{ fontSize: 40 }} />
        </IconButton>
      )}
      {!isSmall && (
        <Button
          label={translate('resources.integrations.actions.scanQR')}
          onClick={() => setQrOpen(true)}
          startIcon={<QrCodeScannerIcon />}
        />
      )}
      <QRScannerDialog
        open={qrOpen}
        onClose={() => setQrOpen(false)}
        onSuccess={() => {
          notify(translate('resources.integrations.messages.qrScanSuccess'), {
            type: 'success',
          });
          setQrOpen(false);
        }}
        onError={(error) => {
          notify(
            error || translate('resources.integrations.messages.qrScanFailed'),
            { type: 'error' }
          );
        }}
      />
    </TopToolbar>
  );
};

const CheckinTabs = () => {
  const [tab, setTab] = useState(0);
  const [currentFilter, setCurrentFilter] = useState<Record<string, any>>({});
  const translate = useTranslate();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Get today's date at start of day in local timezone
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);

    // Get yesterday's date at start of day
    const yesterdayStart = new Date(todayStart);
    yesterdayStart.setDate(yesterdayStart.getDate() - 1);

    // Get end times
    const todayEnd = new Date(todayStart);
    todayEnd.setHours(23, 59, 59, 999);

    const yesterdayEnd = new Date(yesterdayStart);
    yesterdayEnd.setHours(23, 59, 59, 999);

    const baseFilter = {
      type: { $regex: '^zalo_checkin_otp-', $options: 'i' },
    };

    switch (tab) {
      case 0: // Today
        setCurrentFilter({
          ...baseFilter,
          createdAt: {
            $gte: todayStart.toISOString(),
            $lte: todayEnd.toISOString(),
          },
        });
        break;
      case 1: // Yesterday
        setCurrentFilter({
          ...baseFilter,
          createdAt: {
            $gte: yesterdayStart.toISOString(),
            $lte: yesterdayEnd.toISOString(),
          },
        });
        break;
      case 2: // All time
        // Only set default range if no date filter exists
        if (!location.search.includes('createdAt')) {
          const defaultRange = getDefaultDateRange();
          setCurrentFilter({
            ...baseFilter,
            createdAt: defaultRange,
          });
          // Update URL with default filter
          const filterStr = encodeURIComponent(
            JSON.stringify({
              ...baseFilter,
              createdAt: defaultRange,
            })
          );
          navigate(`${location.pathname}?filter=${filterStr}`, {
            replace: true,
          });
        } else {
          setCurrentFilter(baseFilter);
        }
        break;
    }
  }, [tab, location, navigate]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)}>
          <Tab label={translate('resources.integrations.tabs.today')} />
          <Tab label={translate('resources.integrations.tabs.yesterday')} />
          <Tab label={translate('resources.integrations.tabs.allTime')} />
        </Tabs>
      </Box>
      <List
        resource="integrations"
        filters={
          tab === 0
            ? todayFilters
            : tab === 1
            ? yesterdayFilters
            : allTimeFilters
        }
        filter={currentFilter}
        sort={{ field: 'createdAt', order: 'DESC' }}
        actions={<ListActions />}
      >
        <GroupedCheckinList />
      </List>
    </>
  );
};

const CheckinList = () => {
  return <CheckinTabs />;
};

export default CheckinList;
