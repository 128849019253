import {
  Datagrid,
  DateField,
  EmailField,
  List,
  FunctionField,
} from 'react-admin';
import UserNameWithStaffBadge from '../components/UserNameWithStaffBadge';
import { User } from 'public-contracts';

const UserList = () => (
  <List>
    <Datagrid rowClick="edit">
      <UserNameWithStaffBadge
        source="name"
        label="resources.users.fields.name"
      />
      <EmailField source="email" />
      <FunctionField
        label="resources.users.fields.role"
        render={(record: User) => record.roles?.join(', ') || ''}
      />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);

export default UserList;
