import { useGetIdentity, ResourceContextProvider } from 'react-admin';
import AttendanceRecordListViews from './AttendanceRecordListViews';

export const StaffAttendanceView = () => {
  const { identity } = useGetIdentity();
  const isStoreManager = identity?.roles?.includes('storeManager');

  // Store managers can see all records, staff can only see their own
  const filter = isStoreManager ? {} : { userId: identity?.id };

  return (
    <ResourceContextProvider value="attendance_records">
      <AttendanceRecordListViews
        filter={filter}
        disableCreate={!isStoreManager}
        disableBulkActions={!isStoreManager}
      />
    </ResourceContextProvider>
  );
};
