import { Theme, useMediaQuery } from '@mui/material';
import { startOfDay, subDays } from 'date-fns';
import { CSSProperties, useMemo } from 'react';
import {
  LoadingPage,
  useAuthProvider,
  useGetList,
  useGetIdentity,
} from 'react-admin';

import MonthlyRevenue from './MonthlyRevenue';
import NbNewOrders from './NbNewOrders';
import NewCustomers from './NewCustomers';
import OrderChart from './OrderChart';
import PendingOrders from './PendingOrders';
import PendingReviews from './PendingReviews';
import Welcome from './Welcome';

import { configs } from '../configs';
import { Order } from '../types';

interface OrderStats {
  revenue: number;
  nbNewOrders: number;
  pendingOrders: Order[];
}

interface State {
  nbNewOrders?: number;
  pendingOrders?: Order[];
  recentOrders?: Order[];
  revenue?: string;
}

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = () => {
  const authProvider = useAuthProvider();
  const { identity } = useGetIdentity();
  const isStaff = identity?.roles?.includes('staff');
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const aMonthAgo = useMemo(() => subDays(startOfDay(new Date()), 30), []);

  const { data: orders } = useGetList<Order>('orders', {
    filter: { dateGte: aMonthAgo.toISOString() },
    sort: { field: 'date', order: 'DESC' },
    pagination: { page: 1, perPage: 50 },
  });

  const aggregation = useMemo<State>(() => {
    if (!orders) return {};

    const aggregations = orders
      .filter((order) => order.status !== 'cancelled')
      .reduce(
        (stats: OrderStats, order) => {
          if (order.status !== 'cancelled') {
            stats.revenue += order.total;
            stats.nbNewOrders++;
          }
          if (order.status === 'ordered') {
            stats.pendingOrders.push(order);
          }
          return stats;
        },
        {
          revenue: 0,
          nbNewOrders: 0,
          pendingOrders: [],
        }
      );
    return {
      recentOrders: orders,
      revenue: aggregations.revenue.toLocaleString(undefined, {
        style: 'currency',
        currency: configs.priceUnit.text,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      nbNewOrders: aggregations.nbNewOrders,
      pendingOrders: aggregations.pendingOrders,
    };
  }, [orders]);

  if (!authProvider.auth.isAuthenticated()) {
    return <LoadingPage />;
  }
  const { nbNewOrders, pendingOrders, revenue, recentOrders } = aggregation;
  return isXSmall ? (
    <div>
      <div style={styles.flexColumn as CSSProperties}>
        <Welcome />
        {!isStaff && <MonthlyRevenue value={revenue} />}
        <VerticalSpacer />
        <NbNewOrders value={nbNewOrders} />
        <VerticalSpacer />
        <PendingOrders orders={pendingOrders} />
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn as CSSProperties}>
      <div style={styles.singleCol}>
        <Welcome />
      </div>
      {!isStaff && (
        <div style={styles.flex}>
          <MonthlyRevenue value={revenue} />
          <Spacer />
          <NbNewOrders value={nbNewOrders} />
        </div>
      )}
      {isStaff && (
        <div style={styles.singleCol}>
          <NbNewOrders value={nbNewOrders} />
        </div>
      )}
      <div style={styles.singleCol}>
        <OrderChart orders={recentOrders} />
      </div>
      <div style={styles.singleCol}>
        <PendingOrders orders={pendingOrders} />
      </div>
    </div>
  ) : (
    <>
      <Welcome />
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          {!isStaff ? (
            <div style={styles.flex}>
              <MonthlyRevenue value={revenue} />
              <Spacer />
              <NbNewOrders value={nbNewOrders} />
            </div>
          ) : (
            <div style={styles.singleCol}>
              <NbNewOrders value={nbNewOrders} />
            </div>
          )}
          <div style={styles.singleCol}>
            {!isStaff && <OrderChart orders={recentOrders} />}
          </div>
          <div style={styles.singleCol}>
            <PendingOrders orders={pendingOrders} />
          </div>
        </div>
        {!isStaff && (
          <div style={styles.rightCol}>
            <div style={styles.flex}>
              <PendingReviews />
              <Spacer />
              <NewCustomers />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
