import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
} from 'react-admin';
import { CronScheduleField } from './components/CronScheduleField';

const filters = [
  <TextInput key="name" source="name" alwaysOn />,
  <TextInput key="url" source="url" />,
  <BooleanInput key="isActive" source="isActive" />,
];

export const CronList = () => (
  <List filters={filters}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="description" />
      <CronScheduleField source="schedule" />
      <TextField source="url" />
      <TextField source="method" />
      <BooleanField source="isActive" />
      <DateField source="lastRun" showTime />
      <DateField source="nextRun" showTime />
      <TextField source="status" />
      <TextField source="lastError" />
      <EditButton />
    </Datagrid>
  </List>
);
