import englishMessages from 'ra-language-english';
import { TranslationMessages } from 'react-admin';

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  ra: {
    ...(englishMessages.ra ?? {}),
    action: {
      ...(englishMessages.ra.action ?? {}),
      choose_language: 'Choose language',
      skip: 'Skip',
    },
    auth: {
      ...(englishMessages.ra.auth ?? {}),
      forgot_password: 'Forgot password?',
      reset_password: 'Reset password',
      email: 'Email',
      username_email_password_required:
        'Username/email and password are required',
      invalid_credentials: 'Invalid username/email or password',
      invalid_username_email: 'Invalid username/email',
      invalid_password: 'Invalid password',
      invalid_username: 'Invalid username',
      invalid_email: 'Invalid email',
      invalid_username_or_email: 'Invalid username/email',
      close_window: 'Close',
      set_new_password: 'Update Password',
      username_or_email: 'Username/email',
      username_or_email_placeholder: 'Enter username or email',
      or: 'or',
      sign_in_with_passkey: 'Sign in with Passkey',
      passkey_error: 'Failed to sign in with passkey',
      passkey_not_supported: 'Passkey is not supported on this device',
      passkey_register: 'Register Passkey',
      passkey_register_success: 'Passkey registered successfully',
      passkey_register_error: 'Failed to register passkey',
      passkey_not_registered:
        'No passkeys registered yet. Please log in with your username/password first, then register a passkey in your profile settings.',
      register_passkey: 'Register Passkey',
      register_passkey_description:
        'Would you like to set up a passkey for faster and more secure login? You can use your fingerprint, face recognition, or device PIN.',
      register_passkey_button: 'Register Passkey',
      register_passkey_error: 'Failed to register passkey',
      register_passkey_success: 'Passkey registered successfully',
      change_password: 'Change Password',
      password_changed_successfully: 'Password changed successfully',
      current_password_incorrect: 'Current password is incorrect',
      new_password_required: 'All fields are required',
      new_passwords_do_not_match: 'New passwords do not match',
      new_password_min_length:
        'New password must be at least 8 characters long',
      current_password: 'Current Password',
      new_password: 'New Password',
      confirm_new_password: 'Confirm New Password',
    },
    page: {
      ...(englishMessages.ra.page ?? {}),
      forbidden: 'Forbidden',
    },
    message: {
      ...(englishMessages.ra.message ?? {}),
      forbidden: 'You do not have permission to access this page',
    },
    configurable: {
      ...(englishMessages.ra.configurable ?? {}),
      customize: 'Customize',
    } as TranslationMessages['ra']['configurable'],
  },
  common: {
    actions: {
      cancel: 'Close',
    },
  },
  pos: {
    search: 'Search',
    notifications: {
      title: 'Notifications',
      empty: 'No notifications',
      markAsRead: 'Mark as read',
      markAllAsRead: 'Mark all as read',
    },
    configuration: {
      name: 'Configuration',
      receipt: 'Receipt Settings',
      thankYouMessage: 'Thank You Message',
      language: 'Language',
      theme: {
        name: 'Theme',
        light: 'Light',
        dark: 'Dark',
      },
      qrCode: {
        label: 'QR Code',
        description:
          'Upload a QR code image that will be displayed on the receipt',
        thankYouMessage:
          'Thank you for being with us. For any feedback or suggestions, please scan the QR code below. Have a wonderful day!',
      },
    },
    menu: {
      sales: 'Sales',
      catalog: 'Catalog',
      customers: 'Customers',
      library: 'Library',
      memberships: 'Memberships',
      staff_profiles: 'Staff Profiles',
      organization: 'Organization',
      system: 'System',
      zalo: 'Zalo Integration',
    },
    dashboard: {
      monthly_revenue: 'Monthly Revenue',
      month_history: '30 Day Revenue History',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      all_reviews: 'See all reviews',
      new_customers: 'New Customers',
      all_customers: 'See all customers',
      pending_orders: 'Pending Orders',
      order: {
        items:
          'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to Neko POS System',
        subtitle:
          "Empower your business with Neko POS System. Don't hesitate to explore and modify the data. Shall you have any questions, please contact us.",
        ra_button: "What's New?",
        demo_button: 'Contact Support',
      },
    },
    events: {
      review: {
        title: 'Posted review on "%{product}"',
      },
      order: {
        title: 'Ordered 1 poster |||| Ordered %{smart_count} posters',
      },
    },
    receipt: {
      thankYou: 'Thank you for your support!',
    },
  },
  resources: {
    customers: {
      name: 'Customer |||| Customers',
      fields: {
        orders: 'Orders',
        first_seen: 'First seen',
        groups: 'Segments',
        last_seen: 'Last seen',
        last_seen_gte: 'Visited Since',
        name: 'Name',
        total_spent: 'Total spent',
        password: 'Password',
        confirm_password: 'Confirm password',
        stateAbbr: 'State',
        customerPoints: 'Points: %{points}',
        otp: 'OTP Code',
      },
      filters: {
        last_visited: 'Last visited',
        today: 'Today',
        this_week: 'This week',
        last_week: 'Last week',
        this_month: 'This month',
        last_month: 'Last month',
        earlier: 'Earlier',
        has_ordered: 'Has ordered',
        has_newsletter: 'Has newsletter',
        group: 'Segment',
      },
      fieldGroups: {
        identity: 'Identity',
        address: 'Address',
        stats: 'Stats',
        history: 'History',
        password: 'Password',
        change_password: 'Change Password',
      },
      page: {
        delete: 'Delete Customer',
      },
      errors: {
        password_mismatch:
          'The password confirmation is not the same as the password.',
      },
    },
    orders: {
      name: 'Order |||| Orders',
      amount: '1 order |||| %{smart_count} orders',
      title: 'Order %{reference}',
      fields: {
        date: 'Date',
        reference: 'Reference',
        items: 'Items',
        totalExTaxes: 'Total (excl. tax)',
        deliveryFees: 'Delivery Fees',
        basket: {
          delivery: 'Delivery',
          reference: 'Reference',
          quantity: 'Quantity',
          sum: 'Sum',
          tax_rate: 'Tax Rate',
          taxes: 'Tax',
          total: 'Total',
          unit_price: 'Unit Price',
        },
        address: 'Address',
        customer: 'Customer',
        firstName: 'First Name',
        lastName: 'Last Name',
        customer_id: 'Customer',
        customerPoints: 'Points: %{points}',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        nb_items: 'Nb Items',
        total_gte: 'Min amount',
        status: 'Status',
        returned: 'Returned',
        totalBeforeDiscount: 'Total before discount',
        discount: 'Discount',
        totalAfterDiscount: 'Total after discount',
        promotion: 'Promotion',
        promotionType: 'Promotion Type',
        promotionValue: 'Promotion Value',
        total: 'Total',
        tableNumber: 'Table Number',
        paid: 'Paid',
        quantity: 'Quantity',
        product: 'Product',
        price: 'Price',
        note: 'Note',
        sum: 'Sum',
        searchProducts: 'Search products',
        taxes: 'Taxes',
        points: 'Points',
        pointsRedemption: 'Points to Redeem',
        orderType: 'Order Type',
      },
      section: {
        order: 'Order',
        customer: 'Customer',
        shipping_address: 'Shipping Address',
        items: 'Items',
        total: 'Totals',
      },
      messages: {
        unknownCustomer: 'Walk-in Customer',
        noProductsFound: 'No products found',
        total: 'Total',
        totalBeforeDiscount: 'Total before discount',
        completeProfile: 'Please complete your profile',
        qrScanFailed: 'QR scan failed',
        profileUpdateSuccess: 'Profile updated successfully',
        profileUpdateFailed: 'Profile update failed',
        checkinSuccess: 'Check-in successful',
        checkinFailed: 'Check-in failed',
        invalidOtp: 'Invalid OTP',
        otpExpired: 'OTP has expired',
        otpUsed: 'OTP has already been used',
        otpNotFound: 'OTP not found',
        checkinCooldown:
          'Please wait %{minutes} minutes before checking in again',
        customerNotFound: 'Customer not found',
        availablePoints: 'Available Points: %{points}',
        pointsValue: 'Points Value: %{value} VND',
        pointsDiscount: 'Using %{points} points (-%{value} VND)',
        remainingPoints: 'Remaining Points: %{points}',
        minPointsRedemption: 'Minimum points value must be 5,000 VND',
        exceedAvailablePoints: 'Points value cannot exceed available points',
        pointsMultipleOf1000: 'Points value must be a multiple of 1,000 VND',
        pointsRedemptionHelp:
          'Enter points to redeem (1 point = 100 VND, minimum 50 points)',
        insufficientOrderAmount:
          'Order amount must be at least 5,000 VND more than points value',
        enterOtp: 'Please enter OTP to verify customer',
        registerForMembership:
          'Customer has checked in before. Please ask if they would like to register for membership to earn points.',
        verifyOtp: 'Verify OTP',
        percentageExceeded: 'Percentage cannot exceed 100%',
        fixedAmountExceeded: 'Fixed amount cannot exceed total amount',
        customTableNumber: 'Custom Table Number',
        enterTableNumber: 'Enter table number (>16)',
        tableRequired: 'Table number is required for dine-in orders',
        noTableSelected: 'No table selected',
      },
      orderTypes: {
        dineIn: 'Dine In',
        takeaway: 'Takeaway',
        delivery: 'Delivery',
      },
      actions: {
        ready: 'Ready',
        cancel: 'Cancel',
        cancel_confirm_title: 'Cancel Order',
        cancel_confirm_message: 'Are you sure you want to cancel this order?',
        print_bill: 'Print Bill',
        deliver: 'Deliver',
        addProduct: 'Add Product',
        updateProfile: 'Update Profile',
        useMaxPoints: 'Use Maximum Points',
        resetPoints: 'Reset Points',
        verifyOtp: 'Verify OTP',
        selectTable: 'Select Table',
        changeTable: 'Change Table',
      },
      notification: {
        ready_success: 'Order marked as ready',
        ready_error: 'Error: Could not mark order as ready',
        cancel_success: 'Order cancelled',
        cancel_error: 'Error: Could not cancel order',
        deliver_success: 'Order delivered',
        deliver_error: 'Error: Could not deliver order',
      },
      tabs: {
        ordered: 'Ordered',
        ready: 'Ready',
        delivered: 'Delivered',
        cancelled: 'Cancelled',
      },
      promotion: {
        none: 'None',
        percentage: 'Percentage',
        fixed: 'Fixed Amount',
      },
      filters: {
        dateGte: 'From Date',
        dateLte: 'To Date',
        totalGte: 'Min Total',
      },
    },
    invoices: {
      name: 'Invoice |||| Invoices',
      fields: {
        date: 'Invoice date',
        customer_id: 'Customer',
        command_id: 'Order',
        date_gte: 'Passed Since',
        date_lte: 'Passed Before',
        total_gte: 'Min amount',
        address: 'Address',
      },
    },
    products: {
      name: 'Product |||| Products',
      fields: {
        name: 'Name',
        category_id: 'Category',
        height_gte: 'Min height',
        height_lte: 'Max height',
        height: 'Height',
        image: 'Image',
        price: 'Price',
        reference: 'Reference',
        sales: 'Sales',
        stock_lte: 'Low Stock',
        stock: 'Stock',
        thumbnail: 'Thumbnail',
        width_gte: 'Min width',
        width_lte: 'Max width',
        width: 'Width',
      },
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
        reviews: 'Reviews',
      },
      filters: {
        categories: 'Categories',
        stock: 'Stock',
        no_stock: 'Out of stock',
        low_stock: '1 - 9 items',
        average_stock: '10 - 49 items',
        enough_stock: '50 items & more',
        sales: 'Sales',
        best_sellers: 'Best sellers',
        average_sellers: 'Average',
        low_sellers: 'Low',
        never_sold: 'Never sold',
      },
    },
    categories: {
      name: 'Category |||| Categories',
      fields: {
        products: 'Products',
      },
    },
    reviews: {
      name: 'Review |||| Reviews',
      amount: '1 review |||| %{smart_count} reviews',
      relative_to_poster: 'Review on poster',
      detail: 'Review detail',
      fields: {
        customer_id: 'Customer',
        command_id: 'Order',
        product_id: 'Product',
        date_gte: 'Posted since',
        date_lte: 'Posted before',
        date: 'Date',
        comment: 'Comment',
        rating: 'Rating',
      },
      action: {
        accept: 'Accept',
        reject: 'Reject',
      },
      notification: {
        approved_success: 'Review approved',
        approved_error: 'Error: Review not approved',
        rejected_success: 'Review rejected',
        rejected_error: 'Error: Review not rejected',
      },
    },
    segments: {
      name: 'Segment |||| Segments',
      fields: {
        customers: 'Customers',
        name: 'Name',
      },
      data: {
        compulsive: 'Compulsive',
        collector: 'Collector',
        ordered_once: 'Ordered once',
        regular: 'Regular',
        returns: 'Returns',
        reviewer: 'Reviewer',
      },
    },
    users: {
      name: 'User |||| Users',
      fields: {
        commands: 'Orders',
        firstSeen: 'First seen',
        groups: 'Segments',
        lastSeen: 'Last seen',
        lastSeenGte: 'Visited Since',
        name: 'Name',
        totalSpent: 'Total spent',
        password: 'Password',
        confirmPassword: 'Confirm password',
        stateAbbr: 'State',
        clientId: 'Client App ID',
        role: 'Roles',
        email: 'Email',
      },
      filters: {
        lastVisited: 'Last visited',
        today: 'Today',
        thisWeek: 'This week',
        lastWeek: 'Last week',
        thisMonth: 'This month',
        lastMonth: 'Last month',
        earlier: 'Earlier',
        hasOrdered: 'Has ordered',
        hasNewsletter: 'Has newsletter',
        group: 'Segment',
      },
      fieldGroups: {
        identity: 'Identity',
        address: 'Address',
        stats: 'Stats',
        history: 'History',
        password: 'Password',
        changePassword: 'Change Password',
        app: 'App',
      },
      page: {
        delete: 'Delete Customer',
      },
      errors: {
        passwordMismatch:
          'The password confirmation is not the same as the password.',
      },
    },
    email_templates: {
      name: 'Email Template |||| Email Templates',
      fields: {
        name: 'Name',
        subject: 'Subject',
        description: 'Description',
        body: 'Body',
        enabled: 'Enabled',
      },
      detail: 'Email Template Detail',
      action: {
        accept: 'Accept',
        reject: 'Reject',
      },
      notification: {
        approved_success: 'Email Template approved',
        approved_error: 'Error: Email Template not approved',
        rejected_success: 'Email Template rejected',
        rejected_error: 'Error: Email Template not rejected',
      },
    },
    auth_clients: {
      name: 'Auth Client |||| Auth Clients',
      fields: {
        name: 'Name',
        description: 'Description',
        client_id: 'Client ID',
        client_secret: 'Client Secret',
        grants: 'Grants',
        redirect_uris: 'Redirect URIs',
      },
      detail: 'Auth Client Detail',
    },
    payment_gateways: {
      name: 'Payment Gateway |||| Payment Gateways',
      fields: {
        name: 'Name',
        description: 'Description',
        isDefault: 'Default Gateway',
        isSandbox: 'Sandbox Mode',
        staticQrCode: 'QR Code',
        config: 'Configuration',
      },
    },
    warehouses: {
      name: 'Warehouse |||| Warehouses',
      fields: {
        address: 'Address',
        additionalAddressInfo: 'Additional Address Info',
        contact: 'Contact',
        description: 'Description',
        location: 'Location',
        name: 'Name',
        picture: 'Picture',
        lat: 'Latitude',
        lng: 'Longitude',
        pictures: "Warehouse's Pictures",
      },
      fieldGroups: {
        identity: 'Identity',
      },
      validation: {
        invalidLatitude: 'Invalid Latitude',
        invalidLongitude: 'Invalid Longitude',
      },
    },
    organizations: {
      name: 'Organization |||| Organizations',
      fields: {
        address: 'Address',
        avatar: 'Avatar',
        intro: 'Introduction',
        name: 'Name',
        socialProfileIds: 'Social Profile IDs',
      },
      fieldGroups: {
        identity: 'Identity',
        address: 'Address',
        avatar: 'Avatar',
      },
    },
    staff_profiles: {
      name: 'Staff Profile |||| Staff Profiles',
      fields: {
        userId: 'User',
        name: 'Full Name',
        address: 'Address',
        postionId: 'Position',
        departmentId: 'Department',
        ratePerHour: 'Rate per Hour',
        ratePerHourCurrency: 'Rate Currency',
        sex: 'Gender',
      },
      fieldGroups: {
        identity: 'Identity',
        employment: 'Employment',
        compensation: 'Compensation',
      },
      tooltips: {
        isStaff: 'Staff Member',
      },
    },
    user_roles: {
      name: 'User Role |||| User Roles',
      fields: {
        name: 'Name',
        description: 'Description',
        permissions: 'Permissions',
      },
      detail: 'User Role Detail',
    },
    organization_departments: {
      name: 'Organization Department |||| Organization Departments',
      fields: {
        name: 'Name',
        description: 'Description',
        avatar: 'Avatar',
        organizationId: 'Organization',
        parentId: 'Parent',
      },
      fieldGroups: {
        identity: 'Identity',
        address: 'Address',
        avatar: 'Avatar',
      },
    },
    department_positions: {
      name: 'Department Position |||| Department Positions',
      fields: {
        name: 'Name',
        description: 'Description',
        avatar: 'Avatar',
        departmentId: 'Department',
      },
      fieldGroups: {
        identity: 'Identity',
        address: 'Address',
        avatar: 'Avatar',
      },
    },
    book_categories: {
      name: 'Book Category |||| Book Categories',
      fields: {
        books: 'Books',
      },
    },
    books: {
      name: 'Book |||| Books',
      filters: {
        best_rents: 'Best rents',
        average_rents: 'Average',
        low_rents: 'Low',
        never_rent: 'Never rented',
        no_stock: 'Out of stock',
        low_stock: '1 - 9 items',
        average_stock: '10 - 49 items',
        enough_stock: '50 items & more',
        categories: 'Categories',
        stock: 'Stock',
        rents: 'Rents',
      },
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
        reviews: 'Reviews',
      },
      fields: {
        image: 'Image',
      },
    },
    book_rents: {
      name: 'Book Rent |||| Book Rents',
      fields: {
        bookId: 'Book',
        trackingCode: 'Tracking Code',
        customerId: 'Customer',
        rentDate: 'Rent Date',
        dueDate: 'Due Date',
        status: 'Status',
        rentLocation: 'Rent Location',
        title: 'Book Title',
      },
      status: {
        RENTED: 'Rented',
        RETURNED: 'Returned',
        OVERDUE: 'Overdue',
      },
      location: {
        IN_STORE: 'In Store',
        OUTSIDE_STORE: 'Outside Store',
      },
      messages: {
        book_not_found: 'Book not found',
        book_not_available: 'Book is currently rented',
        book_not_rented: 'Book is not currently rented',
        update_success: 'Book rent updated successfully',
        create_success: 'Book rent created successfully',
        return_success: 'Book returned successfully',
        return_error: 'Error returning book',
        customer_required: 'Customer is required for outside store rentals',
        overdue_help:
          'This rent is overdue. Update the due date or mark as returned.',
      },
      actions: {
        return: 'Return Book',
      },
    },
    libraries: {
      name: 'Library |||| Libraries',
    },

    memberships: {
      name: 'Membership |||| Memberships',
      filters: {
        features: 'Membership Features',
      },
      tabs: {
        image: 'Image',
        details: 'Details',
        description: 'Description',
      },
    },
    membership_features: {
      name: 'Membership Feature |||| Membership Features',
      fields: {
        name: 'Name',
        description: 'Description',
        parent: 'Parent Feature',
        memberships: 'Memberships',
      },
    },
    attendance_records: {
      name: 'Attendance Record |||| Attendance Records',
      fields: {
        checkInTime: 'Check-in Time',
        checkOutTime: 'Check-out Time',
        staffId: 'Staff',
        status: 'Status',
        location: 'Location',
        note: 'Note',
      },
    },
    integrations: {
      name: 'Integration |||| Integrations',
      fields: {
        'data.customerId': 'Customer ID',
        'data.profile.firstName': 'First Name',
        'data.profile.lastName': 'Last Name',
        createdAt: 'Created At',
      },
      filters: {
        createdAtFrom: 'From Date',
        createdAtTo: 'To Date',
      },
      status: {
        checkedIn: 'Checked In',
        notCheckedIn: 'Not Checked In',
        pending: 'Pending',
        approved: 'Approved',
        rejected: 'Rejected',
      },
      actions: {
        checkIn: 'Check In',
        scanQR: 'Scan QR',
        approve: 'Approve',
        reject: 'Reject',
      },
      tabs: {
        today: 'Today',
        yesterday: 'Yesterday',
        allTime: 'All Time',
      },
    },
    zalo_checkin: {
      name: 'Zalo Check-in |||| Zalo Check-ins',
    },
    crons: {
      name: 'Cron Job |||| Cron Jobs',
      fields: {
        name: 'Name',
        description: 'Description',
        schedule: 'Schedule',
        url: 'URL',
        method: 'Method',
        headers: 'Headers',
        body: 'Body',
        params: 'Parameters',
        isActive: 'Active',
        lastRun: 'Last Run',
        nextRun: 'Next Run',
        status: 'Status',
        lastError: 'Last Error',
      },
      status: {
        success: 'Success',
        failed: 'Failed',
        pending: 'Pending',
      },
      messages: {
        invalidJson: 'Invalid JSON format',
      },
    },
    zalo: {
      name: 'Zalo Integration',
      checkin: 'Check-in',
      members: 'Members',
      messages: 'Messages',
      pending: 'Pending Requests',
      settings: 'Settings',
      tabs: {
        today: 'Today',
        yesterday: 'Yesterday',
        allTime: 'All Time',
      },
    },
    social_profiles: {
      name: 'Members',
      fields: {
        socialProfileId: 'Social Profile ID',
        entityId: 'Customer ID',
        points: 'Points',
      },
      actions: {
        grant_points: 'Grant Points',
      },
    },
  },
};

export default customEnglishMessages;
