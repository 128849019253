import OrderIcon from '@mui/icons-material/AttachMoney';

import OrderList from './OrderList';
import OrderEdit from './OrderEdit';
import OrderCreate from './OrderCreate';

export default {
  list: OrderList,
  create: OrderCreate,
  edit: OrderEdit,
  icon: OrderIcon,
};
