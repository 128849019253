import { List, Datagrid } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const ConfigurationList = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/configuration/1/edit');
    }, 0);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <List resource="configuration" hasCreate={false}>
      <Datagrid></Datagrid>
    </List>
  );
};
