import React from 'react';
import {
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
  useTranslate,
} from 'react-admin';
import { Card, CardContent, Typography } from '@mui/material';
import ImageInput from '../components/forms/ImageInput';

const ConfigurationEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ConfigurationTitle = () => <span>Configuration</span>;

export const ConfigurationEdit = () => {
  const translate = useTranslate();

  return (
    <Edit title={<ConfigurationTitle />} id="1">
      <SimpleForm toolbar={<ConfigurationEditToolbar />}>
        <Card sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {translate('pos.configuration.receipt')}
            </Typography>
            <Typography variant="body2" color="textSecondary" paragraph>
              {translate('pos.configuration.qrCode.description')}
            </Typography>
            <ImageInput
              source="qrCode"
              label={translate('pos.configuration.qrCode.label')}
              accept="image/*"
            />
          </CardContent>
        </Card>
      </SimpleForm>
    </Edit>
  );
};
