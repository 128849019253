import React from 'react';
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  ImageInput,
  ImageField,
  required,
} from 'react-admin';
import { PaymentGateway } from 'public-contracts';

interface PaymentGatewayFormProps {
  initialValues?: Partial<PaymentGateway>;
  onSubmit?: (values: Partial<PaymentGateway>) => void;
}

export const PaymentGatewayForm: React.FC<PaymentGatewayFormProps> = ({
  initialValues,
  onSubmit,
}) => {
  return (
    <SimpleForm defaultValues={initialValues} onSubmit={onSubmit}>
      <TextInput source="name" validate={required()} fullWidth />
      <TextInput source="description" multiline rows={3} fullWidth />
      <ImageInput source="staticQrCode" label="QR Code" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <BooleanInput source="isDefault" label="Default Gateway" />
      <BooleanInput source="isSandbox" label="Sandbox Mode" />
      <TextInput
        source="config"
        multiline
        rows={4}
        placeholder="Enter JSON configuration"
        format={(value) => JSON.stringify(value, null, 2)}
        parse={(value) => {
          try {
            return JSON.parse(value);
          } catch {
            return value;
          }
        }}
        fullWidth
      />
    </SimpleForm>
  );
};
