import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useNotify, useRefresh, useTranslate } from 'react-admin';
import { useDataProvider } from 'react-admin';

interface GrantPointsButtonProps {
  record: {
    socialProfileId: string;
    entityId: string;
  };
}

const GrantPointsButton = ({ record }: GrantPointsButtonProps) => {
  const [open, setOpen] = useState(false);
  const [points, setPoints] = useState('');
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPoints('');
  };

  const handleSubmit = async () => {
    try {
      await dataProvider.create('membership_points', {
        data: {
          socialProfileId: record.socialProfileId,
          entityId: record.entityId,
          points: parseInt(points, 10),
        },
      });
      notify('Points granted successfully', { type: 'success' });
      refresh();
      handleClose();
    } catch (error) {
      notify('Error granting points', { type: 'error' });
    }
  };

  return (
    <>
      <Button onClick={handleClick} size="small">
        {translate('resources.social_profiles.actions.grant_points')}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {translate('resources.social_profiles.actions.grant_points')}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={translate('resources.social_profiles.fields.points')}
            type="number"
            fullWidth
            value={points}
            onChange={(e) => setPoints(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{translate('ra.action.cancel')}</Button>
          <Button onClick={handleSubmit} color="primary">
            {translate('ra.action.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GrantPointsButton;
