export const calculateTotals = (data: any) => {
  const items = data.items || [];
  const totalExTaxes = items.reduce((sum: number, item: any) => {
    const quantity = Number(item.quantity) || 0;
    const price = Number(item.price) || 0;
    return sum + quantity * price;
  }, 0);

  const taxRate = 0;
  const taxes = totalExTaxes * taxRate;
  const deliveryFees = 0;
  const promotionType = data.promotionType || 'none';
  const promotionValue = Number(data.promotionValue) || 0;

  let discount = 0;
  if (promotionType === 'percentage') {
    discount = totalExTaxes * (promotionValue / 100);
  } else if (promotionType === 'fixed') {
    discount = promotionValue;
  }

  const total = totalExTaxes + taxes + deliveryFees - discount;

  return {
    totalExTaxes,
    taxes,
    deliveryFees,
    discount,
    total,
  };
};
