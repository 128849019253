import {
  Datagrid,
  DateField,
  List,
  TextField,
  EditButton,
  useTranslate,
  SimpleList,
  TextInput,
  Edit,
  SimpleForm,
  required,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

const filters = [<TextInput key="search" source="q" alwaysOn />];

export const SettingsEdit = () => {
  const translate = useTranslate();

  return (
    <Edit>
      <SimpleForm>
        <TextInput source="appId" validate={[required()]} fullWidth />
        <TextInput source="appSecret" validate={[required()]} fullWidth />
        <TextInput source="accessToken" validate={[required()]} fullWidth />
        <TextInput source="refreshToken" validate={[required()]} fullWidth />
        <TextInput source="oaId" validate={[required()]} fullWidth />
      </SimpleForm>
    </Edit>
  );
};

const SettingsList = () => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const translate = useTranslate();

  return (
    <List
      resource="integrations"
      filters={filters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filter={{ type: 'zalo_settings' }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.appId}
          secondaryText={(record) => record.oaId}
          tertiaryText={(record) =>
            new Date(record.createdAt).toLocaleDateString()
          }
        />
      ) : (
        <Datagrid>
          <TextField source="appId" />
          <TextField source="oaId" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
          <EditButton />
        </Datagrid>
      )}
    </List>
  );
};

export default SettingsList;
