import LabelIcon from '@mui/icons-material/Label';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import BadgeIcon from '@mui/icons-material/Badge';
import BusinessIcon from '@mui/icons-material/Business';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import WorkIcon from '@mui/icons-material/Work';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MessageIcon from '@mui/icons-material/Message';
import SettingsIcon from '@mui/icons-material/Settings';
import ScheduleIcon from '@mui/icons-material/Schedule';
import visitors from './visitors';
import users from './users';
import useRoles from './userRoles';
import orders from './orders';
import invoices from './invoices';
import products from './products';
import categories from './categories';
import reviews from './reviews';
import emailTemplates from './emailTemplates';
import authClients from './authClients';
import payment from './payment';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import warehouses from './warehouses';
import staffProfiles from './staffProfiles';
import organizations from './organizations';
import organizationDepartments from './organizationDepartments';
import departmentPositions from './departmentPositions';
import attendanceRecords from './attendanceRecords';
import books from './books';
import bookCategories from './bookCategories';
import memberships from './memberships';
import membershipFeatures from './membershipFeatures';
import bookRents from './bookRents';
import { ConfigurationEdit } from './configuration/ConfigurationEdit';
import { ConfigurationList } from './configuration/ConfigurationList';
import { zaloResources } from './zalo/ZaloResource';
import { CronList, CronEdit, CronCreate } from './crons';
import ExtensionIcon from '@mui/icons-material/Extension';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PeopleIcon from '@mui/icons-material/People';
import PendingIcon from '@mui/icons-material/Pending';

export interface ResourceItem {
  name: string;
  resource: string;
  icon: React.ReactElement;
  children?: ResourceItem[];
  dense?: boolean;
  hideMenu?: boolean;
  configs?: {
    list?: React.ComponentType<any>;
    create?: React.ComponentType<any>;
    edit?: React.ComponentType<any>;
    icon?: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
      muiName: string;
    };
    recordRepresentation?: (record: any) => string;
    permissions?: {
      list?: string[];
      create?: string[];
      edit?: string[];
      delete?: string[];
    };
  };
}

export const resources: ResourceItem[] = [
  {
    name: 'pos.menu.sales',
    resource: 'menuSales',
    icon: <orders.icon />,
    configs: orders,
    children: [
      {
        name: 'resources.orders.name',
        resource: 'orders',
        icon: <orders.icon />,
        configs: orders,
      },
      {
        name: 'resources.invoices.name',
        resource: 'invoices',
        icon: <invoices.icon />,
        configs: invoices,
      },
    ],
  },
  {
    name: 'pos.menu.catalog',
    resource: 'menuCatalog',
    icon: <products.icon />,
    configs: products,
    children: [
      {
        name: 'resources.products.name',
        resource: 'products',
        icon: <products.icon />,
        configs: products,
      },
      {
        name: 'resources.categories.name',
        resource: 'product_categories',
        icon: <categories.icon />,
        configs: categories,
      },
    ],
  },
  {
    name: 'pos.menu.library',
    resource: 'menuLibrary',
    icon: <LocalLibraryIcon />,
    configs: products,
    children: [
      {
        name: 'resources.books.name',
        resource: 'books',
        icon: <books.icon />,
        configs: books,
      },
      {
        name: 'resources.book_categories.name',
        resource: 'book_categories',
        icon: <bookCategories.icon />,
        configs: bookCategories,
      },
      {
        name: 'resources.book_rents.name',
        resource: 'book_rents',
        icon: <bookRents.icon />,
        configs: bookRents,
      },
    ],
  },
  {
    name: 'pos.menu.customers',
    resource: 'menuCustomers',
    icon: <visitors.icon />,
    configs: visitors,
    children: [
      {
        name: 'resources.customers.name',
        resource: 'customers',
        icon: <visitors.icon />,
        configs: visitors,
      },
      {
        name: 'resources.segments.name',
        resource: 'segments',
        icon: <LabelIcon />,
        configs: visitors,
      },
      {
        name: 'resources.reviews.name',
        resource: 'reviews',
        icon: <reviews.icon />,
        configs: reviews,
      },
    ],
  },
  {
    name: 'pos.menu.zalo',
    resource: 'menuZalo',
    icon: <MessageIcon />,
    configs: {
      list: zaloResources.checkin.list,
      icon: MessageIcon,
    },
    children: [
      {
        name: 'resources.zalo.pending',
        resource: 'zalo/pending',
        icon: <PendingIcon />,
        configs: {
          list: zaloResources.pending.list,
          icon: PendingIcon,
        },
      },
      {
        name: 'resources.zalo.messages',
        resource: 'zalo/messages',
        icon: <MessageIcon />,
        configs: {
          list: zaloResources.messages.list,
          icon: MessageIcon,
        },
      },
      {
        name: 'resources.zalo.checkin',
        resource: 'zalo/checkin',
        icon: <CheckCircleIcon />,
        configs: {
          list: zaloResources.checkin.list,
          icon: CheckCircleIcon,
        },
      },
      {
        name: 'resources.zalo.members',
        resource: 'social_profiles',
        icon: <PeopleIcon />,
        configs: {
          list: zaloResources.members.list,
          icon: PeopleIcon,
        },
      },
      {
        name: 'resources.zalo.settings',
        resource: 'zalo/settings',
        icon: <SettingsIcon />,
        configs: {
          list: zaloResources.settings.list,
          icon: SettingsIcon,
        },
      },
    ],
  },
  {
    name: 'pos.menu.memberships',
    resource: 'menuMemberships',
    icon: <CardMembershipIcon />,
    configs: memberships,
    children: [
      {
        name: 'resources.memberships.name',
        resource: 'memberships',
        icon: <CardMembershipIcon />,
        configs: memberships,
      },
      {
        name: 'resources.membership_features.name',
        resource: 'membership_features',
        icon: <membershipFeatures.icon />,
        configs: membershipFeatures,
      },
    ],
  },
  {
    name: 'pos.menu.organization',
    resource: 'menuOrganization',
    icon: <BusinessIcon />,
    configs: organizations,
    children: [
      {
        name: 'resources.organizations.name',
        resource: 'organizations',
        icon: <BusinessIcon />,
        configs: organizations,
      },
      {
        name: 'resources.organization_departments.name',
        resource: 'organization_departments',
        icon: <AccountTreeIcon />,
        configs: organizationDepartments,
      },
      {
        name: 'resources.department_positions.name',
        resource: 'department_positions',
        icon: <WorkIcon />,
        configs: departmentPositions,
      },
      {
        name: 'resources.staff_profiles.name',
        resource: 'staff_profiles',
        icon: <BadgeIcon />,
        configs: staffProfiles,
      },
      {
        name: 'resources.attendance_records.name',
        resource: 'attendance_records',
        icon: <attendanceRecords.icon />,
        configs: {
          ...attendanceRecords,
          permissions: {
            list: ['admin', 'staff', 'storeManager'],
            edit: ['admin', 'staff', 'storeManager'],
            create: ['admin', 'staff', 'storeManager'],
            delete: ['admin', 'storeManager'],
          },
        },
      },
    ],
  },
  {
    name: 'resources.warehouses.name',
    resource: 'warehouses',
    icon: <WarehouseIcon />,
    configs: warehouses,
  },
  {
    name: 'pos.menu.system',
    resource: 'menuSystem',
    icon: <SettingsIcon />,
    configs: users,
    children: [
      {
        name: 'resources.users.name',
        resource: 'users',
        icon: <users.icon />,
        configs: users,
      },
      {
        name: 'resources.user_roles.name',
        resource: 'user_roles',
        icon: <AdminPanelSettingsIcon />,
        configs: useRoles,
      },
      {
        name: 'resources.payment_gateways.name',
        resource: 'payment_gateways',
        icon: <payment.icon />,
        configs: payment,
      },
      {
        name: 'resources.email_templates.name',
        resource: 'email_templates',
        icon: <emailTemplates.icon />,
        configs: emailTemplates,
      },
      {
        name: 'resources.auth_clients.name',
        resource: 'auth_clients',
        icon: <authClients.icon />,
        configs: authClients,
      },
      {
        name: 'resources.crons.name',
        resource: 'crons',
        icon: <ScheduleIcon />,
        configs: {
          list: CronList,
          edit: CronEdit,
          create: CronCreate,
          icon: ScheduleIcon,
        },
      },
      {
        name: 'pos.configuration.name',
        resource: 'configuration',
        icon: <SettingsIcon />,
        configs: {
          list: ConfigurationList,
          edit: ConfigurationEdit,
        },
      },
    ],
  },
];

export function getResourceConfigs(
  resources: ResourceItem[]
): Record<string, ResourceItem['configs']> {
  return resources.reduce((acc, resource) => {
    if (resource.configs) {
      acc[resource.resource] = resource.configs;
    }
    if (resource.children) {
      acc = {
        ...acc,
        ...getResourceConfigs(resource.children),
      };
    }
    return acc;
  }, {} as Record<string, ResourceItem['configs']>);
}
