import {
  Datagrid,
  DateField,
  List,
  TextField,
  SearchInput,
  useTranslate,
  SimpleList,
  DateInput,
  RichTextField,
  ChipField,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

const filters = [
  <SearchInput key="search" source="q" alwaysOn />,
  <DateInput
    key="createdAtFrom"
    source="createdAt.$gte"
    label="resources.integrations.filters.createdAtFrom"
  />,
  <DateInput
    key="createdAtTo"
    source="createdAt.$lte"
    label="resources.integrations.filters.createdAtTo"
  />,
];

const MessageList = () => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const translate = useTranslate();

  return (
    <List
      resource="integrations"
      filters={filters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filter={{ type: 'zalo_message' }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) =>
            record.sender?.name || record.sender?.phoneNumber
          }
          secondaryText={(record) => record.content}
          tertiaryText={(record) =>
            new Date(record.createdAt).toLocaleDateString()
          }
        />
      ) : (
        <Datagrid bulkActionButtons={false}>
          <TextField source="sender.name" label="Sender Name" />
          <TextField source="sender.phoneNumber" label="Phone Number" />
          <RichTextField source="content" />
          <ChipField source="status" />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Datagrid>
      )}
    </List>
  );
};

export default MessageList;
