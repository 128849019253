import * as React from 'react';
import { SxProps, Typography, Box, Tooltip } from '@mui/material';
import { memo } from 'react';
import { FieldProps, useRecordContext, useGetOne } from 'react-admin';
import AvatarField from './AvatarField';
import { CustomerBriefInfo } from 'public-contracts';
import { Customer } from '../types';
import ZaloIcon from '@mui/icons-material/Chat';
import StarIcon from '@mui/icons-material/Star';

interface Props extends FieldProps<Customer> {
  size?: string;
  sx?: SxProps;
  showPoints?: boolean;
  showZalo?: boolean;
}

const CustomerBriefField = (props: Props) => {
  const { size, showPoints = true, showZalo = true } = props;
  const record = useRecordContext<Customer>();

  const { data: briefInfo } = useGetOne<CustomerBriefInfo>(
    'customers',
    { id: (record?.id as string) || '' },
    {
      enabled: !!record?.id,
      meta: { brief: true },
    }
  );

  if (!record || !briefInfo) return null;

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography
        variant="body2"
        display="flex"
        flexWrap="nowrap"
        alignItems="center"
        component="div"
        sx={props.sx}
      >
        <AvatarField
          record={record}
          size={size}
          sx={{
            mr: 1,
            mt: -0.5,
            mb: -0.5,
          }}
        />
        {briefInfo.firstName} {briefInfo.lastName}
      </Typography>

      {showPoints && (
        <Tooltip title="Points">
          <Typography
            variant="body2"
            color="textSecondary"
            display="flex"
            alignItems="center"
            gap={0.5}
          >
            <StarIcon fontSize="small" />
            {briefInfo.points}
          </Typography>
        </Tooltip>
      )}

      {showZalo && briefInfo.zaloId && (
        <Tooltip title="Zalo ID">
          <Typography
            variant="body2"
            color="textSecondary"
            display="flex"
            alignItems="center"
            gap={0.5}
          >
            <ZaloIcon fontSize="small" />
            {briefInfo.zaloId}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

CustomerBriefField.defaultProps = {
  source: 'lastName' as const,
  label: 'resources.customers.fields.name',
};

export default memo<Props>(CustomerBriefField) as React.FC<Props>;
