import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner';

interface QRScannerDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (customerId: string, code: string) => void;
  onError: (error?: string) => void;
}

const QRScannerDialog = ({
  open,
  onClose,
  onSuccess,
  onError,
}: QRScannerDialogProps) => {
  const handleScan = (detectedCodes: IDetectedBarcode[]) => {
    try {
      const url = new URL(detectedCodes[0].rawValue);
      const code = url.searchParams.get('code');
      const [customerId, otp] = code?.split(':') ?? [];
      if (customerId && otp) {
        onSuccess(customerId, otp);
        onClose();
      } else {
        onError('Invalid QR code');
      }
    } catch (error) {
      onError('Invalid QR code URL');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Scan QR Code</DialogTitle>
      <DialogContent>
        {open && (
          <Scanner
            onScan={handleScan}
            onError={(error) =>
              onError(error instanceof Error ? error.message : 'Scanner error')
            }
            scanDelay={500}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default QRScannerDialog;
