import * as React from 'react';
import { useRecordContext } from 'react-admin';

const NbItemsField = ({ label }: { label?: string }) => {
  const record = useRecordContext();
  if (!record || !record.items) return null;
  return <span>{record.items?.length ?? 0}</span>;
};

NbItemsField.defaultProps = {
  label: 'resources.orders.fields.items',
};

export default NbItemsField;
