import { MenuList } from '@mui/material';
import { Logout, UserMenu, useTranslate } from 'react-admin';
import { ThemeSwapper } from '../themes/ThemeSwapper';
import { Password as PasswordIcon } from '@mui/icons-material';
import UserMenuItem from './UserMenuItem';

interface AdditionalUserMenuProps {
  onChangePassword?: () => void;
}

const AdditionalUserMenu = ({ onChangePassword }: AdditionalUserMenuProps) => {
  const translate = useTranslate();

  return (
    <UserMenu>
      <MenuList>
        {onChangePassword && (
          <UserMenuItem
            onClick={onChangePassword}
            icon={<PasswordIcon fontSize="small" />}
          >
            {translate('ra.auth.change_password')}
          </UserMenuItem>
        )}
        <ThemeSwapper />
        <Logout />
      </MenuList>
    </UserMenu>
  );
};

export default AdditionalUserMenu;
