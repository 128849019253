import { useDataProvider, useNotify } from 'react-admin';
import { nekoPos } from 'public-contracts';
import { useCallback } from 'react';

export const useTableStatus = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const updateTableStatus = useCallback(
    async (tableNumber: number, orderId?: string, isCheckout?: boolean) => {
      try {
        const { data: existingStatuses } =
          await dataProvider.getList<nekoPos.TableStatus>('table_statuses', {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'tableNumber', order: 'ASC' },
            filter: { tableNumber },
          });

        const status = existingStatuses[0];
        // Only mark as available if it's a checkout (delivered/cancelled) or if no orderId is provided
        const isAvailable = isCheckout || !orderId;

        if (status) {
          // Update existing status
          await dataProvider.update<nekoPos.TableStatus>('table_statuses', {
            id: status.id,
            data: {
              ...status,
              orderId,
              isAvailable,
              lastOrderDate: orderId ? new Date() : status.lastOrderDate,
            },
            previousData: status,
          });
        } else {
          // Create new status
          await dataProvider.create<nekoPos.TableStatus>('table_statuses', {
            data: {
              tableNumber,
              orderId,
              isAvailable,
              lastOrderDate: orderId ? new Date() : undefined,
            },
          });
        }
      } catch (error) {
        console.error('Error updating table status:', error);
        notify('Error updating table status', { type: 'error' });
      }
    },
    [dataProvider, notify]
  );

  const releaseTable = useCallback(
    async (tableNumber: number) => {
      await updateTableStatus(tableNumber, undefined, true);
    },
    [updateTableStatus]
  );

  const occupyTable = useCallback(
    async (tableNumber: number, orderId: string) => {
      await updateTableStatus(tableNumber, orderId);
    },
    [updateTableStatus]
  );

  return {
    updateTableStatus,
    releaseTable,
    occupyTable,
  };
};
