import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';
import AttendanceRecordList from './AttendanceRecordList';
import Timesheet from './Timesheet';
import Scheduler from './Scheduler';

interface Props {
  filter?: any;
  disableCreate?: boolean;
  disableBulkActions?: boolean;
}

const AttendanceRecordListViews = (props: Props) => {
  const [activeTab, setActiveTab] = useState(0);
  const { filter = {} } = props;

  // Ensure userId is passed to all child components
  const filterWithUserId = {
    ...filter,
    userId: filter.userId,
  };
  const userFilters = {
    ...filter,
    id: filter.userId,
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Time Sheet" />
        <Tab label="Attendance Record List" />
        <Tab label="Calendar" />
      </Tabs>
      {activeTab === 0 && <Timesheet {...props} filter={filterWithUserId} />}
      {activeTab === 1 && (
        <AttendanceRecordList
          {...props}
          filter={filterWithUserId}
          userFilters={userFilters}
        />
      )}
      {activeTab === 2 && <Scheduler {...props} filter={filterWithUserId} />}
    </div>
  );
};

export default AttendanceRecordListViews;
