import { Box, Tooltip } from '@mui/material';
import BadgeIcon from '@mui/icons-material/Badge';
import PersonIcon from '@mui/icons-material/Person';
import { useGetList, useRecordContext } from 'react-admin';
import { User } from 'public-contracts';

interface Props {
  source?: string;
  label?: string;
}

const UserNameWithStaffBadge = (props: Props) => {
  const record = useRecordContext<User>();
  const { data: staffProfiles } = useGetList(
    'staff_profiles',
    {
      pagination: { page: 1, perPage: 1 },
      filter: { userId: record?.id },
    },
    { enabled: !!record?.id }
  );

  const { data: customers } = useGetList(
    'customers',
    {
      pagination: { page: 1, perPage: 1 },
      filter: { userId: record?.id },
    },
    { enabled: !!record?.id }
  );

  if (!record) return null;

  const isStaff = staffProfiles && staffProfiles.length > 0;
  const isCustomer = customers && customers.length > 0;

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <span>{`${record.firstName} ${record.lastName}`}</span>
      {isStaff && (
        <Tooltip title="Staff Member">
          <BadgeIcon color="primary" sx={{ fontSize: '1rem' }} />
        </Tooltip>
      )}
      {isCustomer && (
        <Tooltip title="Customer">
          <PersonIcon color="secondary" sx={{ fontSize: '1rem' }} />
        </Tooltip>
      )}
    </Box>
  );
};

export default UserNameWithStaffBadge;
