import { Box, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';

export const OrderTotalField = () => {
  const { watch } = useFormContext();
  const translate = useTranslate();
  const items = watch('items') || [];
  const promotionType = watch('promotionType') || 'none';
  const promotionValue = watch('promotionValue') || 0;

  const totalExTaxes = items
    .filter((item: any) => !!item?.productId)
    .reduce((sum: number, item: any) => {
      const quantity = item.quantity || 0;
      const price = item.price || 0;
      let itemTotal = quantity * price;

      if (item.promotionType === 'percentage' && item.promotionValue) {
        itemTotal = itemTotal * (1 - item.promotionValue / 100);
      } else if (item.promotionType === 'fixed' && item.promotionValue) {
        itemTotal = itemTotal - item.promotionValue;
      }

      return sum + itemTotal;
    }, 0);

  let total = totalExTaxes;
  if (promotionType === 'percentage') {
    total = total * (1 - promotionValue / 100);
  } else if (promotionType === 'fixed') {
    total = total - promotionValue;
  }

  return (
    <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
      <Typography variant="h5" color="primary">
        {translate('resources.orders.messages.total')}:{' '}
        {new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(total)}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {translate('resources.orders.messages.totalBeforeDiscount')}:{' '}
        {new Intl.NumberFormat('vi-VN', {
          style: 'currency',
          currency: 'VND',
        }).format(totalExTaxes)}
      </Typography>
    </Box>
  );
};
