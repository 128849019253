import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { useTranslate, useGetList } from 'react-admin';
import { nekoPos } from 'public-contracts';
import { useFormContext } from 'react-hook-form';

const FIXED_TABLES = Array.from({ length: 16 }, (_, i) => i + 1);

interface TableNumberGridProps {
  value?: number;
  onChange?: (value: number | undefined) => void;
}

export const TableNumberGrid = ({ value, onChange }: TableNumberGridProps) => {
  const theme = useTheme();
  const translate = useTranslate();

  // Get table status from the API
  const { data: tableStatuses = [] } = useGetList<nekoPos.TableStatus>(
    'table_statuses',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'tableNumber', order: 'ASC' },
    }
  );

  // Create a map of table numbers to their status
  const tableStatusMap = tableStatuses.reduce((acc, status) => {
    acc[status.tableNumber] = status;
    return acc;
  }, {} as Record<number, nekoPos.TableStatus>);

  const handleTableSelect = (tableNumber: number) => {
    const status = tableStatusMap[tableNumber];
    if (status && !status.isAvailable) return;

    onChange?.(tableNumber);
  };

  const handleCustomTableChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const tableNumber = parseInt(event.target.value);
    if (!isNaN(tableNumber) && tableNumber > 16) {
      onChange?.(tableNumber);
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="subtitle1" gutterBottom>
        {translate('resources.orders.fields.tableNumber')}
      </Typography>
      <Grid container spacing={1}>
        {FIXED_TABLES.map((number) => {
          const status = tableStatusMap[number];
          const isSelected = value === number;
          const isUnavailable = status && !status.isAvailable;

          return (
            <Grid item xs={3} sm={2} md={1.5} key={number}>
              <Paper
                sx={{
                  p: 1,
                  textAlign: 'center',
                  cursor: isUnavailable ? 'not-allowed' : 'pointer',
                  bgcolor: isSelected
                    ? theme.palette.primary.main
                    : isUnavailable
                    ? theme.palette.action.disabledBackground
                    : theme.palette.background.paper,
                  color: isSelected
                    ? theme.palette.primary.contrastText
                    : isUnavailable
                    ? theme.palette.text.disabled
                    : theme.palette.text.primary,
                  '&:hover': {
                    bgcolor: isUnavailable
                      ? theme.palette.action.disabledBackground
                      : isSelected
                      ? theme.palette.primary.dark
                      : theme.palette.action.hover,
                  },
                }}
                onClick={() => handleTableSelect(number)}
              >
                {number}
              </Paper>
            </Grid>
          );
        })}
        <Grid item xs={12}>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              {translate('resources.orders.messages.customTableNumber')}
            </Typography>
            <input
              type="number"
              min="17"
              value={value && value > 16 ? value : ''}
              onChange={handleCustomTableChange}
              placeholder={translate(
                'resources.orders.messages.enterTableNumber'
              )}
              style={{
                width: '100%',
                padding: '8px',
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: '4px',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
