import PaymentIcon from '@mui/icons-material/Payment';
import { PaymentGatewayList } from './PaymentGatewayList';
import { PaymentGatewayForm } from './PaymentGatewayForm';

const PaymentGateway = {
  list: PaymentGatewayList,
  create: PaymentGatewayForm,
  edit: PaymentGatewayForm,
  icon: PaymentIcon,
};

export default PaymentGateway;
