import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import {
  SimpleForm,
  TextInput,
  DateInput,
  SelectInput,
  AutocompleteInput,
  ReferenceField,
  useTranslate,
  useGetList,
  useUpdate,
  useNotify,
  Loading,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import CustomerReferenceField from '../visitors/CustomerReferenceField';

const BookInfo = ({ trackingCode }: { trackingCode: string }) => {
  const { setValue } = useFormContext();
  const translate = useTranslate();

  const { data: books, isLoading: isLoadingBook } = useGetList(
    'books',
    {
      filter: { trackingCode },
      pagination: { page: 1, perPage: 1 },
    },
    { enabled: !!trackingCode }
  );

  // Get the book ID if available
  const bookId = React.useMemo(() => {
    return books && books.length > 0 ? books[0].id : null;
  }, [books]);

  // Query rent records for the book
  const { data: rents, isLoading: isLoadingRents } = useGetList(
    'book_rents',
    {
      filter: { bookId, status: 'RENTED' },
      pagination: { page: 1, perPage: 1 },
    },
    { enabled: !!bookId }
  );

  const isChecking = React.useMemo(() => {
    return isLoadingBook || (bookId && isLoadingRents);
  }, [isLoadingBook, bookId, isLoadingRents]);

  // Update form when book and rent are found
  React.useEffect(() => {
    if (!isChecking && bookId && rents?.length) {
      const rent = rents[0];
      setValue('id', rent.id);
      setValue('bookId', bookId);
      setValue('customerId', rent.customerId);
      setValue('rentDate', rent.rentDate);
      setValue('dueDate', rent.dueDate);
      setValue('rentLocation', rent.rentLocation);
      setValue('title', books?.[0]?.title);
    }
  }, [setValue, bookId, rents, isChecking, books]);

  if (isChecking) return <Loading />;
  if (!isChecking && trackingCode && !bookId) {
    return (
      <span>{translate('resources.book_rents.messages.book_not_found')}</span>
    );
  }
  if (!isChecking && bookId && !rents?.length) {
    return (
      <span>{translate('resources.book_rents.messages.book_not_rented')}</span>
    );
  }
  return null;
};

export const RentReturnDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const translate = useTranslate();
  const notify = useNotify();
  const [update] = useUpdate();
  const [trackingCode, setTrackingCode] = React.useState('');

  const handleSubmit = (values: any) => {
    if (values.id) {
      update(
        'book_rents',
        {
          id: values.id,
          data: { ...values, status: 'RETURNED' },
        },
        {
          onSuccess: () => {
            notify(translate('resources.book_rents.messages.return_success'));
            onClose();
          },
          onError: (error) => {
            notify(translate('resources.book_rents.messages.return_error'), {
              type: 'error',
            });
          },
        }
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        {translate('resources.book_rents.actions.return')}
      </DialogTitle>
      <DialogContent>
        <SimpleForm onSubmit={handleSubmit}>
          <TextInput
            source="trackingCode"
            label="resources.book_rents.fields.trackingCode"
            onChange={(e) => setTrackingCode(e.target.value)}
            autoFocus
            fullWidth
          />
          {trackingCode && <BookInfo trackingCode={trackingCode} />}
          <TextInput
            source="title"
            label="resources.book_rents.fields.title"
            disabled
            fullWidth
          />
          <ReferenceField
            source="customerId"
            reference="customers"
            label="resources.book_rents.fields.customerId"
            queryOptions={{ meta: { brief: true } }}
          >
            <CustomerReferenceField />
          </ReferenceField>
          <SelectInput
            source="rentLocation"
            label="resources.book_rents.fields.rentLocation"
            disabled
            choices={[
              {
                id: 'IN_STORE',
                name: translate('resources.book_rents.location.IN_STORE'),
              },
              {
                id: 'OUTSIDE_STORE',
                name: translate('resources.book_rents.location.OUTSIDE_STORE'),
              },
            ]}
          />
          <DateInput
            source="rentDate"
            label="resources.book_rents.fields.rentDate"
            disabled
          />
          <DateInput
            source="dueDate"
            label="resources.book_rents.fields.dueDate"
            disabled
          />
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{translate('ra.action.cancel')}</Button>
        <Button
          onClick={() => {
            const form = document.querySelector('form');
            if (form) {
              form.dispatchEvent(
                new Event('submit', { cancelable: true, bubbles: true })
              );
            }
          }}
          color="primary"
        >
          {translate('resources.book_rents.actions.return')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
