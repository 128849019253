import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import LockIcon from '@mui/icons-material/Lock';
import TranslateIcon from '@mui/icons-material/Translate';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
} from '@mui/material';
import {
  Form,
  TextInput,
  required,
  useLogin,
  useNotify,
  useTranslate,
  useSetLocale,
  useLocaleState,
} from 'react-admin';

import Box from '@mui/material/Box';
import ForgotPassword from './ForgotPassword';

const LANGUAGES = [
  { code: 'en', name: 'English' },
  { code: 'fr', name: 'Français' },
  { code: 'vi', name: 'Tiếng Việt' },
];

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();
  const setLocale = useSetLocale();
  const [locale] = useLocaleState();
  const location = useLocation();

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);

    // Get values directly from form elements as a backup for autofill
    const form = document.querySelector('form');
    const usernameInput = form?.querySelector(
      'input[name="username"]'
    ) as HTMLInputElement;
    const passwordInput = form?.querySelector(
      'input[name="password"]'
    ) as HTMLInputElement;

    const usernameOrEmail =
      auth.username?.trim() || usernameInput?.value?.trim();
    const password = auth.password || passwordInput?.value;

    // Validate credentials
    if (!usernameOrEmail || !password) {
      setLoading(false);
      notify(translate('ra.auth.username_email_password_required'), {
        type: 'error',
      });
      return;
    }

    login(
      {
        // If input looks like an email, send it as email
        ...(usernameOrEmail.includes('@')
          ? { email: usernameOrEmail }
          : { username: usernameOrEmail }),
        password,
      },
      (location.state as any)?.nextPathname
    ).catch((error: Error) => {
      setLoading(false);
      notify(
        typeof error === 'string'
          ? error
          : typeof error === 'undefined' || !error.message
          ? translate('ra.auth.invalid_credentials')
          : error.message,
        {
          type: 'error',
          messageArgs: {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                ? error.message
                : undefined,
          },
        }
      );
    });
  };

  const handlePasskeyLogin = async () => {
    try {
      setLoading(true);
      // Focus the passkey input to trigger the browser's passkey UI
      const passkeyInput = document.getElementById('passkey');
      if (passkeyInput) {
        passkeyInput.focus();
      }
      await login({ passkey: true }, (location.state as any)?.nextPathname);
    } catch (error: any) {
      console.error('Passkey login error:', error);
      if (error.name === 'NoPasskeysError') {
        notify(translate('ra.auth.no_passkeys'), { type: 'warning' });
      } else {
        notify(translate('ra.auth.passkey_error'), { type: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPasswordClick = () => {
    setIsResettingPassword(true);
  };

  if (isResettingPassword) {
    return <ForgotPassword />;
  }

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'flex-start',
          background: 'url(https://source.unsplash.com/featured/1600x900)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: '6em' }}>
          <input
            type="text"
            name="passkey"
            id="passkey"
            autoComplete="webauthn"
            aria-label="Sign in with passkey"
            style={{
              position: 'absolute',
              opacity: 0,
              pointerEvents: 'none',
              height: 0,
            }}
            onFocus={(e) => e.target.click()}
          />
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: 'secondary.main' }}>
              <LockIcon />
            </Avatar>
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              display: 'flex',
              justifyContent: 'center',
              color: (theme) => theme.palette.grey[500],
            }}
          ></Box>
          <Box sx={{ padding: '0 1em 1em 1em' }}>
            <Box
              sx={{
                marginTop: '1em',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                marginBottom: 2,
              }}
            >
              <TranslateIcon color="action" />
              <FormControl fullWidth size="small">
                <InputLabel id="language-select-label">
                  {translate('ra.action.choose_language')}
                </InputLabel>
                <Select
                  labelId="language-select-label"
                  value={locale}
                  onChange={(e) => setLocale(e.target.value)}
                  label={translate('ra.action.choose_language')}
                >
                  {LANGUAGES.map((locale) => (
                    <MenuItem key={locale.code} value={locale.code}>
                      {locale.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ marginTop: '1em' }}>
              <TextInput
                autoFocus
                source="username"
                name="username"
                autoComplete="username"
                label={translate('ra.auth.username_or_email')}
                placeholder={translate('ra.auth.username_or_email_placeholder')}
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
            <Box sx={{ marginTop: '1em' }}>
              <TextInput
                source="password"
                name="password"
                autoComplete="current-password"
                label={translate('ra.auth.password')}
                type="password"
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
          </Box>
          <CardActions
            sx={{ padding: '0 1em 1em 1em', flexDirection: 'column', gap: 1 }}
          >
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </Button>
            <Divider sx={{ width: '100%', my: 1 }}>
              {translate('ra.auth.or')}
            </Divider>
            <Button
              variant="outlined"
              color="primary"
              onClick={handlePasskeyLogin}
              disabled={loading || !window.PublicKeyCredential}
              startIcon={<FingerprintIcon />}
              fullWidth
            >
              {translate('ra.auth.sign_in_with_passkey')}
            </Button>
            <Button type="button" onClick={handleForgotPasswordClick}>
              {translate('ra.auth.forgot_password')}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Form>
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export default Login;

interface FormValues {
  username?: string;
  password?: string;
  passkey?: boolean;
}
