import {
  Datagrid,
  DateField,
  List,
  TextField,
  SearchInput,
  useTranslate,
  SimpleList,
  DateInput,
  Button,
  useNotify,
  useRecordContext,
  ChipField,
} from 'react-admin';
import { useMediaQuery, Theme, Box, Chip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

const filters = [
  <SearchInput key="search" source="q" alwaysOn />,
  <DateInput
    key="createdAtFrom"
    source="createdAt.$gte"
    label="resources.integrations.filters.createdAtFrom"
  />,
  <DateInput
    key="createdAtTo"
    source="createdAt.$lte"
    label="resources.integrations.filters.createdAtTo"
  />,
];

const RequestStatusField = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const notify = useNotify();

  if (!record) return null;

  const handleApprove = async () => {
    try {
      // TODO: Implement approval logic
      notify('Request approved successfully', { type: 'success' });
    } catch (error) {
      notify(
        error instanceof Error ? error.message : 'Failed to approve request',
        {
          type: 'error',
        }
      );
    }
  };

  const handleReject = async () => {
    try {
      // TODO: Implement rejection logic
      notify('Request rejected successfully', { type: 'success' });
    } catch (error) {
      notify(
        error instanceof Error ? error.message : 'Failed to reject request',
        {
          type: 'error',
        }
      );
    }
  };

  if (record.status === 'pending') {
    return (
      <Box display="flex" gap={1}>
        <Chip
          icon={<PendingIcon />}
          label={translate('resources.integrations.status.pending')}
          color="warning"
        />
        <Button
          label={translate('resources.integrations.actions.approve')}
          onClick={handleApprove}
          color="success"
        />
        <Button
          label={translate('resources.integrations.actions.reject')}
          onClick={handleReject}
          color="error"
        />
      </Box>
    );
  }

  return (
    <Chip
      icon={<CheckCircleIcon />}
      label={translate(`resources.integrations.status.${record.status}`)}
      color={record.status === 'approved' ? 'success' : 'error'}
    />
  );
};

const PendingList = () => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const translate = useTranslate();

  return (
    <List
      resource="integrations"
      filters={filters}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filter={{ type: 'zalo_pending_action', status: 'pending' }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) =>
            record.sender?.name || record.sender?.phoneNumber
          }
          secondaryText={(record) => record.content}
          tertiaryText={(record) => (
            <Box display="flex" gap={1} alignItems="center">
              <RequestStatusField />
            </Box>
          )}
        />
      ) : (
        <Datagrid bulkActionButtons={false}>
          <TextField source="sender.name" label="Sender Name" />
          <TextField source="sender.phoneNumber" label="Phone Number" />
          <TextField source="content" />
          <RequestStatusField />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />
        </Datagrid>
      )}
    </List>
  );
};

export default PendingList;
