import { SearchInput, SearchInputProps } from 'react-admin';
import { normalizeVietnamese } from '../utils/normalizeVietnamese';

export const VietnameseSearchInput = (props: SearchInputProps) => {
  return (
    <SearchInput
      {...props}
      parse={(searchText: string) => normalizeVietnamese(searchText)}
    />
  );
};
