import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDataProvider, useTranslate, HttpError } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';

interface CheckinDialogProps {
  open: boolean;
  onClose: () => void;
  customerId: string;
  code?: string;
  onSuccess: () => void;
  onError: (error?: string) => void;
}

const CheckinDialog = ({
  open,
  onClose,
  code,
  customerId,
  onSuccess,
  onError,
}: CheckinDialogProps) => {
  const [otp, setOtp] = useState(code || '');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasExistingProfile, setHasExistingProfile] = useState(false);
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (code && customerId) {
      setOtp(code);
    }
  }, [code, customerId]);

  const handleSuccess = () => {
    // Remove code parameter from URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('code');
    const newSearch = searchParams.toString();
    const newPath = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
    navigate(newPath, { replace: true });
    // Call parent's onSuccess to refresh the list
    onSuccess();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { data: checkin } = await dataProvider.create(
        'integrations/checkin',
        {
          data: {
            customerId,
            otp,
            profile: {
              firstName,
              lastName,
            },
          },
        }
      );

      if (checkin.profile?.firstName || checkin.profile?.lastName) {
        setFirstName(checkin.profile.firstName || '');
        setLastName(checkin.profile.lastName || '');
        setHasExistingProfile(true);
      }
      handleSuccess();
    } catch (error) {
      const errorMessage = (error as HttpError)?.body?.error
        ? (error as HttpError).body.error
        : error instanceof Error
        ? error.message
        : (error as { error: string })?.error
        ? (error as { error: string }).error
        : (error as string) || undefined;
      onError(translate(errorMessage));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Check In Customer</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <TextField
            autoFocus
            label="OTP Code"
            fullWidth
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          {loading ? (
            <Typography>{translate('ra.message.loading')}</Typography>
          ) : hasExistingProfile ? (
            <>
              <TextField
                label="First Name"
                fullWidth
                value={firstName}
                disabled
              />
              <TextField
                label="Last Name"
                fullWidth
                value={lastName}
                disabled
              />
            </>
          ) : (
            <>
              <TextField
                label="First Name"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                label="Last Name"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" disabled={loading}>
          Check In
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckinDialog;
