import {
  AutocompleteInput,
  ReferenceInput,
  useDataProvider,
  useNotify,
  useTranslate,
  useInput,
  Button as RaButton,
} from 'react-admin';
import {
  Box,
  Typography,
  IconButton,
  TextField,
  CircularProgress,
  styled,
} from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useState } from 'react';
import QRScannerDialog from '../../zalo/checkin/QRScannerDialog';
import { Customer } from '../../types';
import { configs } from '../../configs';
import { NekoPosSdk } from 'neko-common';
import { useCustomerCheckin } from '../hooks/useCustomerCheckin';

const sdk = new NekoPosSdk(configs.apiRoot);

const ScanButton = styled(IconButton)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const ScanIcon = styled(QrCodeScannerIcon)(({ theme }) => ({
  fontSize: '2rem',
  marginBottom: theme.spacing(3),
}));

interface LoadingIndicatorProps {
  show?: boolean;
}

const LoadingIndicator = styled(CircularProgress, {
  shouldForwardProp: (prop) => prop !== 'show',
})<LoadingIndicatorProps>(({ show }) => ({
  opacity: show ? 1 : 0,
}));

export const CustomerInput = () => {
  const [qrOpen, setQrOpen] = useState(false);
  const [showProfileInputs, setShowProfileInputs] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [isValidatingOtp, setIsValidatingOtp] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [isCheckingin, setIsCheckingin] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [otp, setOtp] = useState('');
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null
  );
  const [hasCheckedIn, setHasCheckedIn] = useState(false);
  const [hasMembership, setHasMembership] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const translate = useTranslate();
  const { field: customerIdField } = useInput({ source: 'customerId' });
  const { field: customerPointsField } = useInput({ source: 'customerPoints' });

  const { handleCheckin } = useCustomerCheckin({
    onCheckinSuccess: (customerId, points, hasPoints) => {
      customerIdField.onChange(customerId);
      customerPointsField.onChange(points);
      setHasMembership(hasPoints);
      setShowOtpInput(false);
      setQrOpen(false);
      setHasCheckedIn(true);
      setIsScanning(false);
      setIsCheckingin(false);
    },
    onProfileIncomplete: (customerId) => {
      setSelectedCustomerId(customerId);
      setShowProfileInputs(true);
      setQrOpen(false);
      setIsScanning(false);
      setIsCheckingin(false);
    },
  });

  const handleQrScan = async (zaloId: string, code: string) => {
    setIsScanning(true);
    setIsCheckingin(true);
    try {
      await handleCheckin(zaloId, code);
    } finally {
      setIsScanning(false);
    }
  };

  const handleProfileSubmit = async () => {
    try {
      setIsCheckingin(true);
      // Get current customer data first
      const { data: currentCustomer } = await dataProvider.getOne('customers', {
        id: selectedCustomerId as string,
      });

      // Update customer profile
      await dataProvider.update('customers', {
        id: selectedCustomerId,
        data: {
          firstName,
          lastName,
        },
        previousData: currentCustomer,
      });

      // Create membership for the customer
      await sdk.customerMembership.createMembership({
        customerId: selectedCustomerId as string,
        points: 0,
      });

      setShowProfileInputs(false);
      notify(translate('resources.orders.messages.profileUpdateSuccess'), {
        type: 'success',
      });

      // Update form with customer points (0 for new membership)
      customerPointsField.onChange(0);
      setHasMembership(true);
      setHasCheckedIn(true);
    } catch (error: any) {
      notify(translate('resources.orders.messages.profileUpdateFailed'), {
        type: 'error',
      });
    } finally {
      setIsCheckingin(false);
    }
  };

  const handleOtpSubmit = async () => {
    try {
      setIsValidatingOtp(true);
      setIsCheckingin(true);
      // First get the social profile to get the customer entity ID
      const { data: socialProfiles } = await dataProvider.getList(
        'social_profiles',
        {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'createdAt', order: 'DESC' },
          filter: {
            entityId: selectedCustomerId,
            socialProfileType: 'zalo',
          },
        }
      );

      if (socialProfiles.length === 0) {
        notify(translate('resources.orders.messages.customerNotFound'), {
          type: 'error',
        });
        return;
      }

      await handleCheckin(socialProfiles[0].socialProfileId, otp);
    } finally {
      setIsValidatingOtp(false);
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" alignItems="center" gap={1}>
        <ReferenceInput
          source="customerId"
          reference="customers"
          queryOptions={{
            refetchOnMount: true,
            refetchOnReconnect: true,
            refetchOnWindowFocus: true,
          }}
        >
          <AutocompleteInput
            optionText={(choice?: Customer) => {
              if (!choice?.id)
                return translate('resources.orders.messages.unknownCustomer');
              const fullName = `${choice.firstName || ''} ${
                choice.lastName || ''
              }`.trim();
              return (
                fullName ||
                translate('resources.orders.messages.unknownCustomer')
              );
            }}
            label="resources.orders.fields.customer"
            fullWidth
            disabled={hasCheckedIn || isCheckingin}
            onChange={async (value) => {
              if (value) {
                setSelectedCustomerId(value);
                setShowOtpInput(true);
                // Reset points until OTP is verified
                customerPointsField.onChange(0);
              } else {
                customerIdField.onChange(null);
                customerPointsField.onChange(0);
                setShowOtpInput(false);
                setSelectedCustomerId(null);
              }
            }}
          />
        </ReferenceInput>
        <ScanButton
          onClick={() => setQrOpen(true)}
          color="primary"
          disabled={hasCheckedIn || isScanning || isCheckingin}
        >
          {isScanning || isCheckingin ? (
            <CircularProgress size={24} />
          ) : (
            <ScanIcon />
          )}
        </ScanButton>
      </Box>

      {showOtpInput && (
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="subtitle1">
            {translate('resources.orders.messages.enterOtp')}
          </Typography>
          <Box display="flex" gap={2}>
            <TextField
              label={translate('resources.customers.fields.otp')}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              disabled={isValidatingOtp || isCheckingin}
            />
            <RaButton
              onClick={handleOtpSubmit}
              label="resources.orders.actions.verifyOtp"
              disabled={isValidatingOtp || !otp || isCheckingin}
            >
              {isValidatingOtp || isCheckingin ? (
                <CircularProgress size={20} />
              ) : (
                <VerifiedIcon />
              )}
            </RaButton>
          </Box>
        </Box>
      )}

      {showProfileInputs && (
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography variant="subtitle1">
            {translate('resources.orders.messages.completeProfile')}
          </Typography>
          <TextField
            label={translate('resources.orders.fields.firstName')}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            disabled={isCheckingin}
          />
          <TextField
            label={translate('resources.orders.fields.lastName')}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
            disabled={isCheckingin}
          />
          <RaButton
            onClick={handleProfileSubmit}
            label="resources.orders.actions.updateProfile"
            disabled={isCheckingin}
          >
            <LoadingIndicator size={20} show={isCheckingin} />
          </RaButton>
        </Box>
      )}

      {hasCheckedIn && !hasMembership && (
        <Typography variant="body2" color="info">
          {translate('resources.orders.messages.registerForMembership')}
        </Typography>
      )}

      <QRScannerDialog
        open={qrOpen}
        onClose={() => {
          setQrOpen(false);
          setIsScanning(false);
        }}
        onSuccess={handleQrScan}
        onError={(error?: string) => {
          setIsScanning(false);
          notify(error || translate('resources.orders.messages.qrScanFailed'), {
            type: 'error',
          });
        }}
      />
    </Box>
  );
};
