import { useRecordContext, FunctionField, useTranslate } from 'react-admin';
import cronstrue from 'cronstrue';
import 'cronstrue/locales/fr';
import 'cronstrue/locales/vi';

export const CronScheduleField = (props: { source: string }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  if (!record?.[props.source]) return null;

  const formatSchedule = (schedule: string) => {
    // Get current locale from react-admin
    const locale = translate('ra.action.close').includes('Fermer')
      ? 'fr'
      : translate('ra.action.close').includes('Đóng')
      ? 'vi'
      : 'en';

    try {
      // Try to parse with seconds field first
      return cronstrue.toString(schedule, { verbose: true, locale });
    } catch (e) {
      // If parsing with seconds fails, try without seconds (5-field format)
      try {
        // Remove the seconds field if present and try again
        const fiveFieldSchedule = schedule.split(' ').slice(1).join(' ');
        return cronstrue.toString(fiveFieldSchedule, { verbose: true, locale });
      } catch (e) {
        // If both attempts fail, return the raw schedule
        return schedule;
      }
    }
  };

  return (
    <FunctionField
      {...props}
      render={(record: any) => (
        <span title={record[props.source]}>
          {formatSchedule(record[props.source])}
        </span>
      )}
    />
  );
};
