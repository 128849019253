import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  BooleanInput,
  required,
} from 'react-admin';

const httpMethods = [
  { id: 'GET', name: 'GET' },
  { id: 'POST', name: 'POST' },
  { id: 'PUT', name: 'PUT' },
  { id: 'DELETE', name: 'DELETE' },
  { id: 'PATCH', name: 'PATCH' },
];

const validateJson = (value: string) => {
  if (!value) return undefined;
  try {
    JSON.parse(value);
    return undefined;
  } catch (e) {
    return 'Invalid JSON format';
  }
};

export const CronEdit = () => (
  <Edit>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="description" multiline />
      <TextInput
        source="schedule"
        validate={[required()]}
        helperText="Cron expression with optional seconds (e.g., '*/15 * * * * *' for every 15 seconds, or '*/5 * * * *' for every 5 minutes)"
      />
      <TextInput source="url" validate={[required()]} />
      <SelectInput
        source="method"
        choices={httpMethods}
        validate={[required()]}
      />
      <TextInput
        source="headers"
        multiline
        validate={validateJson}
        helperText="Enter headers as JSON object"
        format={(value) => (value ? JSON.stringify(value, null, 2) : '')}
        parse={(value) => (value ? JSON.parse(value) : undefined)}
      />
      <TextInput
        source="body"
        multiline
        validate={validateJson}
        helperText="Enter body as JSON object"
        format={(value) => (value ? JSON.stringify(value, null, 2) : '')}
        parse={(value) => (value ? JSON.parse(value) : undefined)}
      />
      <TextInput
        source="params"
        multiline
        validate={validateJson}
        helperText="Enter params as JSON object"
        format={(value) => (value ? JSON.stringify(value, null, 2) : '')}
        parse={(value) => (value ? JSON.parse(value) : undefined)}
      />
      <BooleanInput source="isActive" />
    </SimpleForm>
  </Edit>
);
