import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttendanceRecordList from './AttendanceRecordList';

// Admin resource
export const attendanceRecordResource = {
  list: AttendanceRecordList,
  icon: AccessTimeIcon,
};

// Staff-only resource
export const staffOnlyAttendanceResource = {
  list: AttendanceRecordList,
  icon: AccessTimeIcon,
  options: { label: 'Attendance' },
};
