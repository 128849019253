import { useGetIdentity } from 'react-admin';
import StaffProfileList from './StaffProfileList';
import { ResourceContextProvider } from 'react-admin';

export const StaffProfileView = () => {
  const { identity } = useGetIdentity();

  // Filter to only show current user's profile
  const filter = { userId: identity?.id };

  return (
    <ResourceContextProvider value="staff_profiles">
      <StaffProfileList
        filter={filter}
        disableCreate={true} // Staff shouldn't create new profiles
        disableBulkActions={true} // No bulk actions needed for single user
      />
    </ResourceContextProvider>
  );
};
