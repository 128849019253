import { useRecordContext } from 'react-admin';
import GrantPointsButton from './GrantPointsButton';

interface SocialProfile {
  id: string;
  socialProfileId: string;
  entityId: string;
}

const GrantPointsField = () => {
  const record = useRecordContext<SocialProfile>();
  if (!record) return null;

  return <GrantPointsButton record={record} />;
};

export default GrantPointsField;
