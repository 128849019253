import React, { useState, useCallback } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  FormDataConsumer,
  useCreate,
  useNotify,
  useRedirect,
  Button as RAButton,
  required,
} from 'react-admin';
import { makeStyles } from '@mui/styles';
import { CURRENCY_OPTIONS } from '../constants/currency';
import {
  Switch,
  FormControlLabel,
  Box,
  InputAdornment,
  IconButton,
  Button,
} from '@mui/material';
import {
  AutoFixHigh as GenerateIcon,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';

const useStyles = makeStyles({
  fullWidthInput: {
    width: '100%',
  },
});

const generatePassword = () => {
  const length = 12;
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*';
  let password = '';
  for (let i = 0; i < length; i++) {
    password += charset.charAt(Math.floor(Math.random() * charset.length));
  }
  return password;
};

const generateUsernameFromEmail = (email: string) => {
  return email.split('@')[0];
};

const StaffProfileCreate = () => {
  const classes = useStyles();
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);
  const [userFormData, setUserFormData] = useState({
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [createdUserId, setCreatedUserId] = useState<string | null>(null);

  const [create] = useCreate();
  const notify = useNotify();
  const redirect = useRedirect();

  const handleGeneratePassword = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      const newPassword = generatePassword();
      setUserFormData((prev) => ({
        ...prev,
        password: newPassword,
        passwordConfirmation: newPassword,
      }));
      setShowPassword(true);
      setShowPasswordConfirmation(true);
    },
    [setUserFormData, setShowPassword, setShowPasswordConfirmation]
  );

  const handleEmailChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newEmail = e.target.value;
      setUserFormData((prev) => ({
        ...prev,
        email: newEmail,
        username:
          !prev.username ||
          prev.username === generateUsernameFromEmail(prev.email)
            ? generateUsernameFromEmail(newEmail)
            : prev.username,
      }));
    },
    []
  );

  const handleUserFormChange = useCallback(
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserFormData((prev) => ({
        ...prev,
        [field]: e.target.value,
      }));
    },
    []
  );

  const handleSubmit = async (values: any) => {
    try {
      if (isNewUser) {
        if (userFormData.password !== userFormData.passwordConfirmation) {
          notify('Passwords do not match', { type: 'error' });
          return;
        }

        // First create user
        const userResponse = await create('users', {
          data: {
            email: userFormData.email,
            username: userFormData.username,
            password: userFormData.password,
            firstName: userFormData.firstName,
            lastName: userFormData.lastName,
          },
        });

        // Store the created user ID and create staff profile in one step
        if (userResponse.data) {
          // Check both id and insertedId
          const userId = userResponse.data.id || userResponse.data.insertedId;

          await create(
            'staff_profiles',
            {
              data: {
                ...values,
                userId, // Use userId directly instead of going through state
              },
            },
            {
              onSuccess: () => {
                notify('Staff profile created successfully');
                redirect('list', 'staff_profiles');
              },
            }
          );
        } else {
          throw new Error('User creation failed - no ID returned');
        }
      } else {
        // For existing user, use the value directly from the form
        if (!values.userId) {
          notify('Please select a user', { type: 'error' });
          return;
        }

        await create(
          'staff_profiles',
          { data: values }, // values.userId should already be set by ReferenceInput
          {
            onSuccess: () => {
              notify('Staff profile created successfully');
              redirect('list', 'staff_profiles');
            },
          }
        );
      }
    } catch (error) {
      console.error('Creation error:', error);
      notify('Error creating staff profile', { type: 'error' });
    }
  };

  return (
    <Create>
      <SimpleForm onSubmit={handleSubmit}>
        <FormControlLabel
          control={
            <Switch
              checked={isNewUser}
              onChange={(e) => setIsNewUser(e.target.checked)}
            />
          }
          label="Create New User"
        />

        {isNewUser ? (
          <>
            <TextInput
              source="email"
              label="Email"
              className={classes.fullWidthInput}
              defaultValue={userFormData.email}
              format={() => userFormData.email}
              parse={(value) => value}
              onChange={(e) => {
                handleEmailChange(e);
                return e.target.value;
              }}
              required
            />
            <TextInput
              source="username"
              label="Username"
              className={classes.fullWidthInput}
              defaultValue={userFormData.username}
              format={() => userFormData.username}
              parse={(value) => value}
              onChange={(e) => {
                handleUserFormChange('username')(e);
                return e.target.value;
              }}
              required
            />
            <TextInput
              source="firstName"
              label="First Name"
              className={classes.fullWidthInput}
              defaultValue={userFormData.firstName}
              format={() => userFormData.firstName}
              parse={(value) => value}
              onChange={(e) => {
                handleUserFormChange('firstName')(e);
                return e.target.value;
              }}
              required
            />
            <TextInput
              source="lastName"
              label="Last Name"
              className={classes.fullWidthInput}
              defaultValue={userFormData.lastName}
              format={() => userFormData.lastName}
              parse={(value) => value}
              onChange={(e) => {
                handleUserFormChange('lastName')(e);
                return e.target.value;
              }}
              required
            />
            <Box display="flex" alignItems="center" gap={1}>
              <TextInput
                source="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                className={classes.fullWidthInput}
                defaultValue={userFormData.password}
                format={() => userFormData.password}
                parse={(value) => value}
                onChange={(e) => {
                  handleUserFormChange('password')(e);
                  return e.target.value;
                }}
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                size="small"
                onClick={handleGeneratePassword}
                startIcon={<GenerateIcon />}
                type="button"
              >
                Generate
              </Button>
            </Box>
            <TextInput
              source="passwordConfirmation"
              label="Confirm Password"
              type={showPasswordConfirmation ? 'text' : 'password'}
              className={classes.fullWidthInput}
              defaultValue={userFormData.passwordConfirmation}
              format={() => userFormData.passwordConfirmation}
              parse={(value) => value}
              onChange={(e) => {
                handleUserFormChange('passwordConfirmation')(e);
                return e.target.value;
              }}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password confirmation visibility"
                      onClick={() =>
                        setShowPasswordConfirmation(!showPasswordConfirmation)
                      }
                      edge="end"
                    >
                      {showPasswordConfirmation ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        ) : (
          <ReferenceInput
            source="userId"
            label="User ID"
            reference="users"
            className={classes.fullWidthInput}
            validate={required()}
          >
            <AutocompleteInput
              optionText={(record) =>
                `${record.firstName} ${record.lastName} (${record.email})`
              }
              className={classes.fullWidthInput}
            />
          </ReferenceInput>
        )}

        <TextInput
          source="address"
          label="Address"
          className={classes.fullWidthInput}
        />
        <ReferenceInput
          source="departmentId"
          reference="organization_departments"
          label="Department"
          className={classes.fullWidthInput}
        >
          <SelectInput
            optionText="name"
            className={classes.fullWidthInput}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => (
            <ReferenceInput
              source="positionId"
              reference="department_positions"
              label="Position"
              className={classes.fullWidthInput}
              filter={{
                departmentId: formData.departmentId || selectedDepartment,
              }}
              allowEmpty
            >
              <SelectInput
                optionText="name"
                className={classes.fullWidthInput}
              />
            </ReferenceInput>
          )}
        </FormDataConsumer>
        <NumberInput
          source="ratePerHour"
          label="Rate per Hour"
          className={classes.fullWidthInput}
        />
        <SelectInput
          source="ratePerHourCurrency"
          label="Currency"
          defaultValue="VND"
          choices={CURRENCY_OPTIONS}
          className={classes.fullWidthInput}
        />
      </SimpleForm>
    </Create>
  );
};

export default StaffProfileCreate;
