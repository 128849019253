import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  ButtonGroup,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ProductGridSelector } from './ProductGridSelector';
import { useTranslate, useGetOne } from 'react-admin';

interface ItemsGridInputProps {
  disabled?: boolean;
}

interface Product {
  id: string | number;
  name: string;
  price: number;
  reference?: string;
}

interface OrderItem {
  productId: string | number;
  quantity: number;
  price: number;
  note?: string;
}

const ProductNameCell = ({ productId }: { productId: string | number }) => {
  const { data: product } = useGetOne('products', { id: productId });
  return (
    <Box
      sx={{
        maxWidth: 150,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {product?.name || productId}
    </Box>
  );
};

const QuantityInput = ({
  value,
  onChange,
  disabled,
}: {
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}) => {
  const handleDecrease = () => {
    if (value > 0) {
      onChange(value - 1);
    }
  };

  const handleIncrease = () => {
    onChange(value + 1);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val === '') {
      onChange(0);
      return;
    }
    const newValue = parseInt(val, 10);
    if (!isNaN(newValue) && newValue >= 0) {
      onChange(newValue);
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <IconButton
        onClick={handleDecrease}
        disabled={disabled || value <= 0}
        size="small"
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: '50%',
          width: 32,
          height: 32,
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
      >
        <RemoveIcon fontSize="small" />
      </IconButton>
      <Box
        sx={{
          backgroundColor: '#f5f5f5',
          borderRadius: 1,
          minWidth: 60,
          height: 32,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TextField
          value={value}
          onChange={handleInputChange}
          disabled={disabled}
          size="small"
          type="number"
          variant="standard"
          inputProps={{
            min: 0,
            style: {
              textAlign: 'center',
              width: '100%',
              padding: 0,
              border: 'none',
              backgroundColor: 'transparent',
              WebkitAppearance: 'textfield',
              MozAppearance: 'textfield',
            },
          }}
          sx={{
            width: '100%',
            '& .MuiInput-root': {
              border: 'none',
              '&:before, &:after': {
                display: 'none',
              },
            },
            '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
              {
                WebkitAppearance: 'none',
                margin: 0,
              },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          }}
        />
      </Box>
      <IconButton
        onClick={handleIncrease}
        disabled={disabled}
        size="small"
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: '50%',
          width: 32,
          height: 32,
          backgroundColor: 'white',
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
      >
        <AddIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

export const ItemsGridInput = ({ disabled }: ItemsGridInputProps) => {
  const translate = useTranslate();
  const form = useFormContext();
  const items = form.watch('items') || [];
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleAddProduct = useCallback(
    (product: Product) => {
      const existingItemIndex = items.findIndex(
        (item: OrderItem) => item.productId === product.id
      );

      if (existingItemIndex >= 0) {
        // Update quantity if product already exists
        const newItems = [...items];
        newItems[existingItemIndex] = {
          ...newItems[existingItemIndex],
          quantity: (newItems[existingItemIndex].quantity || 0) + 1,
        };
        form.setValue('items', newItems);
      } else {
        // Add new item if product doesn't exist
        form.setValue('items', [
          ...items,
          {
            productId: product.id,
            quantity: 1,
            price: product.price,
          },
        ]);
      }
    },
    [items, form]
  );

  const handleUpdateQuantity = useCallback(
    (index: number, value: string) => {
      const quantity = parseInt(value, 10);
      if (!isNaN(quantity) && quantity >= 0) {
        const newItems = [...items];
        newItems[index] = {
          ...newItems[index],
          quantity,
        };
        form.setValue('items', newItems);
      }
    },
    [items, form]
  );

  const handleUpdateNote = useCallback(
    (index: number, value: string) => {
      const newItems = [...items];
      newItems[index] = {
        ...newItems[index],
        note: value,
      };
      form.setValue('items', newItems);
    },
    [items, form]
  );

  const handleRemoveItem = useCallback(
    (index: number) => {
      const newItems = [...items];
      newItems.splice(index, 1);
      form.setValue('items', newItems);
    },
    [items, form]
  );

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return (
    <Box>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpenDialog}
          disabled={disabled}
        >
          {translate('resources.orders.actions.addProduct')}
        </Button>
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          width: '100%',
          '& .MuiTable-root': {
            minWidth: '100%',
            tableLayout: 'fixed',
          },
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell width="30%">
                {translate('resources.orders.fields.product')}
              </TableCell>
              <TableCell width="20%" align="center">
                {translate('resources.orders.fields.quantity')}
              </TableCell>
              <TableCell width="15%" align="right">
                {translate('resources.orders.fields.price')}
              </TableCell>
              <TableCell width="30%">
                {translate('resources.orders.fields.note')}
              </TableCell>
              <TableCell padding="checkbox" width="5%"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item: OrderItem, index: number) => (
              <TableRow key={item.productId}>
                <TableCell component="th" scope="row" width="30%">
                  <ProductNameCell productId={item.productId} />
                </TableCell>
                <TableCell align="center" width="20%">
                  <Box
                    display="flex"
                    justifyContent="center"
                    sx={{
                      minWidth: 140,
                    }}
                  >
                    <QuantityInput
                      value={item.quantity}
                      onChange={(value) =>
                        handleUpdateQuantity(index, value.toString())
                      }
                      disabled={disabled}
                    />
                  </Box>
                </TableCell>
                <TableCell align="right" width="15%">
                  {item.price?.toLocaleString()} đ
                </TableCell>
                <TableCell width="30%">
                  <TextField
                    value={item.note || ''}
                    onChange={(e) => handleUpdateNote(index, e.target.value)}
                    disabled={disabled}
                    size="small"
                    fullWidth
                    multiline
                    maxRows={2}
                  />
                </TableCell>
                <TableCell padding="checkbox" width="5%">
                  <IconButton
                    onClick={() => handleRemoveItem(index)}
                    disabled={disabled}
                    size="small"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: { height: '80vh' },
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          <ProductGridSelector
            disabled={disabled}
            onAddProduct={handleAddProduct}
            onClose={handleCloseDialog}
            currentItems={items}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
