import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

import RentList from './RentList';
import RentEdit from './RentEdit';
import RentCreate from './RentCreate';

export default {
  list: RentList,
  edit: RentEdit,
  create: RentCreate,
  icon: LibraryBooksIcon,
};
