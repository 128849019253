import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useAuthProvider, useNotify, useTranslate } from 'react-admin';

interface ChangePasswordDialogProps {
  open: boolean;
  onClose: () => void;
}

const ChangePasswordDialog = ({ open, onClose }: ChangePasswordDialogProps) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const authProvider = useAuthProvider();
  const notify = useNotify();
  const translate = useTranslate();

  const handleSubmit = async () => {
    try {
      setError(null);
      setLoading(true);

      // Validate passwords
      if (!currentPassword || !newPassword || !confirmPassword) {
        setError(translate('ra.auth.new_password_required'));
        return;
      }

      if (newPassword !== confirmPassword) {
        setError(translate('ra.auth.new_passwords_do_not_match'));
        return;
      }

      if (newPassword.length < 8) {
        setError(translate('ra.auth.new_password_min_length'));
        return;
      }

      // Change password
      await authProvider.auth.changePassword(currentPassword, newPassword);
      notify('ra.auth.password_changed_successfully', { type: 'success' });
      handleClose();
    } catch (error) {
      console.error('Error changing password:', error);
      setError(translate('ra.auth.current_password_incorrect'));
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
    setError(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{translate('ra.auth.change_password')}</DialogTitle>
      <DialogContent>
        {error && (
          <div style={{ color: 'red', marginBottom: '1rem' }}>{error}</div>
        )}
        <TextField
          autoFocus
          margin="dense"
          label={translate('ra.auth.current_password')}
          type="password"
          fullWidth
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          disabled={loading}
        />
        <TextField
          margin="dense"
          label={translate('ra.auth.new_password')}
          type="password"
          fullWidth
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          disabled={loading}
          helperText={translate('ra.auth.new_password_min_length')}
        />
        <TextField
          margin="dense"
          label={translate('ra.auth.confirm_new_password')}
          type="password"
          fullWidth
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          {translate('ra.action.cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={loading}>
          {translate('ra.auth.change_password')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
