import * as React from 'react';
import inflection from 'inflection';
import { Card, CardContent } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  SavedQueriesList,
  useGetList,
} from 'react-admin';

import { Category } from '../types';

const Aside = () => {
  const { data } = useGetList<Category>('book_categories', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'name', order: 'ASC' },
  });

  return (
    <Card
      sx={{
        display: { xs: 'none', md: 'block' },
        order: -1,
        width: '15em',
        mr: 2,
        alignSelf: 'flex-start',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch hiddenLabel />

        <SavedQueriesList />

        <FilterList
          label="resources.books.filters.rents"
          icon={<AttachMoneyIcon />}
        >
          <FilterListItem
            label="resources.books.filters.best_rents"
            value={{
              rents: { $lte: undefined, $gt: 25 },
            }}
          />
          <FilterListItem
            label="resources.books.filters.average_rents"
            value={{
              rents: { $lte: 25, $gt: 10 },
            }}
          />
          <FilterListItem
            label="resources.books.filters.low_rents"
            value={{
              rents: { $lte: 10, $gt: 0 },
            }}
          />
          <FilterListItem
            label="resources.books.filters.never_rent"
            value={{
              rents: { $lte: undefined, $gt: 0 },
            }}
          />
        </FilterList>

        <FilterList
          label="resources.books.filters.stock"
          icon={<BarChartIcon />}
        >
          <FilterListItem
            label="resources.books.filters.no_stock"
            value={{
              quantity: 0,
            }}
          />
          <FilterListItem
            label="resources.books.filters.low_stock"
            value={{
              quantity: { $lt: 10, $gt: 0 },
            }}
          />
          <FilterListItem
            label="resources.books.filters.average_stock"
            value={{
              quantity: { $lte: 50, $gt: 9 },
            }}
          />
          <FilterListItem
            label="resources.books.filters.enough_stock"
            value={{
              quantity: { $lt: undefined, $gt: 49 },
            }}
          />
        </FilterList>

        <FilterList
          label="resources.books.filters.categories"
          icon={<LocalOfferIcon />}
        >
          {data &&
            data.map((record: any) => (
              <FilterListItem
                label={inflection.humanize(record.name)}
                key={record.id}
                value={{ categoryId: record.id }}
              />
            ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default Aside;
