import Box from '@mui/material/Box';
import { useState } from 'react';
import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
  useTranslate,
  useGetIdentity,
} from 'react-admin';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { resources } from '../resources';
import SubMenu from './SubMenu';
import { StaffProfileView } from '../staffProfiles/StaffProfileView';
import { StaffAttendanceView } from '../attendanceRecords/StaffAttendanceView';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const MenuLink = MenuItemLink as unknown as {
  (props: {
    to: string;
    primaryText: string;
    leftIcon?: JSX.Element;
    dense?: boolean;
    component?: React.ComponentType<any>;
  }): JSX.Element;
};

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState<Record<string, boolean>>({});
  const translate = useTranslate();
  const [open] = useSidebarState();
  const { identity } = useGetIdentity();
  const isStaff = identity?.roles?.includes('staff');
  const isStoreManager = identity?.roles?.includes('storeManager');

  const hasPermission = (resource: string) => {
    if (!identity) return false;
    if (identity.roles?.includes('sysAdmin')) return true;
    if (isStaff) {
      const staffResources = [
        'menuSales',
        'orders',
        'menuZalo',
        'zalo/checkin',
        'menuLibrary',
        'books',
        'book_categories',
        'book_rents',
      ];
      return staffResources.includes(resource);
    }
    if (isStoreManager) {
      const managerResources = [
        'menuOrganization',
        'organizations',
        'organization_departments',
        'department_positions',
        'staff_profiles',
        'attendance_records',
        'menuSales',
        'orders',
        'menuZalo',
        'zalo/checkin',
        'menuLibrary',
        'books',
        'book_categories',
        'book_rents',
        'menuCustomers',
        'customers',
        'reviews',
      ];
      return managerResources.includes(resource);
    }

    if (identity.roles?.includes('admin')) return true;
    return false;
  };

  const handleToggle = (menu: string) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />

      {/* Staff-specific routes - only show to staff */}
      {isStaff && (
        <>
          <MenuLink
            to="/staff_profile"
            primaryText={translate('resources.staff_profiles.name', {
              smart_count: 2,
            })}
            leftIcon={<PersonIcon />}
            dense={dense}
          />
          <MenuLink
            to="/staff_attendance"
            primaryText={translate('resources.attendance_records.name', {
              smart_count: 2,
            })}
            leftIcon={<AccessTimeIcon />}
            dense={dense}
          />
        </>
      )}

      {/* Regular menu items */}
      {resources.map((resource) => {
        if (!hasPermission(resource.resource)) return null;

        if (resource.children) {
          return (
            <SubMenu
              key={resource.name}
              handleToggle={() => handleToggle(resource.resource)}
              isOpen={state[resource.resource] || false}
              name={resource.name}
              icon={resource.icon}
              dense={dense}
            >
              {resource.children.map((child) => {
                if (!hasPermission(child.resource)) return null;
                return (
                  <MenuLink
                    key={child.name}
                    to={`/${child.resource}`}
                    primaryText={translate(child.name, { smart_count: 2 })}
                    leftIcon={child.icon}
                    dense={dense}
                  />
                );
              })}
            </SubMenu>
          );
        }

        return (
          <MenuLink
            key={resource.name}
            to={`/${resource.resource}`}
            primaryText={translate(resource.name, { smart_count: 2 })}
            leftIcon={resource.icon}
            dense={dense}
          />
        );
      })}
    </Box>
  );
};

export default Menu;
