import { Layout as RaLayout, useGetIdentity, useNotify } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { useState, useEffect } from 'react';
import PasskeyRegistrationDialog from './PasskeyRegistrationDialog';
import { useAuthProvider } from 'react-admin';

const Layout = (props: any) => {
  const [showPasskeyDialog, setShowPasskeyDialog] = useState(false);
  const { identity } = useGetIdentity();
  const authProvider = useAuthProvider();
  const notify = useNotify();

  useEffect(() => {
    // Check if we should show the dialog when identity is loaded
    const checkPasskeyStatus = async () => {
      if (identity) {
        const hasShownPasskeyPrompt = localStorage.getItem(
          'hasShownPasskeyPrompt'
        );
        if (!hasShownPasskeyPrompt) {
          try {
            const hasPasskey = await authProvider.auth.hasPasskey();
            if (!hasPasskey) {
              setShowPasskeyDialog(true);
            } else {
              localStorage.setItem('hasShownPasskeyPrompt', 'true');
            }
          } catch (error) {
            console.error('Error checking passkey status:', error);
            // For errors, don't show the dialog to avoid confusion
            localStorage.setItem('hasShownPasskeyPrompt', 'true');
          }
        }
      }
    };

    checkPasskeyStatus();
  }, [identity, authProvider]);

  const handleRegisterPasskey = async () => {
    try {
      await authProvider.auth.registerPasskey();
      notify('ra.auth.register_passkey_success', { type: 'success' });
      setShowPasskeyDialog(false);
      localStorage.setItem('hasShownPasskeyPrompt', 'true');
    } catch (error) {
      console.error('Passkey registration error:', error);
      notify('ra.auth.register_passkey_error', { type: 'error' });
    }
  };

  const handleClosePasskeyDialog = () => {
    setShowPasskeyDialog(false);
    localStorage.setItem('hasShownPasskeyPrompt', 'true');
  };

  return (
    <>
      <RaLayout {...props} appBar={AppBar} menu={Menu} />
      <PasskeyRegistrationDialog
        open={showPasskeyDialog}
        onClose={handleClosePasskeyDialog}
        onRegister={handleRegisterPasskey}
      />
    </>
  );
};

export default Layout;
