import BadgeIcon from '@mui/icons-material/Badge';
import StaffProfileList from './StaffProfileList';
import StaffProfileEdit from './StaffProfileEdit';
import StaffProfileCreate from './StaffProfileCreate';
import { nekoStaff } from 'public-contracts';

export default {
  list: StaffProfileList,
  create: StaffProfileCreate,
  edit: StaffProfileEdit,
  icon: BadgeIcon,
  recordRepresentation: (record: nekoStaff.StaffProfile) => {
    if (!record) return '';
    return `Staff Profile #${record.id}`;
  },
};
