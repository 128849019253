import {
  DataProvider,
  RaRecord,
  GetListParams,
  GetListResult,
  GetOneParams,
  UpdateParams,
  CreateParams,
} from 'react-admin';

const STORAGE_KEY = 'neko_coffee_config';

interface Configuration extends RaRecord {
  qrCode?: string;
}

const getStoredConfig = (): Configuration => {
  const stored = localStorage.getItem(STORAGE_KEY);
  if (!stored) {
    const defaultConfig = { id: '1' };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(defaultConfig));
    return defaultConfig;
  }
  return JSON.parse(stored);
};

const updateStoredConfig = (config: Configuration) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(config));
  return config;
};

export const configurationProvider: DataProvider<any> = {
  getList: async (
    _resource: string,
    _params: GetListParams
  ): Promise<GetListResult<any>> => {
    const config = getStoredConfig();
    return {
      data: [config],
      total: 1,
    };
  },

  getOne: async (
    _resource: string,
    _params: GetOneParams
  ): Promise<{ data: any }> => {
    const config = getStoredConfig();
    return { data: config };
  },

  update: async (
    _resource: string,
    params: UpdateParams
  ): Promise<{ data: any }> => {
    const config = getStoredConfig();
    const updatedConfig = { ...config, ...params.data };
    updateStoredConfig(updatedConfig);
    return { data: updatedConfig };
  },

  create: async (
    _resource: string,
    params: CreateParams
  ): Promise<{ data: any }> => {
    const config = getStoredConfig();
    const updatedConfig = { ...config, ...params.data };
    updateStoredConfig(updatedConfig);
    return { data: updatedConfig };
  },

  getMany: async (
    _resource: string,
    _params: any
  ): Promise<{ data: any[] }> => {
    const config = getStoredConfig();
    return { data: [config] };
  },

  updateMany: async (
    _resource: string,
    params: any
  ): Promise<{ data: any[] }> => {
    const config = getStoredConfig();
    const updatedConfig = { ...config, ...params.data };
    updateStoredConfig(updatedConfig);
    return { data: [updatedConfig.id] };
  },

  delete: async (_resource: string, _params: any): Promise<{ data: any }> => {
    const config = getStoredConfig();
    return { data: config };
  },

  deleteMany: async (
    _resource: string,
    _params: any
  ): Promise<{ data: any[] }> => {
    const config = getStoredConfig();
    return { data: [config.id] };
  },

  getManyReference: async (
    _resource: string,
    _params: any
  ): Promise<GetListResult<any>> => {
    return { data: [], total: 0 };
  },
};
