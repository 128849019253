import { useDataProvider, useNotify, useTranslate } from 'react-admin';
import { NekoPosSdk } from 'neko-common';
import { configs } from '../../configs';

const sdk = new NekoPosSdk(configs.apiRoot);

interface UseCustomerCheckinProps {
  onCheckinSuccess: (
    customerId: string,
    points: number,
    hasMembership: boolean
  ) => void;
  onProfileIncomplete: (customerId: string) => void;
}

type ErrorResponse = {
  key: string;
  params?: { minutes: string };
  message?: string;
};

export const useCustomerCheckin = ({
  onCheckinSuccess,
  onProfileIncomplete,
}: UseCustomerCheckinProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const translate = useTranslate();

  const mapErrorToTranslationKey = (error: any): ErrorResponse | string => {
    const errorMessage = error?.body?.error || error?.error || '';

    switch (errorMessage.toLowerCase()) {
      case 'invalid otp':
        return 'invalidOtp';
      case 'otp has expired':
        return 'otpExpired';
      case 'otp has already been used':
        return 'otpUsed';
      case 'social profile not found':
      case 'customer not found':
        return 'customerNotFound';
      default:
        if (errorMessage.toLowerCase().includes('please wait')) {
          const minutes = errorMessage.match(/\d+/)?.[0];
          return { key: 'checkinCooldown', params: { minutes } };
        }
        return { key: 'checkinFailed', message: errorMessage };
    }
  };

  const handleCheckin = async (zaloId: string, otp: string) => {
    try {
      // Get customer by Zalo ID first
      const { data: socialProfiles } = await dataProvider.getList(
        'social_profiles',
        {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
          filter: {
            socialProfileId: zaloId,
            socialProfileType: 'zalo',
          },
        }
      );

      // If customer exists, check their profile
      if (socialProfiles && socialProfiles.length > 0) {
        const { data: customer } = await dataProvider.getOne('customers', {
          id: socialProfiles[0].entityId,
        });

        // If profile is incomplete, notify parent
        if (!customer.firstName && !customer.lastName) {
          onProfileIncomplete(socialProfiles[0].entityId);
          return;
        }

        // Profile is complete, proceed with check-in
        try {
          const response = await sdk.customerMembership.checkin({
            customerId: zaloId,
            otp,
          });

          if (!response.success) {
            const errorResult = mapErrorToTranslationKey(response);
            if (typeof errorResult === 'string') {
              notify(translate(`resources.orders.messages.${errorResult}`), {
                type: 'error',
              });
            } else if (
              errorResult.key === 'checkinFailed' &&
              errorResult.message
            ) {
              notify(errorResult.message, { type: 'error' });
            } else {
              notify(
                translate(
                  `resources.orders.messages.${errorResult.key}`,
                  errorResult.params
                ),
                { type: 'error' }
              );
            }
            return;
          }

          // Get customer membership data
          try {
            const { data: integrations } = await dataProvider.getList(
              'integrations',
              {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'createdAt', order: 'DESC' },
                filter: {
                  type: 'customer_membership',
                  'data.customerId': socialProfiles[0].entityId,
                },
              }
            );

            if (integrations && integrations.length > 0) {
              onCheckinSuccess(
                socialProfiles[0].entityId,
                integrations[0].data.points,
                true
              );
            } else {
              onCheckinSuccess(socialProfiles[0].entityId, 0, false);
              notify(
                translate('resources.orders.messages.registerForMembership'),
                {
                  type: 'info',
                }
              );
            }
          } catch (error) {
            onCheckinSuccess(socialProfiles[0].entityId, 0, false);
            notify(translate('resources.orders.messages.checkinSuccess'), {
              type: 'success',
            });
          }
        } catch (error: any) {
          const errorResult = mapErrorToTranslationKey(error);
          if (typeof errorResult === 'string') {
            notify(translate(`resources.orders.messages.${errorResult}`), {
              type: 'error',
            });
          } else if (
            errorResult.key === 'checkinFailed' &&
            errorResult.message
          ) {
            notify(errorResult.message, { type: 'error' });
          } else {
            notify(
              translate(
                `resources.orders.messages.${errorResult.key}`,
                errorResult.params
              ),
              { type: 'error' }
            );
          }
        }
      } else {
        // No social profile found, create new customer with check-in
        try {
          const response = await sdk.customerMembership.checkin({
            customerId: zaloId,
            otp,
          });

          if (!response.success) {
            const errorResult = mapErrorToTranslationKey(response);
            if (typeof errorResult === 'string') {
              notify(translate(`resources.orders.messages.${errorResult}`), {
                type: 'error',
              });
            } else if (
              errorResult.key === 'checkinFailed' &&
              errorResult.message
            ) {
              notify(errorResult.message, { type: 'error' });
            } else {
              notify(
                translate(
                  `resources.orders.messages.${errorResult.key}`,
                  errorResult.params
                ),
                { type: 'error' }
              );
            }
            return;
          }

          // Get the newly created customer ID
          const { data: newSocialProfiles } = await dataProvider.getList(
            'social_profiles',
            {
              pagination: { page: 1, perPage: 1 },
              sort: { field: 'id', order: 'ASC' },
              filter: {
                socialProfileId: zaloId,
                socialProfileType: 'zalo',
              },
            }
          );

          if (!newSocialProfiles || newSocialProfiles.length === 0) {
            notify(translate('resources.orders.messages.customerNotFound'), {
              type: 'error',
            });
            return;
          }

          onProfileIncomplete(newSocialProfiles[0].entityId);
        } catch (error: any) {
          const errorResult = mapErrorToTranslationKey(error);
          if (typeof errorResult === 'string') {
            notify(translate(`resources.orders.messages.${errorResult}`), {
              type: 'error',
            });
          } else if (
            errorResult.key === 'checkinFailed' &&
            errorResult.message
          ) {
            notify(errorResult.message, { type: 'error' });
          } else {
            notify(
              translate(
                `resources.orders.messages.${errorResult.key}`,
                errorResult.params
              ),
              { type: 'error' }
            );
          }
        }
      }
    } catch (error: any) {
      const errorResult = mapErrorToTranslationKey(error);
      if (typeof errorResult === 'string') {
        notify(translate(`resources.orders.messages.${errorResult}`), {
          type: 'error',
        });
      } else if (errorResult.key === 'checkinFailed' && errorResult.message) {
        notify(errorResult.message, { type: 'error' });
      } else {
        notify(
          translate(
            `resources.orders.messages.${errorResult.key}`,
            errorResult.params
          ),
          { type: 'error' }
        );
      }
    }
  };

  return { handleCheckin };
};
