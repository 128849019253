import {
  Datagrid,
  List,
  SimpleList,
  TextField,
  NumberField,
} from 'react-admin';
import { useTheme, useMediaQuery } from '@mui/material';
import GrantPointsField from './GrantPointsField';

const MemberList = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <List>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.socialProfileId}
          secondaryText={(record) => record.entityId}
          tertiaryText={(record) => record.points}
        />
      ) : (
        <Datagrid bulkActionButtons={false} rowClick="show">
          <TextField source="socialProfileId" />
          <TextField source="entityId" />
          <NumberField source="points" />
          <GrantPointsField />
        </Datagrid>
      )}
    </List>
  );
};
export default MemberList;
