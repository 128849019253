import * as React from 'react';
import { ReferenceField, ReferenceFieldProps } from 'react-admin';
import CustomerBriefField from './CustomerBriefField';

interface Props
  extends Omit<ReferenceFieldProps, 'reference' | 'children' | 'source'> {
  source?: string;
  showPoints?: boolean;
  showZalo?: boolean;
}

const CustomerReferenceField = ({ showPoints, showZalo, ...props }: Props) => (
  <ReferenceField
    source="customerId"
    reference="customers"
    queryOptions={{ meta: { brief: true } }}
    {...props}
  >
    <CustomerBriefField showPoints={showPoints} showZalo={showZalo} />
  </ReferenceField>
);

CustomerReferenceField.defaultProps = {
  label: 'resources.orders.fields.customer',
  source: 'customerId',
  showPoints: true,
  showZalo: true,
};

export default CustomerReferenceField;
