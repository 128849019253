import { ReferenceField, FunctionField } from 'react-admin';

const StaffNameField = () => (
  <ReferenceField source="userId" reference="users">
    <FunctionField
      render={(record: { firstName: string; lastName: string }) =>
        `${record.firstName} ${record.lastName}`
      }
    />
  </ReferenceField>
);

export default StaffNameField;
