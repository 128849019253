import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Typography,
  Divider,
  Box,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDataProvider, useTranslate, HttpError } from 'react-admin';
import { useLocation, useNavigate } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

interface CheckinDialogProps {
  open: boolean;
  onClose: () => void;
  customerId: string;
  code?: string;
  onSuccess: () => void;
  onError: (error?: string) => void;
}

interface MembershipInfo {
  points: number;
  tier: {
    name: string;
    pointMultiplier: number;
  };
  nextTier?: {
    name: string;
    requiredPoints: number;
  };
  features: Array<{
    id: string;
    name: string;
    description: string;
  }>;
}

const CheckinDialog = ({
  open,
  onClose,
  code,
  customerId,
  onSuccess,
  onError,
}: CheckinDialogProps) => {
  const [otp, setOtp] = useState(code || '');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasExistingProfile, setHasExistingProfile] = useState(false);
  const [membershipInfo, setMembershipInfo] = useState<MembershipInfo | null>(
    null
  );
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (code && customerId) {
      setOtp(code);
      fetchMembershipInfo();
    }
  }, [code, customerId]);

  const fetchMembershipInfo = async () => {
    try {
      // Fetch customer membership data
      const { data: membership } = await dataProvider.getOne(
        'customer_memberships',
        {
          id: customerId,
        }
      );

      // Fetch membership features
      const { data: features } = await dataProvider.getMany(
        'membership_features',
        {
          ids: membership.membership.featureIds || [],
        }
      );

      setMembershipInfo({
        points: membership.points,
        tier: {
          name: membership.membership.name,
          pointMultiplier: membership.membership.pointMultiplier,
        },
        nextTier: membership.membership.nextTier
          ? {
              name: membership.membership.nextTier.name,
              requiredPoints: membership.membership.nextTier.requiredPoints,
            }
          : undefined,
        features: features.map((feature) => ({
          id: feature.id,
          name: feature.name,
          description: feature.description,
        })),
      });
    } catch (error) {
      console.error('Failed to fetch membership info:', error);
    }
  };

  const handleSuccess = () => {
    // Remove code parameter from URL
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete('code');
    const newSearch = searchParams.toString();
    const newPath = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
    navigate(newPath, { replace: true });
    // Call parent's onSuccess to refresh the list
    onSuccess();
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { data: checkin } = await dataProvider.create(
        'integrations/checkin',
        {
          data: {
            customerId,
            otp,
            profile: {
              firstName,
              lastName,
            },
          },
        }
      );

      if (checkin.profile?.firstName || checkin.profile?.lastName) {
        setFirstName(checkin.profile.firstName || '');
        setLastName(checkin.profile.lastName || '');
        setHasExistingProfile(true);
      }
      handleSuccess();
    } catch (error) {
      const errorMessage = (error as HttpError)?.body?.error
        ? (error as HttpError).body.error
        : error instanceof Error
        ? error.message
        : (error as { error: string })?.error
        ? (error as { error: string }).error
        : (error as string) || undefined;
      onError(translate(errorMessage));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Check In Customer</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <TextField
            autoFocus
            label="OTP Code"
            fullWidth
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          {loading ? (
            <Typography>{translate('ra.message.loading')}</Typography>
          ) : hasExistingProfile ? (
            <>
              <TextField
                label="First Name"
                fullWidth
                value={firstName}
                disabled
              />
              <TextField
                label="Last Name"
                fullWidth
                value={lastName}
                disabled
              />
            </>
          ) : (
            <>
              <TextField
                label="First Name"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                label="Last Name"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </>
          )}

          {membershipInfo && (
            <>
              <Divider sx={{ my: 2 }} />
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Membership Status
                </Typography>
                <Stack spacing={1}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>Current Tier:</Typography>
                    <Chip
                      label={membershipInfo.tier.name}
                      color="primary"
                      icon={<StarIcon />}
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>Points Balance:</Typography>
                    <Typography variant="h6" color="primary">
                      {membershipInfo.points.toLocaleString()}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>Point Multiplier:</Typography>
                    <Typography>
                      {(membershipInfo.tier.pointMultiplier * 100).toFixed(0)}%
                    </Typography>
                  </Box>
                  {membershipInfo.nextTier && (
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        {membershipInfo.nextTier.requiredPoints -
                          membershipInfo.points}{' '}
                        points until {membershipInfo.nextTier.name}
                      </Typography>
                    </Box>
                  )}
                </Stack>

                <Divider sx={{ my: 2 }} />

                <Typography variant="subtitle1" gutterBottom>
                  Available Benefits
                </Typography>
                <List dense>
                  {membershipInfo.features.map((feature) => (
                    <ListItem key={feature.id}>
                      <ListItemIcon>
                        <LocalOfferIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={feature.name}
                        secondary={feature.description}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" disabled={loading}>
          Check In
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CheckinDialog;
