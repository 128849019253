import { stringify } from 'query-string';
import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  HttpError,
  Identifier,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { Options } from 'ra-core';
import { configs } from '../configs';
import authProvider from '../authProvider';
import { configurationProvider } from '../configuration/configurationProvider';

interface ExtendedOptions extends Options {
  meta?: Record<string, any>;
}

const createHeadersFromOptions = (options: ExtendedOptions): Headers => {
  const requestHeaders = (options.headers ||
    new Headers({
      Accept: 'application/json',
    })) as Headers;
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && (!options.method || options.method === 'GET')) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json');
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token);
  }

  return requestHeaders;
};

const httpClient = async (url: string, options: ExtendedOptions = {}) => {
  const [authenticated, isRedirectedToAuthServer] = await Promise.all([
    authProvider.auth.isAuthenticated(),
    authProvider.auth.isRedirectedToAuthServer(),
  ]);

  if (!authenticated && !isRedirectedToAuthServer) {
    return Promise.resolve({
      status: 200,
      headers: new Headers(),
      body: '[]',
      json: [],
    });
  }

  const requestHeaders = createHeadersFromOptions({
    ...options,
    user: {
      ...options.user,
      authenticated,
      token: 'Bearer ' + (await authProvider.auth.getAccessToken()),
    },
  });

  if (options.method === 'GET' || !options.method) {
    const parsedUrl = new URL(url);
    const params = parsedUrl.searchParams;
    let filter = params.get('filter')
      ? JSON.parse(params.get('filter') as string)
      : {};
    const identity = await authProvider.auth.getUserProfile();
    if (identity?.roles?.includes('staff')) {
      if (parsedUrl.pathname.includes('/users')) {
        filter = {
          ...filter,
          id: identity.id,
        };
        params.set('filter', JSON.stringify(filter));
      } else if (parsedUrl.pathname.includes('/attendance_records')) {
        filter = {
          ...filter,
          userId: identity.id,
        };
        params.set('filter', JSON.stringify(filter));
      }
    }

    if (params.get('filter')) {
      if (filter.id) {
        const { id, ...rest } = filter;
        if (typeof id !== 'string') {
          const newFilter = {
            ...rest,
          };
          params.set('filter', JSON.stringify(newFilter));
        }
      }
    }
    if (params.get('range')) {
      const range = JSON.parse(params.get('range') as string);
      const [start, end] = range;
      params.set('size', (end - start + 1).toString());
      params.set('page', (start / (end - start + 1) + 1).toString());
    }

    if (options.meta) {
      params.set('meta', JSON.stringify(options.meta));
    }

    url = parsedUrl.toString();
  }

  return await fetch(url, { ...options, headers: requestHeaders })
    .then((response) =>
      response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
        if (json.insertedId) {
          json.id = json.insertedId;
        }
        if (json.success && json.data?.insertedId) {
          json = {
            ...json.data,
            id: json.data.insertedId,
          };
        }
        if (json.acknowledged && !json.id) {
          json.id = (options.body as any)?.id;
        }
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError((json && json.message) || statusText, status, json)
        );
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

const countHeader = 'Content-Range';

const dataProvider: DataProvider = {
  getList: <RecordType extends RaRecord = any>(
    resource: string,
    params: GetListParams
  ): Promise<GetListResult<RecordType>> => {
    if (resource === 'configuration' && configurationProvider.getList) {
      return configurationProvider.getList(resource, params);
    }

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { meta } = params;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify(params.filter),
    };

    const url = `${configs.apiRoot}/${resource}?${stringify(query)}`;
    const options: ExtendedOptions = {
      headers: new Headers({
        Range: `${resource}=${rangeStart}-${rangeEnd}`,
      }),
      meta,
    };

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        );
      }
      return {
        data: json,
        total: parseInt(headers.get(countHeader)?.split('/').pop() || '0', 10),
      };
    });
  },

  getOne: <RecordType extends RaRecord = any>(
    resource: string,
    params: GetOneParams<any>
  ): Promise<GetOneResult<RecordType>> => {
    if (resource === 'configuration' && configurationProvider.getOne) {
      return configurationProvider.getOne(resource, params);
    }

    const { meta } = params;
    return httpClient(`${configs.apiRoot}/${resource}/${params.id}`, {
      meta,
    }).then(({ json }) => ({
      data: json,
    }));
  },

  getMany: <RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyParams
  ): Promise<GetManyResult<RecordType>> => {
    if (resource === 'configuration' && configurationProvider.getMany) {
      return configurationProvider.getMany(resource, params);
    }

    const { meta } = params;
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${configs.apiRoot}/${resource}?${stringify(query)}`;
    return httpClient(url, { meta }).then(({ json }) => ({ data: json }));
  },

  getManyReference: <RecordType extends RaRecord = any>(
    resource: string,
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<RecordType>> => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { meta } = params;

    const rangeStart = (page - 1) * perPage;
    const rangeEnd = page * perPage - 1;

    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([rangeStart, rangeEnd]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };

    const url = `${configs.apiRoot}/${resource}?${stringify(query)}`;
    const options: ExtendedOptions = {
      headers: new Headers({
        Range: `${resource}=${rangeStart}-${rangeEnd}`,
      }),
      meta,
    };

    return httpClient(url, options).then(({ headers, json }) => {
      if (!headers.has(countHeader)) {
        throw new Error(
          `The ${countHeader} header is missing in the HTTP Response. The data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
        );
      }
      return {
        data: json,
        total: parseInt(headers.get(countHeader)?.split('/').pop() || '0', 10),
      };
    });
  },

  update: <RecordType extends RaRecord = any>(
    resource: string,
    params: UpdateParams<any>
  ): Promise<UpdateResult<RecordType>> => {
    if (resource === 'configuration' && configurationProvider.update) {
      return configurationProvider.update(resource, params);
    }

    const { meta } = params;
    return httpClient(`${configs.apiRoot}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
      meta,
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (
    resource: string,
    params: UpdateManyParams<any>
  ): Promise<UpdateManyResult> => {
    if (resource === 'configuration' && configurationProvider.updateMany) {
      return configurationProvider.updateMany(resource, params);
    }

    const { meta } = params;
    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${configs.apiRoot}/${resource}/${id}`, {
          method: 'PUT',
          body: JSON.stringify(params.data),
          meta,
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
  },

  create: <RecordType extends Omit<RaRecord, 'id'> = any>(
    resource: string,
    params: CreateParams<any>
  ): Promise<CreateResult<RecordType & { id: Identifier }>> => {
    const { meta } = params;
    return httpClient(`${configs.apiRoot}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
      meta,
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id } as RecordType & { id: Identifier },
    }));
  },

  delete: <RecordType extends RaRecord = any>(
    resource: string,
    params: DeleteParams<RecordType>
  ): Promise<DeleteResult<RecordType>> => {
    const { meta } = params;
    return httpClient(`${configs.apiRoot}/${resource}/${params.id}`, {
      method: 'DELETE',
      meta,
    }).then(({ json }) => ({ data: json }));
  },

  deleteMany: (
    resource: string,
    params: DeleteManyParams<any>
  ): Promise<DeleteManyResult> => {
    const { meta } = params;
    return Promise.all(
      params.ids.map((id) =>
        httpClient(`${configs.apiRoot}/${resource}/${id}`, {
          method: 'DELETE',
          meta,
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
  },
};

export default dataProvider;
