import MessageIcon from '@mui/icons-material/Message';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import PendingIcon from '@mui/icons-material/Pending';
import CheckinList from './checkin/CheckinList';
import MemberList from './members/MemberList';
import MessageList from './messages/MessageList';
import SettingsList, { SettingsEdit } from './settings/SettingsList';
import PendingList from './pending/PendingList';
import { ResourceProps } from 'react-admin';

type ZaloResourceConfig = {
  name: string;
  list?: React.ComponentType<any>;
  edit?: React.ComponentType<any>;
  icon: React.ComponentType<any>;
  options: {
    label: string;
    parent: string;
  };
};

export const zaloResources: Record<string, ZaloResourceConfig> = {
  pending: {
    name: 'integrations',
    list: PendingList,
    icon: PendingIcon,
    options: {
      label: 'Pending Requests',
      parent: 'zalo',
    },
  },
  messages: {
    name: 'integrations',
    list: MessageList,
    icon: MessageIcon,
    options: {
      label: 'Messages',
      parent: 'zalo',
    },
  },
  checkin: {
    name: 'integrations',
    list: CheckinList,
    icon: CheckCircleIcon,
    options: {
      label: 'Check-in',
      parent: 'zalo',
    },
  },
  members: {
    name: 'social-profiles',
    list: MemberList,
    icon: PeopleIcon,
    options: {
      label: 'Members',
      parent: 'zalo',
    },
  },
  settings: {
    name: 'integrations',
    list: SettingsList,
    edit: SettingsEdit,
    icon: SettingsIcon,
    options: {
      label: 'Settings',
      parent: 'zalo',
    },
  },
};
