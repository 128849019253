import * as React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  TextInput,
  SelectInput,
  DateInput,
  useTranslate,
  FunctionField,
  TopToolbar,
  CreateButton,
  Button,
  ListProps,
} from 'react-admin';
import { RentReturnDialog } from './RentReturn';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import CustomerReferenceField from '../visitors/CustomerReferenceField';

const ListActions = () => {
  const [showReturnDialog, setShowReturnDialog] = React.useState(false);
  const translate = useTranslate();

  return (
    <TopToolbar>
      <CreateButton />
      <Button
        onClick={() => setShowReturnDialog(true)}
        label="resources.book_rents.actions.return"
      >
        <KeyboardReturnIcon />
      </Button>
      <RentReturnDialog
        open={showReturnDialog}
        onClose={() => setShowReturnDialog(false)}
      />
    </TopToolbar>
  );
};

const StatusField = (props: any) => (
  <FunctionField
    {...props}
    render={(record: any) =>
      record?.status ? (
        <span>
          {props.translate(`resources.book_rents.status.${record.status}`)}
        </span>
      ) : null
    }
  />
);

const LocationField = (props: any) => (
  <FunctionField
    {...props}
    render={(record: any) =>
      record?.rentLocation ? (
        <span>
          {props.translate(
            `resources.book_rents.location.${record.rentLocation}`
          )}
        </span>
      ) : null
    }
  />
);

const RentList = (props: ListProps) => {
  const translate = useTranslate();

  const bookStatuses = [
    { id: 'RENTED', name: translate('resources.book_rents.status.RENTED') },
    { id: 'RETURNED', name: translate('resources.book_rents.status.RETURNED') },
    { id: 'OVERDUE', name: translate('resources.book_rents.status.OVERDUE') },
  ];

  const rentLocations = [
    {
      id: 'IN_STORE',
      name: translate('resources.book_rents.location.IN_STORE'),
    },
    {
      id: 'OUTSIDE_STORE',
      name: translate('resources.book_rents.location.OUTSIDE_STORE'),
    },
  ];

  const filters = [
    <TextInput
      key="trackingCode"
      source="trackingCode"
      label="resources.book_rents.fields.trackingCode"
      alwaysOn
    />,
    <SelectInput
      key="status"
      source="status"
      choices={bookStatuses}
      alwaysOn
    />,
    <SelectInput
      key="rentLocation"
      source="rentLocation"
      choices={rentLocations}
    />,
    <DateInput
      key="rentDate"
      source="rentDate"
      label="resources.book_rents.fields.rentDate"
    />,
    <DateInput
      key="dueDate"
      source="dueDate"
      label="resources.book_rents.fields.dueDate"
    />,
  ];

  return (
    <List {...props} filters={filters} actions={<ListActions />}>
      <Datagrid rowClick="edit">
        <TextField
          source="trackingCode"
          label="resources.book_rents.fields.trackingCode"
        />
        <ReferenceField source="bookId" reference="books" link={false}>
          <TextField source="title" label="resources.book_rents.fields.title" />
        </ReferenceField>
        <LocationField
          source="rentLocation"
          label="resources.book_rents.fields.rentLocation"
          translate={translate}
        />
        <CustomerReferenceField />
        <DateField
          source="rentDate"
          label="resources.book_rents.fields.rentDate"
        />
        <DateField
          source="dueDate"
          label="resources.book_rents.fields.dueDate"
        />
        <StatusField
          source="status"
          label="resources.book_rents.fields.status"
          translate={translate}
        />
      </Datagrid>
    </List>
  );
};

export default RentList;
