import PersonIcon from '@mui/icons-material/Person';
import StaffProfileList from './StaffProfileList';
import StaffProfileEdit from './StaffProfileEdit';

// Admin resource
export const staffProfileResource = {
  list: StaffProfileList,
  edit: StaffProfileEdit,
  icon: PersonIcon,
};

// Staff-only resource
export const staffOnlyResource = {
  list: StaffProfileList,
  edit: StaffProfileEdit,
  icon: PersonIcon,
  options: { label: 'Staff Profile' },
};
