import * as React from 'react';
import { Fragment, useCallback } from 'react';
import {
  AutocompleteInput,
  BooleanField,
  Count,
  DatagridConfigurable,
  DateField,
  DateInput,
  ExportButton,
  FilterButton,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectColumnsButton,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  CreateButton,
  useGetIdentity,
  useTranslate,
} from 'react-admin';
import { useMediaQuery, Divider, Tabs, Tab, Theme } from '@mui/material';

import NbItemsField from './NbItemsField';
import CustomerReferenceField from '../visitors/CustomerReferenceField';
import AddressField from '../visitors/AddressField';
import MobileGrid from './MobileGrid';
import { Customer, Order } from '../types';
import { configs } from '../configs';
import { VietnameseSearchInput } from '../components/VietnameseSearchInput';

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const OrderList = () => (
  <List
    filterDefaultValues={{ status: 'ordered' }}
    sort={{ field: 'date', order: 'DESC' }}
    perPage={25}
    filters={orderFilters}
    actions={<ListActions />}
    hasCreate={true}
  >
    <TabbedDatagrid />
  </List>
);

const orderFilters = [
  <VietnameseSearchInput key="q" source="q" alwaysOn />,
  <ReferenceInput
    key="customerId"
    source="customerId"
    reference="customers"
    queryOptions={{ meta: { brief: true } }}
  >
    <AutocompleteInput
      optionText={(choice?: Customer) =>
        choice?.id ? `${choice.first_name} ${choice.last_name}` : ''
      }
      label="resources.orders.fields.customer"
      sx={{ minWidth: 200 }}
    />
  </ReferenceInput>,
  <DateInput
    key="dateGte"
    source="dateGte"
    label="resources.orders.filters.dateGte"
  />,
  <DateInput
    key="dateLte"
    source="dateLte"
    label="resources.orders.filters.dateLte"
  />,
  <TextInput
    key="totalGte"
    source="totalGte"
    label="resources.orders.filters.totalGte"
  />,
  <NullableBooleanInput
    key="returned"
    source="returned"
    label="resources.orders.fields.returned"
  />,
];

const tabs = [
  { id: 'ordered', name: 'resources.orders.tabs.ordered' },
  { id: 'ready', name: 'resources.orders.tabs.ready' },
  { id: 'delivered', name: 'resources.orders.tabs.delivered' },
  { id: 'cancelled', name: 'resources.orders.tabs.cancelled' },
];

const TabbedDatagrid = () => {
  const translate = useTranslate();
  const listContext = useListContext<Order>();
  const { filterValues, setFilters, displayedFilters, data, isLoading } =
    listContext;
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const { data: identity } = useGetIdentity();
  const isStaff =
    identity?.roles?.includes('staff') ||
    identity?.roles?.includes('storeManager');

  const handleChange = useCallback(
    (event: React.SyntheticEvent, value: any) => {
      setFilters &&
        setFilters({ ...filterValues, status: value }, displayedFilters, false);
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={
              <span>
                {translate(choice.name)} (
                <Count
                  filter={{
                    ...filterValues,
                    status: choice.id,
                  }}
                  sx={{ lineHeight: 'inherit' }}
                />
                )
              </span>
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      {isXSmall ? (
        data?.map((record) => (
          <MobileGrid key={record.id} record={record} isStaff={isStaff} />
        ))
      ) : (
        <>
          {filterValues.status === 'ordered' && (
            <DatagridConfigurable
              rowClick="edit"
              omit={['total_ex_taxes', 'delivery_fees', 'taxes']}
              bulkActionButtons={false}
              sort={{ field: 'date', order: 'DESC' }}
            >
              <DateField
                source="date"
                label="resources.orders.fields.date"
                showTime
                sortable={true}
              />
              <TextField
                source="reference"
                label="resources.orders.fields.reference"
                sortable={true}
              />
              {!isStaff && (
                <>
                  <CustomerReferenceField
                    label="resources.orders.fields.customer"
                    sortable={true}
                    showPoints={true}
                    showZalo={true}
                  />
                  <ReferenceField
                    source="customerId"
                    reference="customers"
                    link={false}
                    label="resources.orders.fields.address"
                    sortable={true}
                  >
                    <AddressField />
                  </ReferenceField>
                </>
              )}
              <NbItemsField label="resources.orders.fields.items" />
              <NumberField
                source="totalExTaxes"
                label="resources.orders.fields.totalExTaxes"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sortable={true}
              />
              <NumberField
                source="deliveryFees"
                label="resources.orders.fields.deliveryFees"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sortable={true}
              />
              <NumberField
                source="taxes"
                label="resources.orders.fields.taxes"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sortable={true}
              />
              <NumberField
                source="total"
                label="resources.orders.fields.total"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sx={{ fontWeight: 'bold' }}
                sortable={true}
              />
            </DatagridConfigurable>
          )}
          {filterValues.status === 'ready' && (
            <DatagridConfigurable
              rowClick="edit"
              omit={['total_ex_taxes', 'delivery_fees', 'taxes']}
              bulkActionButtons={false}
              sort={{ field: 'date', order: 'DESC' }}
            >
              <DateField
                source="date"
                label="resources.orders.fields.date"
                showTime
                sortable={true}
              />
              <TextField
                source="reference"
                label="resources.orders.fields.reference"
                sortable={true}
              />
              {!isStaff && (
                <>
                  <CustomerReferenceField
                    label="resources.orders.fields.customer"
                    sortable={true}
                    showPoints={true}
                    showZalo={true}
                  />
                  <ReferenceField
                    source="customerId"
                    reference="customers"
                    link={false}
                    label="resources.orders.fields.address"
                    sortable={true}
                  >
                    <AddressField />
                  </ReferenceField>
                </>
              )}
              <NbItemsField label="resources.orders.fields.items" />
              <NumberField
                source="totalExTaxes"
                label="resources.orders.fields.totalExTaxes"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sortable={true}
              />
              <NumberField
                source="deliveryFees"
                label="resources.orders.fields.deliveryFees"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sortable={true}
              />
              <NumberField
                source="taxes"
                label="resources.orders.fields.taxes"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sortable={true}
              />
              <NumberField
                source="total"
                label="resources.orders.fields.total"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sx={{ fontWeight: 'bold' }}
                sortable={true}
              />
            </DatagridConfigurable>
          )}
          {filterValues.status === 'delivered' && (
            <DatagridConfigurable
              rowClick="edit"
              omit={['total_ex_taxes', 'delivery_fees', 'taxes']}
              bulkActionButtons={false}
              sort={{ field: 'date', order: 'DESC' }}
            >
              <DateField
                source="date"
                label="resources.orders.fields.date"
                showTime
                sortable={true}
              />
              <TextField
                source="reference"
                label="resources.orders.fields.reference"
                sortable={true}
              />
              <CustomerReferenceField
                label="resources.orders.fields.customer"
                sortable={true}
                showPoints={true}
                showZalo={true}
              />
              <ReferenceField
                source="customerId"
                reference="customers"
                link={false}
                label="resources.orders.fields.address"
                sortable={true}
              >
                <AddressField />
              </ReferenceField>
              <NbItemsField label="resources.orders.fields.items" />
              <NumberField
                source="totalExTaxes"
                label="resources.orders.fields.totalExTaxes"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sortable={true}
              />
              <NumberField
                source="deliveryFees"
                label="resources.orders.fields.deliveryFees"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sortable={true}
              />
              <NumberField
                source="taxes"
                label="resources.orders.fields.taxes"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sortable={true}
              />
              <NumberField
                source="total"
                label="resources.orders.fields.total"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sx={{ fontWeight: 'bold' }}
                sortable={true}
              />
              <BooleanField
                source="returned"
                label="resources.orders.fields.returned"
                sx={{ mt: -0.5, mb: -0.5 }}
                sortable={true}
              />
            </DatagridConfigurable>
          )}
          {filterValues.status === 'cancelled' && (
            <DatagridConfigurable
              rowClick="edit"
              omit={['total_ex_taxes', 'delivery_fees', 'taxes']}
              bulkActionButtons={false}
              sort={{ field: 'date', order: 'DESC' }}
            >
              <DateField
                source="date"
                label="resources.orders.fields.date"
                showTime
                sortable={true}
              />
              <TextField
                source="reference"
                label="resources.orders.fields.reference"
                sortable={true}
              />
              <CustomerReferenceField
                label="resources.orders.fields.customer"
                sortable={true}
                showPoints={true}
                showZalo={true}
              />
              <ReferenceField
                source="customerId"
                reference="customers"
                link={false}
                label="resources.orders.fields.address"
                sortable={true}
              >
                <AddressField />
              </ReferenceField>
              <NbItemsField label="resources.orders.fields.items" />
              <NumberField
                source="totalExTaxes"
                label="resources.orders.fields.totalExTaxes"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sortable={true}
              />
              <NumberField
                source="deliveryFees"
                label="resources.orders.fields.deliveryFees"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sortable={true}
              />
              <NumberField
                source="taxes"
                label="resources.orders.fields.taxes"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sortable={true}
              />
              <NumberField
                source="total"
                label="resources.orders.fields.total"
                options={{
                  style: 'currency',
                  currency: configs.priceUnit.text,
                }}
                sx={{ fontWeight: 'bold' }}
                sortable={true}
              />
            </DatagridConfigurable>
          )}
        </>
      )}
    </Fragment>
  );
};

export default OrderList;
