import * as React from 'react';
import { AppBar, TitlePortal, ToggleThemeButton } from 'react-admin';
import { Box, useMediaQuery, Theme } from '@mui/material';
import { useState } from 'react';
import Logo from './Logo';
import { AppBarToolbar } from './AppBarToolbar';
import AdditionalUserMenu from './AdditionalUserMenu';
import { NotificationButton } from './NotificationButton';
import ChangePasswordDialog from './ChangePasswordDialog';

const CustomAppBar = () => {
  const isLargeEnough = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('sm')
  );
  const [showChangePassword, setShowChangePassword] = useState(false);

  return (
    <>
      <AppBar
        color="secondary"
        toolbar={<AppBarToolbar />}
        userMenu={
          <AdditionalUserMenu
            onChangePassword={() => setShowChangePassword(true)}
          />
        }
      >
        {isLargeEnough && <Logo />}
        <Box component="span" sx={{ flex: 1 }} />
        <Box display="flex" alignItems="center" gap={1}>
          <NotificationButton />
          <ToggleThemeButton />
        </Box>
      </AppBar>
      <ChangePasswordDialog
        open={showChangePassword}
        onClose={() => setShowChangePassword(false)}
      />
    </>
  );
};

export default CustomAppBar;
