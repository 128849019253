import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import { BookSeriesEntry } from 'public-contracts/neko-library';
import * as React from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  TextInput,
  useCreate,
  useCreateSuggestionContext,
  useDataProvider,
  useInput,
} from 'react-admin';
import { bookAgeRatings, bookLanguages } from '../configs';

const CreateSeries = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate<
    BookSeriesEntry,
    unknown,
    {
      id: string;
      insertedId: string;
    }
  >();
  const [value, setValue] = React.useState(filter || '');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    create(
      'book_series',
      { data: { name: value, description: '' } },
      {
        onSuccess: (data) => {
          onCreate({
            name: value,
            id: data.id,
          });
          setValue('');
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New series name"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CreateCategory = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate<
    BookSeriesEntry,
    unknown,
    {
      id: string;
      insertedId: string;
    }
  >();
  const [value, setValue] = React.useState(filter || '');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    create(
      'book_categories',
      { data: { name: value, description: '' } },
      {
        onSuccess: (data) => {
          onCreate({
            name: value,
            id: data.id,
          });
          setValue('');
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New category name"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CreatePublisher = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate<
    BookSeriesEntry,
    unknown,
    {
      id: string;
      insertedId: string;
    }
  >();
  const [value, setValue] = React.useState(filter || '');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    create(
      'book_publishers',
      { data: { name: value } },
      {
        onSuccess: (data) => {
          onCreate({
            name: value,
            id: data.id,
          });
          setValue('');
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New publisher name"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CreateAuthor = () => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [create] = useCreate<
    BookSeriesEntry,
    unknown,
    {
      id: string;
      insertedId: string;
    }
  >();
  const [value, setValue] = React.useState(filter || '');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    create(
      'book_authors',
      { data: { name: value } },
      {
        onSuccess: (data) => {
          onCreate({
            name: value,
            id: data.id,
          });
          setValue('');
        },
      }
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label="New author name"
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const CustomTagInput = () => {
  const dataProvider = useDataProvider();
  const [lastSpaceTime, setLastSpaceTime] = React.useState(0);
  const { field } = useInput({ source: 'tagIds' });

  const cleanValue = React.useCallback((value: string) => {
    return value.trim().replace(/[.\s]+$/, '');
  }, []);

  const handleCreate = React.useCallback(
    async (filter: string | undefined) => {
      if (!filter) return null;
      const cleanedValue = cleanValue(filter);
      if (!cleanedValue) return null;

      try {
        // Check if tag exists
        const { data } = await dataProvider.getList('book_tags', {
          pagination: { page: 1, perPage: 1 },
          sort: { field: 'id', order: 'ASC' },
          filter: { name: { $regex: `^${cleanedValue}$`, $options: 'i' } },
        });

        if (data.length > 0) {
          // Return existing tag
          return { id: data[0].id, name: data[0].name };
        }

        // Create new tag
        const response = await dataProvider.create('book_tags', {
          data: { name: cleanedValue },
        });
        return { id: response.data.id, name: cleanedValue };
      } catch (error) {
        console.error('Error handling tag:', error);
        return Promise.reject(error);
      }
    },
    [dataProvider, cleanValue]
  );

  const handleKeyDown = React.useCallback(
    async (event: React.KeyboardEvent) => {
      if (event.key === ' ') {
        const now = Date.now();
        const input = event.target as HTMLInputElement;

        // If this is potentially the second space
        if (now - lastSpaceTime < 500) {
          event.preventDefault();
          // Get value before the first space was added
          const value = cleanValue(input.value.slice(0, -1));
          if (value) {
            try {
              const tag = await handleCreate(value);
              if (tag) {
                const currentTags = field.value || [];
                if (!currentTags.includes(tag.id)) {
                  field.onChange([...currentTags, tag.id]);
                }
                input.value = '';
              }
            } catch (error) {
              console.error('Error handling tag creation:', error);
            }
          }
        }
        setLastSpaceTime(now);
      }
    },
    [lastSpaceTime, field, handleCreate, cleanValue]
  );

  const filterToQuery = React.useCallback(
    (searchText: string | undefined) =>
      searchText && searchText.length >= 3
        ? { name: { $regex: searchText, $options: 'i' } }
        : {},
    []
  );

  return (
    <ReferenceArrayInput
      source="tagIds"
      reference="book_tags"
      filterToQuery={filterToQuery}
    >
      <AutocompleteArrayInput
        optionText="name"
        fullWidth
        onCreate={handleCreate}
        onKeyDown={handleKeyDown}
        filterToQuery={filterToQuery}
      />
    </ReferenceArrayInput>
  );
};

export const BookEditDetails = () => (
  <>
    <TextInput source="title" validate={required()} fullWidth />
    <ReferenceInput
      source="authorId"
      reference="book_authors"
      validate={required()}
    >
      <AutocompleteInput
        optionText="name"
        fullWidth
        create={<CreateAuthor />}
      />
    </ReferenceInput>
    <TextInput source="trackingCode" validate={required()} fullWidth />
    <ReferenceInput
      source="categoryId"
      reference="book_categories"
      validate={required()}
    >
      <AutocompleteInput
        optionText="name"
        fullWidth
        create={<CreateCategory />}
      />
    </ReferenceInput>
    <ReferenceInput
      source="publisherId"
      reference="book_publishers"
      validate={required()}
    >
      <AutocompleteInput
        optionText="name"
        fullWidth
        create={<CreatePublisher />}
      />
    </ReferenceInput>
    <ReferenceInput source="seriesId" reference="book_series">
      <AutocompleteInput
        create={<CreateSeries />}
        optionText="name"
        fullWidth
      />
    </ReferenceInput>
    <TextInput source="publishDate" type="date" fullWidth />
    <AutocompleteInput
      source="ageRatingId"
      fullWidth
      choices={bookAgeRatings}
    />
    <AutocompleteInput
      source="languageId"
      validate={required()}
      fullWidth
      choices={bookLanguages}
    />
    <NumberInput source="pages" fullWidth />
    <NumberInput source="quantity" validate={required()} min={0} fullWidth />
    <NumberInput source="price" defaultValue={0} fullWidth />
    <CustomTagInput />
  </>
);
