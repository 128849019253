import * as React from 'react';
import { Box, Card, CardHeader, CardContent, Typography } from '@mui/material';
import {
  DateField,
  EditButton,
  NumberField,
  TextField,
  BooleanField,
  useTranslate,
  RecordContextProvider,
} from 'react-admin';
import { configs } from '../configs';
import CustomerReferenceField from '../visitors/CustomerReferenceField';
import AddressField from '../visitors/AddressField';
import NbItemsField from './NbItemsField';
import { Order } from '../types';

interface MobileGridProps {
  record: Order;
  isStaff: boolean;
}

const MobileGrid = ({ record, isStaff }: MobileGridProps) => {
  const translate = useTranslate();

  return (
    <RecordContextProvider value={record}>
      <Card sx={{ margin: '0.5rem 0' }}>
        <CardHeader
          title={
            <>
              {translate('resources.orders.name', 1)} #
              <TextField source="reference" variant="body1" />
            </>
          }
          titleTypographyProps={{ variant: 'body1' }}
          action={<EditButton />}
        />
        <CardContent sx={{ pt: 0 }}>
          {!isStaff && (
            <>
              <CustomerReferenceField
                sx={{ display: 'block', mb: 1 }}
                showPoints={true}
                showZalo={true}
              />
              <Typography variant="body2" gutterBottom>
                {translate('resources.orders.fields.address')}:&nbsp;
                <AddressField />
              </Typography>
            </>
          )}
          <Typography variant="body2" gutterBottom>
            {translate('resources.orders.fields.date')}:&nbsp;
            <DateField source="date" showTime />
          </Typography>
          <Typography variant="body2" gutterBottom>
            {translate('resources.orders.fields.items')}:&nbsp;
            <NbItemsField />
          </Typography>
          <Typography variant="body2" gutterBottom>
            {translate('resources.orders.fields.totalExTaxes')}:&nbsp;
            <NumberField
              source="totalExTaxes"
              options={{
                style: 'currency',
                currency: configs.priceUnit.text,
              }}
            />
          </Typography>
          <Typography variant="body2" gutterBottom>
            {translate('resources.orders.fields.deliveryFees')}:&nbsp;
            <NumberField
              source="deliveryFees"
              options={{
                style: 'currency',
                currency: configs.priceUnit.text,
              }}
            />
          </Typography>
          <Typography variant="body2" gutterBottom>
            {translate('resources.orders.fields.taxes')}:&nbsp;
            <NumberField
              source="taxes"
              options={{
                style: 'currency',
                currency: configs.priceUnit.text,
              }}
            />
          </Typography>
          <Typography variant="body2" gutterBottom>
            {translate('resources.orders.fields.total')}:&nbsp;
            <NumberField
              source="total"
              options={{
                style: 'currency',
                currency: configs.priceUnit.text,
              }}
              sx={{ fontWeight: 'bold' }}
            />
          </Typography>
          <Typography variant="body2" gutterBottom>
            {translate('resources.orders.fields.status')}:&nbsp;
            <TextField
              source="status"
              label={translate(`resources.orders.tabs.${record.status}`)}
            />
          </Typography>
          {record.status === 'delivered' && (
            <Typography variant="body2">
              {translate('resources.orders.fields.returned')}:&nbsp;
              <BooleanField source="returned" />
            </Typography>
          )}
        </CardContent>
      </Card>
    </RecordContextProvider>
  );
};

export default MobileGrid;
