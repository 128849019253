import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Scanner, IDetectedBarcode } from '@yudiel/react-qr-scanner';
import { useNavigate } from 'react-router-dom';

interface QRScannerDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onError: (error?: string) => void;
}

const QRScannerDialog = ({
  open,
  onClose,
  onSuccess,
  onError,
}: QRScannerDialogProps) => {
  const navigate = useNavigate();

  const handleScan = (detectedCodes: IDetectedBarcode[]) => {
    try {
      const url = new URL(detectedCodes[0].rawValue);
      const code = url.searchParams.get('code');
      if (code) {
        navigate(`/zalo/checkin?code=${code}`);
        onSuccess();
        onClose();
      } else {
        onError('Invalid QR code');
      }
    } catch (error) {
      onError('Invalid QR code URL');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Scan QR Code</DialogTitle>
      <DialogContent>
        {open && (
          <Scanner
            onScan={handleScan}
            onError={(error) =>
              onError(error instanceof Error ? error.message : 'Scanner error')
            }
            scanDelay={500}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default QRScannerDialog;
